import React, { useState, useEffect } from 'react';

import FormDialog from './FormDialog';
import ContactItemDialog from './ContactItemDialog';

import { basicMsg } from '../../../actions/swal_msg';
import { getContactForms } from '../../../actions/admin/form/contact_form';

import {
    Alert, Box, Grid, Card, CardContent, CardActions,
    Typography, Button, IconButton, Stack, Tooltip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { SELECTED_FORM } from '../../../constants/admin/form_const';

const AdminContact = () => {
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(SELECTED_FORM);

    const [loading, setLoading] = useState(false);

    const [dialog, setDialog] = useState({
        open1: false,
        open2: false
    });
    const { open1, open2 } = dialog;

    useEffect(() => {
        getForms();
    }, []);
    
    const getForms = async () => {
        setLoading(true);
        const req = await getContactForms(setLoading);
        if (req.status)
            setForms(req.resp);
        setLoading(false);
    };

    const selectForm = _id => async () => {
        const Model = forms.find(e => e._id === _id);
        if (Model) {
            setSelectedForm(Model);
            setDialog(e => ({ ...e, open2: true }));
        } else
            basicMsg('No se encontró el formulario de contacto');
    };
    
    return (
        <Box sx={{ p: 2 }}>
            {
                loading ?
                    <Alert severity="info">Cargando contenido...</Alert>
                :
                    forms.length > 0 ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Stack direction="row">
                                    <Typography variant="h5" gutterBottom>
                                        Contacto tienda
                                    </Typography>
                                    <Tooltip title="Contacto de clientes (página principal)">
                                        <IconButton aria-label="informacion">
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                            {
                                forms.map(({
                                    _id, fullname, email, tel, email_content: { msg }, replied
                                }) =>
                                    <Grid item xs={12} sm={4} md={3} key={_id}>
                                        <Card sx={{ height: '26vh' }}>
                                            <CardContent>
                                                <Stack direction="row" spacing={2}>
                                                    <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                                        { email }
                                                    </Typography>
                                                    {
                                                        replied &&
                                                            <Tooltip title="Respondiste">
                                                                <CheckCircleIcon color="success" />
                                                            </Tooltip>
                                                    }
                                                </Stack>
                                                
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                                    { `Tel: ${tel}` }
                                                </Typography>
                                                <Typography gutterBottom sx={{ fontSize: 16 }}>
                                                    { `Mensaje de ${fullname}` }
                                                </Typography>
                                                <Typography variant="body2" sx={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    display: "-webkit-box",
                                                    WebkitLineClamp: "3",
                                                    WebkitBoxOrient: "vertical",
                                                }}>
                                                    { `"${msg}"` }
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small"
                                                    onClick={ selectForm(_id) }>
                                                    Ver mas
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                )
                            }
                        </Grid>
                    :
                        <Alert severity="info">No hay resultados.</Alert>
            }
            <ContactItemDialog
                open2={ open2 }
                setDialog={ setDialog }
                selectedForm={ selectedForm }
            />
            <FormDialog
                setForms={ setForms }
                open1={ open1 }
                setDialog={ setDialog }
                selectedForm={ selectedForm }
            />
        </Box>
    );
};

export default AdminContact;