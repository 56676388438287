import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterDomLink } from 'react-router-dom';

import { simpleConfirmDialog } from '../../../actions/swal_msg';
import { getCategoryURL } from '../../../helpers/shop/shopHelper';
import { removeItemFromCart, updateItemInCart, updateQuantity } from '../../../actions/shop';

import {
    FormControl, TextField, Typography, Button, Grid, Box, Link
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import { regex_num } from '../../../constants/regex';
import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../constants/constants';

// aceptar sólo números
// const regex = /^\d+$/;

const CartItem = ({ goToProductPage }) => {
    const dispatch = useDispatch();
    // productos de carrito
    const { cart: { item } } = useSelector(state => state.shop);

    // cantidad de producto en carrito
    const handleChange = _prod_var_id => ({ target }) => {
        const abc = target.value;
        const quantity = Number(abc);
        if (abc === '')
            dispatch(updateQuantity({
                prod_var_id: _prod_var_id,
                quantity: abc
            }));
        else if (quantity === 0)
            dispatch(updateQuantity({
                prod_var_id: _prod_var_id,
                quantity
            }));
        else if (quantity > 0 && regex_num.test(quantity))
            dispatch(updateItemInCart(_prod_var_id, quantity));
    };

    // eliminar producto de carrito
    const remove = _prod_var_id => async () => {
        if (await simpleConfirmDialog('¿Eliminar el producto de su carrito?', 'Sí, eliminar')) {
            dispatch(removeItemFromCart(_prod_var_id));
        }
    };

    return (
        <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
            {
                item.map(({
                    prod_var_id, title, variant_title, url, barcode,
                    price, quantity, category, prod_category, images
                }) => {
                    const full_title = variant_title ? `${title} - ${variant_title}` : title;
                    const img = images.length > 0 ?
                        {
                            id: `${GOOGLE_DRIVE_CDN}${images[0].id}`,
                            alt: images[0].alt ? images[0].alt : 'producto-nefropolis'
                        }
                    :
                        {
                            id: `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`,
                            alt: 'producto-nefropolis'
                        };
                    return (
                        <Box sx={{ p: 3 }} className="cart-box-shadow" key={prod_var_id}>
                            <Grid container spacing={2}>
                                {/* Imagen del producto */}
                                <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                                    <Box sx={{ textAlign: 'center' }} className="animate__animated animate__fadeInLeft">
                                        <Link
                                            component="button"
                                            onClick={ goToProductPage(url, barcode) }>
                                            <img src={ img.id }
                                                className="cart-img" alt={ img.alt } width='150' height="150"
                                                style={{ objectFit: 'contain' }}
                                            />
                                            {/* <img src={`${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`}
                                                className="cart-img" alt="producto-nefropolis" width='100%'
                                            /> */}
                                        </Link>
                                    </Box>
                                </Grid>
                                {/* Información del producto */}
                                <Grid item xl={9} lg={9} md={12} sm={12} xs={12}>
                                    <Box sx={{ textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left' } }}>
                                        <div>
                                            {/* Nombre del producto */}
                                            <Link
                                                component="button"
                                                variant="body1"
                                                sx={{
                                                    color: '#000', mb: 1, textDecoration: 'none',
                                                    fontWeight: '600', textAlign: 'left'
                                                }}
                                                onClick={ goToProductPage(url, barcode) }>
                                                { full_title }
                                            </Link>
                                            {/* Categorías */}
                                            <Box>
                                                <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                                                    Categorías:
                                                    {
                                                        (prod_category && prod_category.length > 0) ?
                                                            prod_category.map(({ category }, i) =>
                                                                <RouterDomLink key={i}
                                                                    to={
                                                                        `/tienda/categoria/${getCategoryURL(category)}`
                                                                    }
                                                                    style={{ marginLeft: '3px' }}>{
                                                                        i === prod_category.length - 1 ?
                                                                            category : `${category},`
                                                                    }
                                                                </RouterDomLink>
                                                            )
                                                        :
                                                            (category && category.length > 0) ?
                                                                category.map((e, i) =>
                                                                    <RouterDomLink key={i}
                                                                        to={
                                                                            `/tienda/categoria/${getCategoryURL(e)}`
                                                                        }
                                                                        style={{ marginLeft: '3px' }}>{
                                                                            i === category.length - 1 ?
                                                                                e : `${e},`
                                                                        }
                                                                    </RouterDomLink>
                                                                )
                                                            :
                                                                ` N/A`
                                                    }
                                                </Typography>
                                            </Box>

                                            <FormControl sx={{ width: { xs: '100%', md: 'auto' }, mt: 2 }}>
                                                <TextField
                                                    type="number"
                                                    label="Cantidad"
                                                    variant="outlined"
                                                    value={quantity}
                                                    size="small"
                                                    InputProps={{ inputProps: { min: 1 } }}
                                                    onChange={handleChange(prod_var_id)}
                                                />
                                            </FormControl>
                                        </div>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', mt: 2 }}>
                                        <Box sx={{ my: 'auto' }}>
                                            {/* Boton para eliminar elemento del carrito */}
                                            <Button
                                                color='error'
                                                onClick={remove(prod_var_id)}
                                                startIcon={<DeleteIcon />}>
                                                Eliminar del carrito
                                            </Button>
                                        </Box>
                                        <Box sx={{ my: 'auto' }}>
                                            <Typography variant='body1' sx={{ color: 'green', fontWeight: '600' }}>
                                                ${price}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })
            }
        </Grid>
    );
};

export default CartItem;