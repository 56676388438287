import React from 'react';
import { useDispatch } from 'react-redux';

import { updAdmin } from '../../../actions/admin';
import { valMsg } from '../../../actions/swal_msg';

import {
    Box, Button, Checkbox, Container, Divider, FormControlLabel,
    FormGroup, Typography
} from '@mui/material';

const AdminPrivileges = ({
    values, setValues, inputErr, setInputErr, myRef, eInputRef, uInputRef, rInputRef, handleCloseDialog
}) => {
    const dispatch = useDispatch();
    const { username, email, role, privileges:
        {
            products, product_data, coupons,
            orders, shipping, banners, blog,
            admins, clients
        }
    } = values;

    const handleCheckboxChange = ({ target }) => {
        const name = target.name;
        const checked = target.checked;
        setValues({
            ...values,
            privileges: {
                ...values.privileges,
                [name]: checked
            }
        });
    };

    const update = () => {
        if (formValidator())
            dispatch(updAdmin(values));
        handleCloseDialog();
    };

    // validación formulario
    const formValidator = () => {
        const email_len = email.trim().length;
        const username_len = username.trim().length;
        const role_len = role.trim().length;
        if (email_len > 5 && email_len <= 100) {
            if (username_len > 2 && username_len <= 100) {
                if (role_len > 2 && role_len <= 60)
                    return true;
                else {
                    rInputRef.current.select();
                    valMsg('warning', 'Administrador',
                        'Ingrese un rol para el usuario');
                }
            } else {
                uInputRef.current.select();
                valMsg('warning', 'Administrador',
                    'Ingrese un nombre de usuario apropiado');
            }
        } else {
            eInputRef.current.select();
            valMsg('warning', 'Administrador',
                'Ingrese un correo electrónico válido');
        }
        // establece errores en los campos
        setInputErr({
            ...inputErr,
            email_err: (email.length < 6 ||
                email.length > 100) ? true : false,
            username_err: (username.length < 3 ||
                username.length > 100) ? true : false,
            role_err: (role.length < 3 ||
                username.length > 100) ? true : false
        });
        return false;
    };

    return (
        <Container maxWidth='xl' sx={{ mb: 5 }} ref={myRef}>
            <Box sx={{ p:3, boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)', borderRadius: '5px' }}>
                <Typography variant='h6' sx={{fontWeight: '600'}}>Administrar privilegios para: {username} </Typography>
                <Divider sx={{my: 2}} />
                <FormGroup>
                    <FormControlLabel control={<Checkbox name="products" checked={products} onChange={handleCheckboxChange} />} label="Productos" />
                    <FormControlLabel control={<Checkbox name="product_data" checked={product_data} onChange={handleCheckboxChange} />} label="Categorías/marca productos" />
                    <FormControlLabel control={<Checkbox name="coupons" checked={coupons} onChange={handleCheckboxChange} />} label="Cupones/Promociones" />
                    <FormControlLabel control={<Checkbox name="orders" checked={orders} onChange={handleCheckboxChange} />} label="Pedidos" />
                    <FormControlLabel control={<Checkbox name="shipping" checked={shipping} onChange={handleCheckboxChange} />} label="Métodos de envío" />
                    <FormControlLabel control={<Checkbox name="banners" checked={banners} onChange={handleCheckboxChange} />} label="Banners" />
                    <FormControlLabel control={<Checkbox name="blog" checked={blog} onChange={handleCheckboxChange} />} label="Blog" />
                    <FormControlLabel control={<Checkbox name="clients" checked={clients} onChange={handleCheckboxChange} />} label="Clientes" />
                    <FormControlLabel control={<Checkbox name="admins" checked={admins} onChange={handleCheckboxChange} />} label="Lista de administradores" />
                </FormGroup>
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button variant='contained'
                        onClick={ update }>
                        Guardar cambios
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default AdminPrivileges;
