import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { valMsg } from '../../../actions/swal_msg';
import { adminUserModalToggle } from '../../../actions/ui';
import { regAdmin, updAdmin } from '../../../actions/admin';

import {
    Button, FormControl, FormHelperText, InputAdornment, InputLabel,
    OutlinedInput, TextField, Typography, FormControlLabel, Box,
    Checkbox, Dialog, DialogContent, DialogTitle, Grid, DialogActions
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import {
    AccountCircle, Visibility, VisibilityOff
} from '@mui/icons-material';

// estilos para mostrar/ocultar errores
const hideErrStyle = { display: "none" };
const showErrStyle = { display: "flex" };

const closeAction = {
    status: false,
    action: 'add'
};

const init = {
    username: '',
    email: '',
    password: '',
    password_c: '',
    privileges: {
        products: false,
        product_data: false,
        coupons: false,
        orders: false,
        shipping: false,
        banners: false,
        blog: false,
        admins: false,
        clients: false
    }
};

const error_i = {
    username_err: false,
    email_err: false,
    pwd1_err: false,
    pwd2_err: false
};

const AdminUserModal = () => {
    const dispatch = useDispatch();
    const { activeAdmin } = useSelector(state => state.admin);
    // estado de modal
    const { adminUserModal } = useSelector(state => state.ui);
    const { status, action } = adminUserModal;
    // referencias a campos
    const eInputRef = useRef();
    const uInputRef = useRef();
    const p1InputRef = useRef();
    const p2InputRef = useRef();

    const [values, setValues] = useState(init);
    const { username, email, password, password_c,
        privileges: {
            products, product_data, coupons,
            orders, shipping, banners, blog,
            admins, clients
        }
    } = values;
    // errores en campos
    const [inputErr, setInputErr] = useState(error_i);
    const { username_err, email_err, pwd1_err, pwd2_err } = inputErr;
    // mostrar/ocultar contraseña
    const [pwdToggle, setPwdToggle] = useState({
        pwd1: false,
        pwd2: false,
    });
    const { pwd1, pwd2 } = pwdToggle;

    const handleCheckboxChange = ({ target }) => {
        const name = target.name;
        const checked = target.checked;
        if (name === 'product_section')
            setValues({
                ...values,
                privileges: {
                    ...values.privileges,
                    'products': checked,
                    'product_data': checked,
                    'coupons': checked
                }
            });
        else if (name === 'shop_section')
            setValues({
                ...values,
                privileges: {
                    ...values.privileges,
                    'orders': checked,
                    'shipping': checked,
                    'banners': checked,
                    'blog': checked,
                    'clients': checked
                }
            });
        else
            setValues({
                ...values,
                privileges: {
                    ...values.privileges,
                    [name]: checked
                }
            });
    };

    const product_children = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <FormControlLabel
                name="products"
                label="Productos"
                control={<Checkbox checked={products} onChange={handleCheckboxChange} />}
            />
            <FormControlLabel
                name="product_data"
                label="Categorías/marca"
                control={<Checkbox checked={product_data} onChange={handleCheckboxChange} />}
            />
            <FormControlLabel
                name="coupons"
                label="Cupones y promociones"
                control={<Checkbox checked={coupons} onChange={handleCheckboxChange} />}
            />
        </Box>
    );

    const shop_children = (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
            <FormControlLabel
                name="orders"
                label="Pedidos"
                control={<Checkbox checked={orders} onChange={handleCheckboxChange} />}
            />
            <FormControlLabel
                name="shipping"
                label="Métodos de envío"
                control={<Checkbox checked={shipping} onChange={handleCheckboxChange} />}
            />
            <FormControlLabel
                name="banners"
                label="Banners"
                control={<Checkbox checked={banners} onChange={handleCheckboxChange} />}
            />
            <FormControlLabel
                name="blog"
                label="Blog"
                control={<Checkbox checked={blog} onChange={handleCheckboxChange} />}
            />
            <FormControlLabel
                name="clients"
                label="Clientes"
                control={<Checkbox checked={clients} onChange={handleCheckboxChange} />}
            />
        </Box>
    );

    useEffect(() => {
        if (!status) {
            setValues(init);
            setInputErr(error_i);
        } else {
            if (action === 'edit')
                setValues({ ...activeAdmin, password: '', password_c: '' });
        }
    }, [adminUserModal]);

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        setValues({ ...values, [name]: value });
        switch (name) {
            case 'email':
                setInputErr({
                    ...inputErr,
                    email_err: (value.length < 6 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'username':
                setInputErr({
                    ...inputErr,
                    username_err: (value.length < 3 ||
                        value.length > 100) ? true : false
                });
                break;
            case 'password':
                setInputErr({
                    ...inputErr,
                    pwd1_err: (value.length < 5 ||
                        value.length > 60) ? true : false,
                    pwd2_err: (password_c !== value) ? true : false
                });
                break;
            case 'password_c':
                setInputErr({
                    ...inputErr,
                    pwd2_err: (value !== password) ? true : false
                });
                break;
            default:
                break;
        }
    };

    // validación formulario
    const formValidator = () => {
        const email_len = email.trim().length;
        const username_len = username.trim().length;
        const pwd_len = password.trim().length;
        if (email_len > 5 && email_len <= 100) {
            if (username_len > 2 && username_len <= 100) {
                if (pwd_len > 4 && pwd_len <= 60) {
                    if (password_c === password) return true;
                    else {
                        p2InputRef.current.select();
                        valMsg('warning', 'Registro',
                            'Las contraseñas no son iguales');
                    }
                } else {
                    p1InputRef.current.select();
                    valMsg('warning', 'Registro',
                        'Ingrese una contraseña segura');
                }
            } else {
                uInputRef.current.select();
                valMsg('warning', 'Registro',
                    'Ingrese un nombre de usuario apropiado');
            }
        } else {
            eInputRef.current.select();
            valMsg('warning', 'Registro',
                'Ingrese un correo electrónico válido');
        }
        // establece errores en los campos
        setInputErr({
            ...inputErr,
            email_err: (email_len < 6 ||
                email_len > 100) ? true : false,
            username_err: (username_len < 3 ||
                username_len > 100) ? true : false,
            pwd1_err: (pwd_len < 5 ||
                pwd_len > 60) ? true : false,
            pwd2_err: (password_c !== password) ? true : false
        });
        return false;
    };

    const handlePwd1Toggle = () => {
        setPwdToggle({ ...pwdToggle, pwd1: !pwd1 });
    };
    const handlePwd2Toggle = () => {
        setPwdToggle({ ...pwdToggle, pwd2: !pwd2 });
    };
    const handleMouseDownPwd = (event) => {
        event.preventDefault();
    };

    const submit = () => {
        if (formValidator()) {
            dispatch(regAdmin(values));
        }
    };

    const update = () => {
        if (formValidator()) {
            dispatch(updAdmin(values));
        }
    };

    // cerrar modal
    const handleClose = () => {
        dispatch(adminUserModalToggle(closeAction));
    };

    return (
        <Dialog
            open={status}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'lg'}
            sx={{ zIndex: 1000 }}
        >
            <DialogTitle>
                {action === 'edit' ? 'Editar' : 'Agregar'} administrador
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3.5}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Correo electrónico"
                            size="large"
                            variant="standard"
                            error={email_err}
                            helperText={email_err ? "Ingrese correo electrónico" : ""}
                            inputProps={{ maxLength: 120 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                )
                            }}
                            inputRef={eInputRef}
                            name="email"
                            value={email}
                            onChange={handleInputChange} />
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="Nombre de usuario"
                            size="large"
                            variant="standard"
                            error={username_err}
                            helperText={username_err ? "Ingrese nombre de usuario" : ""}
                            inputProps={{ maxLength: 100 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                )
                            }}
                            inputRef={uInputRef}
                            name="username"
                            value={username}
                            onChange={handleInputChange} />
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Contraseña</InputLabel>
                            <OutlinedInput
                                label="Contraseña"
                                type={pwd1 ? 'text' : 'password'}
                                error={pwd1_err}
                                inputProps={{ maxLength: 60 }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handlePwd1Toggle}
                                            onMouseDown={handleMouseDownPwd}
                                            edge="end">
                                            {pwd1 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                }
                                inputRef={p1InputRef}
                                name="password"
                                value={password}
                                onChange={handleInputChange} />
                            <FormHelperText error style={pwd1_err ? showErrStyle : hideErrStyle}>
                                Ingrese una contraseña
                            </FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Confirmar contraseña</InputLabel>
                            <OutlinedInput
                                label="Confirmar contraseña"
                                type={pwd2 ? 'text' : 'password'}
                                error={pwd2_err}
                                inputProps={{ maxLength: 60 }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password confirm visibility"
                                            onClick={handlePwd2Toggle}
                                            onMouseDown={handleMouseDownPwd}
                                            edge="end">
                                            {pwd2 ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PasswordIcon />
                                    </InputAdornment>
                                }
                                inputRef={p2InputRef}
                                name="password_c"
                                value={password_c}
                                onChange={handleInputChange} />
                            <FormHelperText error style={pwd2_err ? showErrStyle : hideErrStyle}>
                                Las contraseñas no coinciden
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom component="div">
                        Permisos
                    </Typography>
                    <FormControlLabel
                        label="Productos"
                        control={
                            <Checkbox
                                name='product_section'
                                checked={ products && product_data && coupons }
                                indeterminate={ !products || !product_data || !coupons }
                                onChange={ handleCheckboxChange }
                            />
                        }
                    />
                    { product_children }
                    <FormControlLabel
                        label="Tienda en línea"
                        control={
                            <Checkbox
                                name='shop_section'
                                checked={ orders && shipping && banners && blog && clients }
                                indeterminate={ !orders || !shipping || !banners || !blog || !clients }
                                onChange={ handleCheckboxChange }
                            />
                        }
                    />
                    { shop_children }
                    <FormControlLabel
                        name="admins"
                        label="Lista administradores"
                        control={
                            <Checkbox checked={ admins }
                                onChange={handleCheckboxChange}
                            />
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button sx={{
                    display: action === 'edit' ? 'none' : 'flex'
                }}
                    onClick={submit}>
                    Registrar
                </Button>
                <Button sx={{
                    display: action === 'edit' ? 'flex' : 'none'
                }}
                    onClick={update}>
                    Actualizar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdminUserModal;