import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

import { costOfSaleInfo } from '../../../actions/admin';

import {
    ListItemButton, Collapse, List, Divider,
    ListItemIcon, ListItemText
} from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DiscountIcon from '@mui/icons-material/Discount';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const ecommerceLinks = [
    {
        title: 'Dashboard',
        url: '',
        icon: DashboardIcon
    },
    {
        title: 'Pedidos',
        url: 'orders',
        icon: ReceiptIcon
    },
    {
        title: 'Costo de ventas ONL',
        url: 'costo-ventas',
        icon: PointOfSaleIcon
    },
    {
        title: 'Carrito abandonado',
        url: 'carrito-abandonado',
        icon: ProductionQuantityLimitsIcon
    },
    {
        title: 'Métodos de envío',
        url: 'metodos-envio',
        icon: LocalShippingIcon
    },
    {
        title: 'Cupones',
        url: 'cupones',
        icon: LocalOfferIcon
    },
    {
        title: 'Promociones',
        url: 'promociones',
        icon: DiscountIcon
    },
    {
        title: 'Contacto',
        url: 'contacto',
        icon: MarkEmailUnreadIcon
    }
    // {
    //     title: 'Carga masiva',
    //     url: 'carga-masiva',
    //     icon: FileUploadIcon
    // },
    // {
    //   title: 'Envío correo de cuentas',
    //   url: 'correo-masivo',
    //   icon: MailLockIcon
    // }
    // comentarios
];

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const EcommerceListItem = ({ props, setOpen }) => {
    const [collapse, setCollapse] = useState(false);
    const handleClick = () => {
        setCollapse(!collapse);
    };

    // generar reporte de costo de venta
    const costOfSale = async () => {
        setOpen(true);
        const req = await costOfSaleInfo();
        if (req.status)
        exportToExcel(req.resp);
        setOpen(false);
    };

    const exportToExcel = async (array) => {
        const ws = XLSX.utils.json_to_sheet(array);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(
            data,
            `costo_ventas${
                format(new Date(), '_dd_MM_yyyy')
            }${fileExtension}`
        );
    };

    return (
        <>
            <ListItemButton onClick={ handleClick }
                sx={[
                    {
                        bgcolor: '#F2F3F4',
                        borderBottom: '1px solid #F2F3F4'
                    },
                    {
                        '&:hover': {
                            backgroundColor: '#EAEDED'
                        }
                    },
                ]}
            >
                <ListItemIcon>
                    <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="E-commerce" />
                { collapse ? <ExpandLess /> : <ExpandMore /> }
            </ListItemButton>
            <Collapse in={ collapse } timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {
                        ecommerceLinks.map(({ icon, title, url }) => {
                            const Icon = icon;
                            return ['carrito-abandonado', 'promociones'].includes(url) ?
                                <React.Fragment key={url}>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        onClick={ props.open }
                                        component={ Link }
                                        to={"/nefro-admin/" + url}>
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={ title } />
                                    </ListItemButton>
                                    <Divider />
                                </React.Fragment>
                            :
                                url === 'costo-ventas' ?
                                    <ListItemButton key={url}
                                        sx={{ pl: 4 }}
                                        onClick={ costOfSale }>
                                        <ListItemIcon>
                                            <Icon />
                                        </ListItemIcon>
                                        <ListItemText primary={ title } />
                                    </ListItemButton>
                                :
                                    <ListItemButton key={url}
                                        sx={{ pl: 4 }}
                                        onClick={ props.open }
                                        component={ Link }
                                        to={"/nefro-admin/" + url}>
                                        <ListItemIcon>
                                            <Icon />
                                        </ListItemIcon>
                                        <ListItemText primary={ title } />
                                    </ListItemButton>
                        })
                    }
                </List>
            </Collapse>
        </>
    );
};

export default EcommerceListItem;