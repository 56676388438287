import React from 'react';

import {
    Button, Dialog, DialogTitle, DialogContent,
    DialogActions, Typography, ImageList, ImageListItem
} from '@mui/material';
import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

const ContactItemDialog = ({ open2, setDialog, selectedForm }) => {
    const {
        fullname, email, tel, email_content: { subject, msg }, images
    } = selectedForm;

    return (
        <Dialog
            sx={{ zIndex: 1000 }}
            open={ open2 }
            onClose={ () => setDialog(e => ({ ...e, open2: false })) }>
            <DialogTitle>
                { `Asunto: ${subject}` }
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Información de contacto:
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} gutterBottom>
                    { `Nombre: ${fullname}` }
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} gutterBottom>
                    { `Correo electrónico: ` }
                    <a href={`mailto:${email}`} style={{ textDecoration: 'none' }}>
                        { email }
                    </a>
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }} gutterBottom>
                    { `Teléfono: ` }
                    <a href={`tel:${tel}`} style={{ textDecoration: 'none' }}>{ tel }</a>
                </Typography>
                {/* <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                    { `"${msg}"` }
                </Typography> */}
                <Typography variant="button"
                    gutterBottom
                    sx={{ display: 'block', mt: 2 }}>
                    { `"${msg}"` }
                </Typography>
                {
                    (images && images.length > 0) &&
                        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                            {
                                images.map(e => (
                                    <ImageListItem key={e}>
                                    <img
                                        src={`${GOOGLE_DRIVE_CDN}${e}`}
                                        alt="imagen-contacto"
                                        loading="lazy"
                                    />
                                    </ImageListItem>
                                ))
                            }
                        </ImageList>
                }
                
            </DialogContent>
            <DialogActions>
                <Button onClick={
                    () => setDialog(e => ({ ...e, open1: false, open2: false }))
                }>
                    Cerrar
                </Button>
                <Button autoFocus onClick={
                    () => setDialog(e => ({ ...e, open1: true, open2: false }))
                }>
                    Responder
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContactItemDialog;