import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ children }) => {
    const location = useLocation();
    const { pathname } = location;
    // const path = pathname.replace(/\/.*\//, '' );

    const { loggedUser } = useSelector(state => state.user);
    const { email, privileges } = loggedUser;
    return (email !== '' && privileges) ?
        pathname === '/nefro-admin' ?
            children
        :
            [
                '/nefro-admin/productos',
                '/nefro-admin/producto-agregar',
                '/nefro-admin/productos-odoo'
            ].includes(pathname) ?
                privileges.products ?
                    children
                :
                    <Navigate to="/nefro-admin" />
            :
                [
                    '/nefro-admin/categorias-producto',
                    '/nefro-admin/marca-producto',
                    '/nefro-admin/orden-productos'
                ].includes(pathname) ?
                    privileges.product_data ?
                        children
                    :
                        <Navigate to="/nefro-admin" />
                :
                    [
                        '/nefro-admin/orders',
                        '/nefro-admin/carrito-abandonado',
                    ].includes(pathname) ?
                        privileges.orders ?
                            children
                        :
                            <Navigate to="/nefro-admin" />
                    :
                        pathname === '/nefro-admin/metodos-envio' ?
                            privileges.shipping ?
                                children
                            :
                                <Navigate to="/nefro-admin" />
                        :
                            [
                                '/nefro-admin/cupones',
                                '/nefro-admin/cupones/registrar',
                                '/nefro-admin/promociones'
                            ].includes(pathname) ?
                                privileges.coupons ?
                                    children
                                :
                                    <Navigate to="/nefro-admin" />
                            :
                                pathname === '/nefro-admin/admins' ?
                                    privileges.admins ?
                                        children
                                    :
                                        <Navigate to="/nefro-admin" />
                                :
                                    pathname === '/nefro-admin/banners-tienda' ?
                                        privileges.banners ?
                                            children
                                        :
                                            <Navigate to="/nefro-admin" />
                                    :
                                        [
                                            '/nefro-admin/blog',
                                            '/nefro-admin/nuevo-blog'
                                        ].includes(pathname) ?
                                            privileges.blog ?
                                                children
                                            :
                                                <Navigate to="/nefro-admin" />
                                        :
                                            children
    :
        <Navigate to="/" />;

    // return (email !== '' && privileges)
    //     ? children
    //     : <Navigate to="/" />
};