import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './FeaturedProducts.css';

//IMG
import shape from '../../../../assets/resources/shape.png';

//MUI
import {
    Box, Typography, IconButton, Stack,
    Grid, useMediaQuery
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";

import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../../constants/constants';

const FeaturedProducts = () => {
    const matches = useMediaQuery('(max-width:610px)');

    // productos destacados
    const { featuredProducts } = useSelector(state => state.shop);
    
    return (
        <>
            {
                featuredProducts.length >= 1 &&
                    <Box className="box_shop" sx={{ p: 3 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '26px', mb: 4 }} textAlign={matches ? 'center' : 'left'}>
                            Productos destacados
                        </Typography>
                        <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                                <IconButton className='featured-swiper-button-prev' size='small'>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xl={10} lg={10} md={10} sm={8} xs={8}>
                                <Swiper
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        610: {
                                            slidesPerView: 2,
                                        },
                                        800: {
                                            slidesPerView: 2,
                                        },
                                        950: {
                                            slidesPerView: 3,
                                        },
                                        1100: {
                                            slidesPerView: 3,
                                        },
                                        1200: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                    spaceBetween={40}
                                    navigation={{
                                        nextEl: '.featured-swiper-button-next',
                                        prevEl: '.featured-swiper-button-prev',
                                    }}
                                    loop={true}
                                    pagination={{
                                        clickable: true,
                                        dynamicBullets: true,
                                    }}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false,
                                        pauseOnMouseEnter: true
                                    }}
                                    modules={[Navigation, Pagination, Autoplay]}
                                    className="mySwiper">
                                    {
                                        featuredProducts.map(({ _id, title, url, prod_variants, reviews }) => {
                                            let review_prom = 0; // promedio reseñas
                                            let review_count = 0 // número reseñas

                                            // precios de variantes
                                            const prices = prod_variants.map(e => e.price);
                                            // precio más económico de variante
                                            const min_price = Math.min(...prices);
                                            
                                            // variante con promoción
                                            const VariantInPromo = prod_variants.find(
                                                e => e.item_promotion && e.item_promotion.promo !== ''
                                            );
                                            // variante con precio más bajo
                                            const CheaperVariant = prod_variants.find(e => e.price === min_price);

                                            /* mostrar variante que tiene promoción activa
                                            caso contrario, mostrar la de menor precio
                                            si no hay coincidencias, mostrar la primera ocurrencia */
                                            const MainVariant = VariantInPromo ?
                                                VariantInPromo
                                            :
                                                CheaperVariant ? CheaperVariant
                                                : prod_variants[0];

                                            if (reviews.length > 0) {
                                                reviews.forEach(element => {
                                                    review_prom += element.rating;
                                                    review_count++;
                                                });
                                                // redondea promedio de reseñas
                                                review_prom = Math.round(review_prom / review_count);
                                            }

                                            // variante a mostrar
                                            const { barcode, item_promotion, images } = MainVariant;
                                            const img = images.length > 0 ?
                                                {
                                                    id: `${GOOGLE_DRIVE_CDN}${images[0].id}`,
                                                    alt: images[0].alt ? images[0].alt : 'producto-nefropolis'
                                                }
                                            :
                                                {
                                                    id: `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`,
                                                    alt: 'producto-nefropolis'
                                                };
                                            
                                            return (
                                                <SwiperSlide style={{ textAlign: 'center' }} key={_id}>
                                                    <Link to={`/tienda/producto/${url}/${barcode}`}>
                                                        <div className="product-in-promotion">
                                                            <img src={ img.id }
                                                                width='100%' style={{ objectFit: 'contain' }}
                                                                alt={ img.alt } />
                                                            {
                                                                item_promotion.promo !== '' &&
                                                                    <div className="shop_top-image">
                                                                        <img src={shape} alt={shape} width='75' style={{ pointerEvents: 'none' }} />
                                                                        <div className="shop_centered_image">
                                                                            {
                                                                                ['2x1', '4x3', 'Descuento%Producto'].includes(item_promotion.promo)
                                                                                    ?
                                                                                    <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                                                        { item_promotion.text }
                                                                                    </Typography>
                                                                                :
                                                                                    <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>
                                                                                        Promoción
                                                                                    </Typography>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </Link>
                                                    <Box sx={{ textAlign: 'center', mt: .5 }}>
                                                        <Link to={`/tienda/producto/${url}/${barcode}`} style={{ textDecoration: 'none', color: '#000' }}>
                                                            <Typography
                                                                variant="body1"
                                                                component="div"
                                                                sx={{ fontWeight: '600' }}
                                                            >
                                                                { title }
                                                            </Typography>
                                                        </Link>
                                                    </Box>

                                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: .5 }}>
                                                        <div className="ratings-shop">
                                                            {
                                                                review_count > 0 ?
                                                                    Array.from(Array(review_prom), (e, j) => {
                                                                        return <i className="fas fa-star"
                                                                            style={{
                                                                                color: '#FFA41C',
                                                                                stroke: "#FFA41C",
                                                                                strokeWidth: 0
                                                                            }}
                                                                            key={j}></i>
                                                                    })
                                                                    :
                                                                    <>
                                                                        <i className="far fa-star"></i>
                                                                        <i className="far fa-star"></i>
                                                                        <i className="far fa-star"></i>
                                                                        <i className="far fa-star"></i>
                                                                        <i className="far fa-star"></i>
                                                                    </>
                                                            }
                                                        </div>
                                                    </Box>

                                                    <Box sx={{
                                                        display: 'flex', mt: 1,
                                                        justifyContent: { xs: 'center', sm: 'center', md: 'center' },
                                                    }}>
                                                        {
                                                            ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo) ?
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="flex-start"
                                                                    alignItems="baseline"
                                                                    spacing={1}>
                                                                    <Typography variant="h6"
                                                                        sx={{ fontWeight: '600', color: '#000', textDecoration: 'line-through' }}>
                                                                        ${min_price}
                                                                    </Typography>
                                                                    <Typography variant="h6"
                                                                        sx={{ fontWeight: '600', color: '#F90606' }} align='center'>
                                                                        ${item_promotion.price}
                                                                    </Typography>
                                                                </Stack>
                                                                :
                                                                <Typography variant="h6"
                                                                    sx={{ fontWeight: '600', color: '#F90606' }} align='center'>
                                                                    ${min_price}
                                                                </Typography>
                                                        }
                                                    </Box>

                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper >
                            </Grid>
                            <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                                <IconButton className='featured-swiper-button-next' size='small'>
                                    <ChevronRightIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
            }
        </>
    );
};

export default FeaturedProducts;