import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import logo from '../../assets/images/Logo_Nefropolis.png';
import no_image from '../../assets/images/banners/coleccion-todos1.jpg';

import './header.css';

import { Animated } from "react-animated-css";

//Utils
import { logout } from '../../actions/user';

//Components
import HeaderAppBar from './HeaderAppBar';
import SearchMobile from './SearchMobile/SearchMobile';
import HeaderSidebar from './HeaderSidebar/HeaderSidebar';
// import LangSelect from './LangSelect/LangSelect';

import { GOOGLE_DRIVE_CDN } from '../../constants/constants';

//MUI
import { AppBar, Toolbar, Box, IconButton, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Header = ({ handleRef }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // carrito
    const { products, cart } = useSelector(state => state.shop);
    const { item } = cart;

    // datos de usuario iniciado
    const { loggedUser:
        { email, username, fullname, privileges }
    } = useSelector(state => state.user);

    const [navbarData, setNavbarData] = useState({
        letter: 'N',
        title: `${t("Cuenta")}`,
        link1: '/login',
        link2: '/mi-cuenta/informacion-y-seguridad'
    });
    // const { letter, title, link1, link2 } = navbarData;

    // cantidad de productos en carrito
    const [itemCount, setItemCount] = useState(0);
    // variantes para barra de búsqueda
    const [options, setOptions] = useState([]);

    const [navbar, setNavbar] = useState(false);
    //Sidebar const
    const [sidebar, setSidebar] = useState(false);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        changeBackground();
        window.addEventListener("scroll", changeBackground);
    });
    
    useEffect(() => {
        if (email)
            setNavbarData(e => ({
                ...e,
                letter:
                    fullname ?
                        fullname.charAt(0).toUpperCase()
                    :
                        username ? username.charAt(0).toUpperCase() : 'N',
                title: privileges && privileges.main ?
                    'Nefro Admin' : `${t("Cuenta")}`,
                link1: privileges && privileges.main ?
                    '/nefro-admin' : '/mi-cuenta',
                link2: privileges && privileges.main ?
                    '/mi-cuenta' : '/mi-cuenta/informacion-y-seguridad'
            }));
    }, [email]);

    useEffect(() => {
        if (products.length > 0) {
            let arr = [];
            const prods = [...products].sort(function (a, b) {
                const a_prom = getAverageReview(a);
                const b_prom = getAverageReview(b);
                return (a_prom < b_prom) ? 1 : ((b_prom < a_prom) ? -1 : 0);
            });
            prods.map(_prod => {
                const { prod_variants, title, url } = _prod;
                prod_variants.map(e => {
                    const { _id: prod_var_id, variant_title, images } = e;
                    const _title = `${title} ${variant_title}`;
                    const img = images.length > 0 ?
                        `${GOOGLE_DRIVE_CDN}${images[0].id}` : no_image;
                    const alt = images.length > 0 ?
                        images[0].alt !== '' ?
                            `${GOOGLE_DRIVE_CDN}${images[0].alt}`
                            : 'producto-nefropolis'
                        : 'producto-nefropolis';
                    arr = [...arr, { prod_var_id, title: _title, url, img, alt }];
                    return e;
                });
                return _prod;
            });
            setOptions(arr);
        }
    }, [products]);

    useEffect(() => { // actualización de carrito
        if (item.length > 0) {
            const stock =
                item.filter(({ quantity }) =>
                    !(['', '0', 0].includes(quantity))).reduce((acum, { quantity }) =>
                        acum + quantity, 0);
            setItemCount(stock);
        } else setItemCount(0);
    }, [cart]);

    const changeBackground = () => {
        if (window.scrollY >= 200)
            setNavbar(true)
        else
            setNavbar(false) // Can't perform a React state update on an unmounted component
    };

    // obtener promedio de reseñas para filtro
    const getAverageReview = (p) => {
        let prom = 0; // promedio reseñas
        let count = 0 // número reseñas
        if (p.reviews.length > 0) {
            p.reviews.forEach(e => {
                prom += e.rating;
                count++;
            });
            // redondea promedio de reseñas
            prom = Math.round(prom / count);
        }
        return prom;
    };

    // cerrar sesión
    const userLogout = () => {
        dispatch(logout());
        window.location.reload();
    };

    // redirigir a página de producto
    const goToProductPage = (event, value) => {
        if (value) {
            const { url, barcode } = value;
            navigate(`/tienda/producto/${url}/${barcode}`);
        }
    };

    return (
        <>
            <HeaderAppBar
                handleRef={ handleRef }
                userLogout={ userLogout }
                itemCount={ itemCount }
                options={ options }
                navbar={ navbar }
                navbarData={ navbarData }
            />

            <div className='search_bar'>
                {/* Navbar tablet and mobile */}
                <AppBar position="sticky" className='navbar_mobile' sx={{ bgcolor: '#fff' }}>
                    <Toolbar sx={{ height: '60px' }}>
                        <IconButton
                            size="large"
                            edge="start"
                            sx={{ mr: !visible ? 2 : 0, color: '#94CC27' }}
                            onClick={() => { setSidebar(true) }}
                        >
                            <MenuIcon fontSize='large' />
                        </IconButton>
                        <Animated animationOut="fadeOut" isVisible={!visible}>
                            {
                                !visible &&
                                <Box>
                                    <Link to='/'>
                                        <img src={logo} alt={logo} width='100' />
                                    </Link>
                                </Box>
                            }
                        </Animated>
                        <Box sx={{ flexGrow: 1 }} />

                        <SearchMobile
                            options={options}
                            goToProductPage={goToProductPage}
                            visible={visible}
                            setVisible={setVisible}
                        />

                        <IconButton
                            size="large"
                            component={Link}
                            to="/carrito"
                            edge="end"
                            sx={[
                                { color: '#000', my: 'auto' },
                                {
                                    '&:hover': {
                                        color: '#000',
                                    },
                                },
                            ]}
                        >
                            <Badge badgeContent={itemCount} color="primary">
                                <ShoppingCartIcon />
                            </Badge>
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </div>

            <HeaderSidebar
                sidebar={ sidebar }
                setSidebar={ setSidebar }
                userLogout={ userLogout }
                navbarData={ navbarData }
            />
        </>
    );
};

export default Header;