import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { getTitle } from '../../../helpers/admin/productHelper';

import PaginationComponent from '../Pagination/Pagination';

import shape from '../../../assets/resources/shape.png';

import {
    Alert, Button, Box, Grid, Typography, Pagination, useMediaQuery
} from '@mui/material';

import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../constants/constants';

const BrandProducts = ({ myRef, brand, filteredProducts }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const returnToShop = () => {
        navigate('/tienda');
    };

    const matches = useMediaQuery('(max-width: 500px)')
    const match_pag = useMediaQuery('(max-width: 374px)')

    //Pagination
    let [page, setPage] = useState(1);
    const PER_PAGE = matches ? 8 : 16;

    const count = Math.ceil(filteredProducts.length / PER_PAGE);
    const _DATA = PaginationComponent(filteredProducts, PER_PAGE);

    const handleChangePagination = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        myRef.current.scrollIntoView()
    };

    return (
        <Grid item xl={10} lg={9} md={12} sm={12} xs={12}>
            <Grid container spacing={3}>
                {
                    filteredProducts.length <= 0 ?
                        <Grid item xs={12}>
                            <Alert severity="info"
                                action={
                                    <Button color="inherit" size="small"
                                        onClick={ returnToShop }>
                                        Ver Tienda
                                    </Button>
                                }>
                                {
                                    brand === '' ?
                                        'No se ha encontrado la marca de producto'
                                    :
                                        `Ningún producto coincide con la marca ${brand}`
                                }
                            </Alert>
                        </Grid>
                    :
                        _DATA.currentData().map(({ _id, title, url, prod_variants, reviews }) => {
                            // precios de variantes
                            const prices = prod_variants.map(e => e.price);
                            const min_price = Math.min(...prices);
                            const max_price = Math.max(...prices);

                            // variante con promoción
                            const VariantInPromo = prod_variants.find(
                                e => e.item_promotion && e.item_promotion.promo !== ''
                            );
                            // variante con precio más bajo
                            const CheaperVariant = prod_variants.find(e => e.price === min_price);

                            /* mostrar variante que tiene promoción activa
                               caso contrario, mostrar la de menor precio
                               si no hay coincidencias, mostrar la primera ocurrencia */
                            const MainVariant = VariantInPromo ?
                                VariantInPromo
                            :
                                CheaperVariant ? CheaperVariant
                                : prod_variants[0];
                            
                            // ¿producto sin variantes?
                            const unique = (
                                prod_variants.length <= 0
                                || min_price === max_price
                            ) ? true : false;

                            let review_prom = 0; // promedio reseñas
                            let review_count = 0 // número reseñas
                            if (reviews.length > 0) {
                                reviews.forEach(element => {
                                    review_prom += element.rating;
                                    review_count++;
                                });
                                // redondea promedio de reseñas
                                review_prom = Math.round(review_prom / review_count);
                            }

                            // variante a mostrar
                            const { variant_title, barcode, item_promotion, images } = MainVariant;
                            const img = images.length > 0 ?
                                {
                                    id: `${GOOGLE_DRIVE_CDN}${images[0].id}`,
                                    alt: images[0].alt ? images[0].alt : 'producto-nefropolis'
                                }
                            :
                                {
                                    id: `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`,
                                    alt: 'producto-nefropolis'
                                };
                            
                            const full_title = getTitle(title, variant_title);
                            
                            return (
                                <Grid item xl={3} lg={4} md={4} sm={6} xs={12} key={_id}>
                                    <Grid container spacing={2}>
                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={5}
                                            sx={{ my: 'auto', textAlign: 'center' }}>
                                            <Link to={`/tienda/producto/${url}/${barcode}`}
                                                state={{ previousPath: pathname }}>
                                                <div className="product-in-promotion">
                                                    <img src={img.id}
                                                        width='100%' style={{ objectFit: 'contain' }}
                                                        className="shop-product"
                                                        alt={img.alt} />
                                                    {
                                                        item_promotion.promo !== '' &&
                                                        <div className="shop_top-image">
                                                            <img src={shape} alt={shape} width='75' style={{ pointerEvents: 'none' }} />
                                                            <div className="shop_centered_image">
                                                                {
                                                                    ['2x1', '4x3', 'Descuento%Producto'].includes(item_promotion.promo)
                                                                        ?
                                                                        <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                                            { item_promotion.text }
                                                                        </Typography>
                                                                        :
                                                                        <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>
                                                                            Promoción
                                                                        </Typography>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Link>
                                        </Grid>

                                        <Grid item xl={12} lg={12} md={12} sm={12} xs={7} sx={{ my: 'auto' }}>
                                            <Link to={`/tienda/producto/${url}/${barcode}`}
                                                className="text-reset text-decoration-none">
                                                <Typography
                                                    sx={{
                                                        fontWeight: '600', textAlign: { xs: 'left', sm: 'center', md: 'center' }
                                                    }}
                                                    variant="body1"
                                                    component="div"
                                                >
                                                    { full_title }
                                                </Typography>
                                            </Link>

                                            <Box sx={{
                                                display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }
                                            }}>
                                                {
                                                    unique ?
                                                        <Typography variant='h6' sx={{ color: '#F90606', fontWeight: '600' }}>
                                                            ${min_price}
                                                        </Typography>
                                                        :
                                                        <Typography variant='h6' sx={{ color: '#F90606', fontWeight: '600' }}>
                                                            ${min_price} - ${max_price}
                                                        </Typography>
                                                }
                                            </Box>

                                            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' } }}>
                                                <div className="ratings-shop">
                                                    {
                                                        review_count > 0 ?
                                                            Array.from(Array(review_prom), (e, j) => {
                                                                return <i className="fas fa-star"
                                                                    style={{
                                                                        color: '#FFA41C',
                                                                        stroke: "#FFA41C",
                                                                        strokeWidth: 0.2
                                                                    }}
                                                                    key={j}></i>
                                                            })
                                                            :
                                                            <>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                                <i className="far fa-star"></i>
                                                            </>
                                                    }
                                                </div>
                                            </Box>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                }
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: matches ? 'center' : 'flex-end', mt: 3 }}>
                <Pagination
                    size={match_pag ? 'small' : 'medium'}
                    color="primary"
                    count={count}
                    page={page}
                    onChange={handleChangePagination}
                />
            </Box>
        </Grid>
    );
};

export default BrandProducts;