import { types } from '../types/types';
import { blockInEdition, blogInEdition } from '../constants/admin/blog_constants';

const active = {
    _id: '',
    email: '',
    username: '',
    role: '',
    privileges: {
        products: false,
        product_data: false,
        coupons: false,
        orders: false,
        shipping: false,
        banners: false,
        blog: false,
        clients: false,
        admins: false
    }
};

const initialState = {
    orders: [], // pedidos
    blog: [], // entradas de blog
    blog_data: {
        blogInEdition,
        blockInEdition
    },
    admins: [],
    activeAdmin: active
};

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.adminUsersLoaded:
            return {
                ...state,
                admins: [...action.payload]
            };
        case types.adminSelected:
            return {
                ...state,
                activeAdmin: action.payload
            };
        case types.adminRegistered:
            return {
                ...state,
                admins: [
                    ...state.admins,
                    action.payload
                ]
            };
        case types.adminUpdated:
            return {
                ...state,
                admins: state.admins.map(
                    e => (e._id === action.payload._id) ? action.payload : e
                ),
            };
        case types.adminDeleted:
            return {
                ...state,
                admins: state.admins.filter(
                    e => (e._id !== action.payload)
                ),
            };
        case types.adminOrders:
            return {
                ...state,
                orders: action.payload
            };
        case types.adminDataLoaded:
            return {
                ...state,
                ...action.payload
            };
        case types.adminBlogLoaded:
            return {
                ...state,
                blog: [...action.payload]
            };
        case types.adminBlogSaved:
            return {
                ...state,
                blog: [...state.blog, action.payload]
            };
        case types.adminBlogUpdated:
            return {
                ...state,
                blog: state.blog.map(e =>
                    (e._id === action.payload._id) ?
                        { ...e, ...action.payload }
                    : e
                )
            };
        case types.adminBlogDeleted:
            return {
                ...state,
                blog: state.blog.filter(
                    e => (e._id !== action.payload)
                )
            };
        case types.adminBlogInEdition:
            return {
                ...state,
                blog_data: {
                    ...state.blog_data,
                    blogInEdition: {
                        ...state.blog_data.blogInEdition,
                        ...action.payload
                    }
                }
            };
        case types.adminBlockInEdition:
            return {
                ...state,
                blog_data: {
                    ...state.blog_data,
                    blockInEdition: {
                        ...state.blog_data.blockInEdition,
                        ...action.payload
                    }
                }
            };
        default:
            return state;
    };
};