import React, { useEffect, useRef, useState } from 'react'

import { getTitle } from '../../../../helpers/admin/productHelper';

//MUI
import {
    Box, Container, FormControl, Grid, InputLabel, MenuItem,
    Pagination, Select, Stack, Typography, useMediaQuery
} from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

//Images
import shape from '../../../../assets/resources/shape.png';

//Redux
import { useSelector } from 'react-redux';

//Router dom
import { Link } from 'react-router-dom';

//Constants
import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../../constants/constants';

//components
import PaginationComponent from "../../Pagination/Pagination";

const PromotionComponent = () => {

    const matches = useMediaQuery('(max-width: 950px)');
    const prod_match_1 = useMediaQuery('(max-width: 1535px)');
    const prod_match_2 = useMediaQuery('(max-width: 550px)');

    // productos en promoción
    const { products } = useSelector(state => state.shop);
    const [promotionProducts, setPromotionProducts] = useState([]);

    useEffect(() => {
        const prods = products.filter(e => {
            const prod = e.prod_variants.filter(u => u.item_promotion && u.item_promotion.promo !== '')
            if (prod.length > 0)
                return true;
            else return false;
        });
        setPromotionProducts(prods);
    }, [products]);

    //Pagination
    let [page, setPage] = useState(1);
    const PER_PAGE = prod_match_2 ? 10 : prod_match_1 ? 12 : 15

    const count = Math.ceil(promotionProducts.length / PER_PAGE);
    const _DATA = PaginationComponent(promotionProducts, PER_PAGE);

    const handleChangePagination = (e, p) => {
        setPage(p);
        _DATA.jump(p);
        window.scrollTo({ top: 0 })
    };

    const myRef = useRef(null);

    const [filter, setFilter] = useState('50');

    // obtener precio menor de variantes para filtro
    const getMinPrice = (p) => {
        const prices = p.prod_variants.map(e => e.price);
        const min = Math.min(...prices);
        return min;
    };

    // obtener promedio de reseñas para filtro
    const getAverageReview = (p) => {
        let prom = 0; // promedio reseñas
        let count = 0 // número reseñas
        if (p.reviews.length > 0) {
            p.reviews.forEach(e => {
                prom += e.rating;
                count++;
            });
            // redondea promedio de reseñas
            prom = Math.round(prom / count);
        }
        return prom;
    };

    // filtrar productos
    const onChangeFilter = ({ target }) => {
        setFilter(target.value);
        const arr = [...promotionProducts];
        switch (target.value) {
            case '10': // menor precio
                setPromotionProducts(arr.sort(function (a, b) {
                    const a_min = getMinPrice(a);
                    const b_min = getMinPrice(b);
                    return (a_min > b_min) ? 1 : ((b_min > a_min) ? -1 : 0);
                }));
                break;
            case '20': // mayor precio
                setPromotionProducts(arr.sort(function (a, b) {
                    const a_min = getMinPrice(a);
                    const b_min = getMinPrice(b);
                    return (a_min < b_min) ? 1 : ((b_min < a_min) ? -1 : 0);
                }));
                break;
            case '30': // nombre ascendente
                setPromotionProducts(arr.sort(function (a, b) {
                    return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
                }));
                break;
            case '40': // nombre descendente
                setPromotionProducts(arr.sort(function (a, b) {
                    return (a.title < b.title) ? 1 : ((b.title < a.title) ? -1 : 0);
                }));
                break;
            case '50':
                setPromotionProducts(arr.sort(function (a, b) {
                    const a_prom = getAverageReview(a);
                    const b_prom = getAverageReview(b);
                    return (a_prom < b_prom) ? 1 : ((b_prom < a_prom) ? -1 : 0);
                }));
                break;
            default:
                break;
        }
    };

    return (
        <Container maxWidth='xxl' sx={{ mt: matches ? 5 : 18 }} className="last-view" ref={myRef}>
            <Typography variant='h5' sx={{ fontWeight: '600', mb: 5 }}>Todos los productos en promoción</Typography>

            {
                promotionProducts.length >= 1
                    ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                            <FormControl className="order-by-shop" size="small">
                                <InputLabel>Ordenar por:</InputLabel>
                                <Select
                                    name={filter}
                                    label="Ordenar por:"
                                    defaultValue={filter}
                                    value={filter}
                                    onChange={onChangeFilter}>
                                    <MenuItem value={'10'}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Menor precio</MenuItem>
                                    <MenuItem value={'20'}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Mayor precio</MenuItem>
                                    <MenuItem value={'30'}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Nombre A - Z</MenuItem>
                                    <MenuItem value={'40'}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Nombre Z - A</MenuItem>
                                    <MenuItem value={'50'}>Rating</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <Grid container spacing={4}>
                            {
                                _DATA.currentData().map(({ _id, title, url, prod_variants, reviews }) => {
                                    let review_prom = 0; // promedio reseñas
                                    let review_count = 0 // número reseñas

                                    // precios de variantes
                                    const prices = prod_variants.map(e => e.price);
                                    // precio más económico de variante
                                    const min_price = Math.min(...prices);

                                    // variante con promoción
                                    const VariantInPromo = prod_variants.find(e => e.item_promotion && e.item_promotion.promo !== '');
                                    // variante con precio más bajo
                                    const CheaperVariant = prod_variants.find(e => e.price === min_price);

                                    /* mostrar variante que tiene promoción activa
                                       caso contrario, mostrar la de menor precio
                                       si no hay coincidencias, mostrar la primera ocurrencia */
                                    const MainVariant = VariantInPromo ?
                                        VariantInPromo
                                    :
                                        CheaperVariant ? CheaperVariant
                                        : prod_variants[0];

                                    if (reviews.length > 0) {
                                        reviews.forEach(element => {
                                            review_prom += element.rating;
                                            review_count++;
                                        });
                                        // redondea promedio de reseñas
                                        review_prom = Math.round(review_prom / review_count);
                                    }

                                    // variante a mostrar
                                    const { variant_title, barcode, item_promotion, images } = MainVariant;
                                    const img = images.length > 0 ?
                                        {
                                            id: `${GOOGLE_DRIVE_CDN}${images[0].id}`,
                                            alt: images[0].alt ? images[0].alt : 'producto-nefropolis'
                                        }
                                    :
                                        {
                                            id: `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`,
                                            alt: 'producto-nefropolis'
                                        };
                                    
                                    const full_title = getTitle(title, variant_title);
                                    return (
                                        <Grid item xxl={2.4} xl={3} lg={4} md={6} sm={6} xs={12}
                                            key={_id} style={{ display: 'flex' }}>
                                            <Box className="shop_prod" sx={{ p: 2 }}>
                                                <Grid container rowSpacing={1} columnSpacing={3} className="product-shop">
                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={5}
                                                        sx={{ my: 'auto', textAlign: 'center' }}>
                                                        <Link to={`/tienda/producto/${url}/${barcode}`}>
                                                            <div className="product-in-promotion">
                                                                <img src={ img.id}
                                                                    width='100%' height='235px' style={{ objectFit: 'contain' }}
                                                                    className="shop-product" alt={ img.alt }
                                                                />
                                                                {
                                                                    item_promotion.promo !== '' &&
                                                                    <div className="shop_top-image">
                                                                        <img src={shape} alt={shape} width='75' style={{ pointerEvents: 'none' }} />
                                                                        <div className="shop_centered_image">
                                                                            {
                                                                                ['2x1', '4x3', 'Descuento%Producto'].includes(item_promotion.promo)
                                                                                    ?
                                                                                    <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                                                        { item_promotion.text }
                                                                                    </Typography>
                                                                                    :
                                                                                    <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>
                                                                                        Promoción
                                                                                    </Typography>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Link>
                                                    </Grid>

                                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={7} sx={{ my: 'auto' }}>
                                                        <Box sx={{ textAlign: { xs: 'flex-start', sm: 'center', md: 'center' } }}>
                                                            <Link to={`/tienda/producto/${url}/${barcode}`} style={{ textDecoration: 'none', color: '#000' }}
                                                                className='product_name'>
                                                                <Typography
                                                                    variant="body1"
                                                                    component="div"
                                                                    sx={{ fontWeight: '600' }}
                                                                >
                                                                    { full_title }
                                                                </Typography>
                                                            </Link>
                                                        </Box>

                                                        <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }, mt: 1 }}>
                                                            <div className="ratings-shop">
                                                                {
                                                                    review_count > 0 ?
                                                                        Array.from(Array(review_prom), (e, j) => {
                                                                            return <i className="fas fa-star"
                                                                                style={{
                                                                                    color: '#FFA41C',
                                                                                    stroke: "#FFA41C",
                                                                                    strokeWidth: 0.2
                                                                                }}
                                                                                key={j}></i>
                                                                        })
                                                                        :
                                                                        <>
                                                                            <i className="far fa-star"></i>
                                                                            <i className="far fa-star"></i>
                                                                            <i className="far fa-star"></i>
                                                                            <i className="far fa-star"></i>
                                                                            <i className="far fa-star"></i>
                                                                        </>
                                                                }
                                                            </div>
                                                        </Box>

                                                        <Box sx={{
                                                            display: 'flex', mt: 1,
                                                            justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }
                                                        }}>
                                                            {
                                                                ['Descuento$Producto', 'Descuento%Producto'].includes(
                                                                        item_promotion.promo
                                                                    ) ?
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="flex-start"
                                                                        alignItems="baseline"
                                                                        spacing={1}>
                                                                        <Typography variant="h6"
                                                                            sx={{ fontWeight: '600', color: '#000', textDecoration: 'line-through' }}>
                                                                            ${min_price}
                                                                        </Typography>
                                                                        <Typography variant="h6"
                                                                            sx={{ fontWeight: '600', color: '#F90606' }}>
                                                                            ${ item_promotion.price }
                                                                        </Typography>
                                                                    </Stack>
                                                                    :
                                                                    <Typography variant="h6"
                                                                        sx={{ fontWeight: '600', color: '#F90606' }}>
                                                                        ${min_price}
                                                                    </Typography>
                                                            }
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>

                        <Box display='flex' mt={5} justifyContent='flex-end'>
                            <Pagination
                                color="primary"
                                count={count}
                                page={page}
                                onChange={handleChangePagination}
                            />
                        </Box>
                    </>
                    :
                    <Box textAlign='center'>
                        <ManageSearchIcon sx={{ fontSize: '40px' }} />
                        <Typography variant='h5'>¡Lo sentimos! Actualmente no contamos con productos en promoción.</Typography>
                        <Typography>Te invitamos a seguir visitando nuestro sitio web para futuras ofertas y descuentos.</Typography>
                    </Box>
            }
        </Container>
    )
}

export default PromotionComponent