import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// funciones
import { categoriesLoad } from '../../../../actions/category';
import { generateURL } from '../../../../helpers/admin/productHelper';
import {
  addProduct, loadProductList, productSnackbarClose
} from '../../../../actions/product';
import { form1Validator } from '../../../../validators/Admin/Product/ProductValidator';
//CSS
import './newproduct.css';
// componentes
import NewVariant from './NewVariant';
import ProductFBT from './FBT/ProductFBT';
import ProductBrand from './Brand/ProductBrand';
import ProductImages from '../Images/ProductImages';
import CategorySelect from './Category/CategorySelect';
import ProductKeywords from './Keywords/ProductKeywords';

import {
  TextField, Snackbar, IconButton, Button, Fab,
  Backdrop, CircularProgress, ToggleButton, Container,
  Typography, Grid, Box, FormGroup, FormControlLabel,
  Switch,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import useMediaQuery from '@mui/material/useMediaQuery';

// constantes
import { productInit, variantInit } from '../../../../constants/constants';

export const NewProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');
  // estado UI de módulo
  const { loading2 } = useSelector(state => state.ui);

  const { products, snackbar } = useSelector(state => state.product);
  const { open, message } = snackbar;
  // valor de producto y variantes
  const [prodValues, setProdValues] = useState(productInit);
  const {
    title, info, additional_images, featured,
    refrigerated, medicine, keywords, variants,
    brand
  } = prodValues;
  // contador (identificador de variante - key)
  const [counter, setCounter] = useState(1);
  // variantes como producto único (se utiliza en ProductFBT.js)
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    dispatch(loadProductList()); // productos
    dispatch(categoriesLoad()); // categorías y marcas de producto
  }, []);
  
  // producto agregado (redireccionar a lista de productos)
  useEffect(() => {
    // al cargar los productos, se toma cada variante como único
    if (products.length > 0) {
      let Products = [];
      for (const e of products) {
        const { _id, keywords, featured, reviews, prod_variants, ..._product } = e;
        for (const variant of prod_variants) {
          const { _id, available, batches, sku, ..._variant } = variant;
          Products = [...Products, {
            prod_id: e._id,
            prod_var_id: variant._id,
            ..._product, ..._variant,
            checked: false
          }];
        }
      }
      setAllProducts(Products);
    }
  }, [products]);
  
  // título / información
  const handleInputChange = ({ target }) => {
    const name = target.name;
    const value = target.value;
    if (name === 'title') {
      const _url = generateURL(value);
      setProdValues({
        ...prodValues,
        title: value,
        url: _url
      });
    } else {
      setProdValues({
        ...prodValues,
        [name]: value
      });
    }
  };

  // producto destacado / necesita refrigeración
  const handleToggleChange = ({ target }) => {
    const _name = target.name;
    if (_name === 'featured')
      setProdValues({
        ...prodValues,
        featured: !featured
      });
    else if (_name === 'refrigerated')
      setProdValues({
        ...prodValues,
        refrigerated: target.checked
      });
    else if (_name === 'medicine')
      setProdValues({
        ...prodValues,
        medicine: target.checked
      });
  };

  // añadir nueva variante
  const addVariant = () => {
    setProdValues({
      ...prodValues,
      variants: [...variants, {
        ...variantInit,
        key: counter
      }]
    });
    // variante añadida
    setCounter(counter+1);
  };

  // registrar producto y variantes
  const handleSubmit = (e) => {
    e.preventDefault();
    if (form1Validator(prodValues)) { // registrar producto
      const fbt_item = allProducts.filter(e => e.checked).map(u => {
        const { prod_id, prod_var_id } = u;
        // const { checked, ...obj } = u;
        // return obj;
        return { prod_id, prod_var_id };
      });
      dispatch(addProduct({ ...prodValues, fbt_item }, navigate));
    }
  };

  // cerrar snackbar
  const handleSnackbarClose = () => {
    dispatch(productSnackbarClose());
  };

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        onClick={handleSnackbarClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  
  return (
    <Container maxWidth='xxl' sx={{ marginTop: '90px', mb: 5 }}>
      <Typography variant='h5' sx={{ fontWeight: 'light' }} paragraph>Añadir nuevo producto</Typography>
      <form onSubmit={handleSubmit}>
        {/* Productos destacado */}
        <Box sx={{ mb: 3 }}>
          <ToggleButton fullWidth={matches ? true : false}
            name="featured"
            value="check"
            selected={featured}
            onChange={handleToggleChange}>
            {
              featured ? <>Destacado<StarIcon sx={{ color: '#ffd600', ml: 1.5 }} /></> :
                <>No destacado<StarBorderIcon sx={{ ml: 1.5 }} /></>
            }
          </ToggleButton>
        </Box>

        {/* necesita refrigeración */}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="refrigerated"
                checked={ refrigerated }
                onChange={ handleToggleChange } />}
            label="Necesita refrigeración" />
        </FormGroup>

        {/* es medicamento */}
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name="medicine"
                checked={ medicine }
                onChange={ handleToggleChange } />}
            label="Es medicamento" />
        </FormGroup>

        <Grid container spacing={4}>
          <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
            {/* Nombre del productos */}
            <Box>
              <TextField
                fullWidth
                name="title"
                label="Título"
                variant="outlined"
                value={title}
                onChange={handleInputChange} />
            </Box>
            
            {/* Información adicional */}
            {/* <Box sx={{ mt: 3 }}>
              <TextField
                fullWidth
                name="info"
                label="Información adicional"
                variant="outlined"
                multiline
                rows={7.7}
                value={info}
                onChange={handleInputChange} />
            </Box> */}

            <ProductImages
              info={ info }
              additional_images={ additional_images }
              handleInputChange={ handleInputChange }
              setProdValues={ setProdValues }
            />

            {/* Variantes */}
            <Box sx={{ mt: 3 }}>
              {
                variants.map((variant, i) =>
                  <NewVariant
                    key={variant.key}
                    pos={i}
                    title={title}
                    variant={variant}
                    setProdValues={setProdValues} />
                )
              }
              <Box sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  endIcon={<AddBoxIcon />}
                  onClick={addVariant}>
                  Nueva variante
                </Button>
              </Box>
            </Box>
          </Grid>

          <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
            {/* Categoría del producto */}
            <Box sx={{ p: 3, mb: 3 }} className='box-shadow-add-product-category'>
              <CategorySelect setProdValues={ setProdValues } />
            </Box>

            <ProductBrand
              brand={ brand }
              setProdValues={ setProdValues }
            />

            <Box sx={{ p: 3 }} className='box-shadow-add-product-category'>
              <ProductKeywords
                keywords={ keywords }
                setProdValues={ setProdValues }
              />
            </Box>

            {/* Comprados juntos frecuentemente */}
            <ProductFBT
              allProducts={ allProducts }
              setAllProducts={ setAllProducts } />
          </Grid>

          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={message}
            action={snackbarAction} />
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={ loading2 }>
            <CircularProgress />
          </Backdrop>
          <Fab type="submit"
            color="primary"
            sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2)
            }}>
            <i className="fa-solid fa-floppy-disk" style={{ fontSize: '1.2rem' }}></i>
          </Fab>
        </Grid>
      </form>
    </Container>
  );
};

export default NewProduct;