import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DraggableCol from './DraggableCol';

import {
    Grid, Card, Alert, Divider, Chip
} from '@mui/material';

const DragDropRow = ({ setOpen, row, setRow }) => {

    // bloque movido de posición
    const onDragEnd = (result, row, setRow) => {
        if (!result.destination) return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = row.find(e => e.id === source.droppableId);
            const i = sourceColumn ? row.findIndex(e => e.id === source.droppableId) : 0;

            const destColumn = row.find(e => e.id === destination.droppableId);
            const j = destColumn ? row.findIndex(e => e.id === destination.droppableId) : 0;

            const sourceItems = sourceColumn ? [...sourceColumn.col] : [];
            const destItems = destColumn ? [...destColumn.col] : [];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);

            let content = [...row];
            content[i] = { ...content[i], col: sourceItems };
            content[j] = { ...content[j], col: destItems };
            setRow(content);
        } else {
            const column = row.find(e => e.id === source.droppableId);
            const i = column ? row.findIndex(e => e.id === source.droppableId) : 0;
            const copiedItems = column ? [...column.col] : [];
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            let content = [...row];
            content[i] = { ...content[i], col: copiedItems };
            setRow(content);
        }
    };
    return (
        <DragDropContext
            onDragEnd={ (result) => onDragEnd(result, row, setRow) }>
            <Card sx={{ mb: 2 }}>
                {
                    row.map(({ col, id }, i) => {
                        return (
                            <Droppable key={id} droppableId={id}
                                direction="horizontal">
                                {(provided, snapshot) => (
                                    <Grid container spacing={1} my={5} px={2}
                                        ref={ provided.innerRef }
                                        { ...provided.droppableProps }>
                                        <Grid item xs={12}>
                                            <Divider>
                                                <Chip label={
                                                    i === 0 ? 'Bloques guardados' : `Fila ${i}`
                                                } variant="outlined" />
                                            </Divider>
                                        </Grid>
                                        {
                                            col.length > 0 ?
                                                col.map(({ id, ...col }, j) =>
                                                    <DraggableCol
                                                        key={id} id={id}
                                                        col={ col } index={j}
                                                        setOpen={ setOpen } 
                                                        row={ row } setRow={ setRow }
                                                    />
                                                )
                                            :
                                                <Grid item xs={12}>
                                                    <Alert severity="info">No hay bloques en la fila</Alert>
                                                </Grid>
                                        }
                                        { provided.placeholder }
                                    </Grid>
                                )}
                            </Droppable>
                        )
                    })
                }
            </Card>
        </DragDropContext>
    );
};

export default DragDropRow;