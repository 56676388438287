import React from 'react'

import './indexComponent.css'

//Assets
import banner_desktop from '../../assets/images/banners/Banners_nuevos/banners-web-nefro-2000x1000.jpg'
import banner_desktop_1 from '../../assets/images/banners/Banners_nuevos/2000x1000-banner_2.jpg'
import banner_tablet from '../../assets/images/banners/Banners_nuevos/banners-web-nefro-1600x900.jpg'
import banner_tablet_1 from '../../assets/images/banners/Banners_nuevos/1600x900-banner_2.jpg'
import banner_mobile from '../../assets/images/banners/Banners_nuevos/banners-web-nefro-1080x920.jpg'
import banner_mobile_1 from '../../assets/images/banners/Banners_nuevos/1080x920-banner_2.jpg'
import envase from '../../assets/resources/Grupo_1.png'
import vendedor from '../../assets/resources/Grupo_2.png'
import lugares from '../../assets/resources/Grupo_3.png'
import product_1 from '../../assets/resources/1malteada-vegetal-600g.png'
import product_2 from '../../assets/resources/MEDICAMENTOS_PATENTE.png'
import product_3 from '../../assets/resources/manual-introducción.png'
import img from '../../assets/resources/imagen-categorias_low.jpg'
// import desc from '../../assets/resources/10.png'
import img_4 from '../../assets/images/laboratorio-NIN_low.jpg'

// Import Swiper styles
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from "swiper";
import { Pagination as SwiperPag } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

//MUI
import {
    Box, Container, Grid, Typography, Button
} from '@mui/material'
import { styled } from "@mui/material/styles";
import SucursalesIndexContainer from './SucursalesIndexContainer/SucursalesIndexContainer'

//Router dom
import { Link } from 'react-router-dom'

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    paddingLeft: '45px',
    paddingRight: '45px',
    fontWeight: '600',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

const ButtonGreen = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: '600',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

// const CouponButton = styled(Button)(({ theme }) => ({
//     color: '#fff',
//     backgroundColor: '#5552B2',
//     '&:hover': {
//         backgroundColor: '#3e3c8b',
//         color: '#fff'
//     }
// }));

const Index = () => {

    return (
        <div className='bg_index'>
            {/* Carousel desktop */}
            <div className="banners-desktop">
                <Swiper
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Navigation, SwiperPag, Autoplay]}
                    style={{ width: '100%', height: '100%', zIndex: 0 }}
                >
                    <SwiperSlide><img src={banner_desktop} width="100%" style={{ objectFit: 'cover' }} alt="" /></SwiperSlide>
                    <SwiperSlide><img src={banner_desktop_1} width="100%" style={{ objectFit: 'cover' }} alt="" /></SwiperSlide>
                </Swiper>
            </div>
            {/* Fin carousel desktop */}

            {/* Carousel tablet */}
            <div className="banners-tablet">
                <Swiper
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 8000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Navigation, SwiperPag, Autoplay]}
                    style={{ width: '100%', height: '100%', zIndex: 0 }}
                >
                    <SwiperSlide><img src={banner_tablet} width="100%" style={{ objectFit: 'cover' }} alt="" /></SwiperSlide>
                    <SwiperSlide><img src={banner_tablet_1} width="100%" style={{ objectFit: 'cover' }} alt="" /></SwiperSlide>
                </Swiper>
            </div>
            {/* Fin carousel tablet */}

            {/* Carousel mobile */}
            <div className="banners-mobile">
                <Swiper
                    navigation={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 5500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Navigation, SwiperPag, Autoplay]}
                    style={{ width: '100%', height: '100%', zIndex: 0 }}
                >
                    <SwiperSlide><img src={banner_mobile} width="100%" style={{ objectFit: 'cover' }} alt="" /></SwiperSlide>
                    <SwiperSlide><img src={banner_mobile_1} width="100%" style={{ objectFit: 'cover' }} alt="" /></SwiperSlide>
                </Swiper>
            </div>
            {/* Fin carousel mobile */}

            <Container maxWidth='xl' sx={{ my: 10 }}>
                <Grid container spacing={4}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 1, lg: 1, md: 1, sm: 2, xs: 2 }}>
                        <div className='video-wrapper'>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/v6i8wnbpZe0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                            </iframe>
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ my: 'auto' }} order={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                        <Typography sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '36px' }} gutterBottom>Nosotros</Typography>
                        <Typography variant='body1'>
                            Somos la <b>tienda especializada</b> líder para pacientes con <b>Enfermedad Renal Crónica</b>. Contamos
                            con <b>15 tiendas</b> en toda la república mexicana, donde podrás encontrar productos de marcas
                            reconocidas a nivel nacional e internacional. Ofrecemos una amplia gama de <b>productos y servicios</b>
                            para mejorar su calidad de vida como: <b>alimentos especiales, suplementos, medicamentos, manuales
                                educativos</b>, entre otros. Además, contamos con servicios en <b>consultas de nutrición renal</b> y estudios
                            de composición corporal. Tu salud renal es nuestra prioridad. ¡Visítanos hoy!
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='xl' sx={{ my: 10 }}>
                <Grid container spacing={6}>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ display: 'flex', mx: 'auto' }}>
                        <Box p={3} className='card_services'>
                            <div className='card_services_header'>
                                <img src={envase} alt={envase} width='55' height='55' style={{ objectFit: 'contain' }} />
                                <Typography paragraph
                                    sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '22px', mt: 2, lineHeight: 'normal' }}
                                >
                                    Catálogo con más de 80 productos.
                                </Typography>
                            </div>
                            <Typography variant='body1'>
                                Para tratar y dar soporte nutricional a la ERC (Enfermedad Renal Crónica) en todas
                                sus etapas, de las marcas y laboratorios más reconocidos a nivel mundial.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ display: 'flex', mx: 'auto' }}>
                        <Box p={3} className='card_services'>
                            <div className='card_services_header'>
                                <img src={vendedor} alt={vendedor} width='55' height='55' style={{ objectFit: 'contain' }} />
                                <Typography paragraph
                                    sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '22px', mt: 2, lineHeight: 'normal' }}
                                >
                                    Acompañamiento Profesional.
                                </Typography>
                            </div>
                            <Typography variant='body1'>
                                Por el <b>equipo de nutrición renal</b> especializada con la tecnología para atenderte
                                desde donde te encuentres; de forma presencial o remota.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ display: 'flex', mx: 'auto' }}>
                        <Box p={3} className='card_services'>
                            <div className='card_services_header'>
                                <img src={lugares} alt={lugares} width='55' height='55' style={{ objectFit: 'contain' }} />
                                <Typography paragraph
                                    sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '22px', mt: 2, lineHeight: 'normal' }}
                                >
                                    Cerca de tí.
                                </Typography>
                            </div>
                            <Typography variant='body1'>
                                Contamos con <b>15 sucursales</b> alrededor de todo el país que son atendidas por Nutriólogas
                                Renales especializadas. También puedes realizar tus pedidos sin salir de casa, por
                                Whatsapp o en nuestra tienda en línea.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth='lg' sx={{ my: 10 }}>
                <Typography variant='h5' sx={{ color: '#5552B2', fontWeight: 'bold', mb: 5 }} align='center' paragraph>
                    Lo que más <span style={{ color: '#2BD8ED' }}>importa es tu salud</span>
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                        <Typography sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '50px' }}>+ 7 mil</Typography>
                        <Typography variant='h6' sx={{ color: '#409CF8', fontWeight: 'bold' }}>
                            Entornos Renales Sanados
                        </Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                        <Typography sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '50px' }}>+ 10 mil</Typography>
                        <Typography variant='h6' sx={{ color: '#409CF8', fontWeight: 'bold' }}>
                            Clientes Satisfechos
                        </Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                        <Typography sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '50px' }}>+ 1.2 mill</Typography>
                        <Typography variant='h6' sx={{ color: '#409CF8', fontWeight: 'bold' }}>
                            Productos Vendidos
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            {/* Productos */}
            <Box className='products_wrap' sx={{ my: 10 }}>
                <Box className='products_left_side'>
                    <img src={img} alt={img} width='100%' />
                </Box>
                <Box sx={{ p: 3 }} className='products_right_side'>
                    <Typography align='center' sx={{ color: '#5552B2', fontSize: '40px ', fontWeight: 'bold', mb: 2 }}>
                        Productos
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xl={3.5} lg={3.5} md={4} sm={6} xs={12} sx={{ my: 'auto', textAlign: 'center' }}>
                            <div className='product_img_one'>
                                <img src={product_1} alt="" width='80%' />
                            </div>
                        </Grid>
                        <Grid item xl={5} lg={8.5} md={8} sm={6} xs={12} sx={{ my: 'auto' }}>
                            <Typography sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '28px' }} gutterBottom>
                                Suplementos
                            </Typography>
                            <Typography variant='body2' paragraph>
                                Probióticos, Prebióticos, Malteadas nutritivas (Prediálisis) Malteadas (Diálisis) y
                                suplementos nutricionales diversos para coadyuvar y mitigar los síntomas de la
                                Enfermedad Renal Crónica.
                            </Typography>
                            <ColorButton
                                variant='contained'
                                sx={{ textTransform: 'none' }}
                                LinkComponent={Link}
                                to='/tienda'
                            >
                                Comprar
                            </ColorButton>
                        </Grid>
                    </Grid>

                    <Box sx={{ my: { xl: 0, lg: 0, md: 0, sm: 4, xs: 4 } }}>
                        <Grid container spacing={2}>
                            <Grid item xl={3.5} lg={12} md={12} sm={12} xs={12}
                                order={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}></Grid>
                            <Grid item xl={5} lg={8.5} md={8} sm={6} xs={12} sx={{ my: 'auto' }}
                                order={{ xl: 2, lg: 2, md: 2, sm: 2, xs: 3 }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold', color: '#5552B2', lineHeight: 'normal', fontSize: '28px',
                                        textAlign: { xl: 'right', lg: 'right', md: 'right', sm: 'center', xs: 'center' }
                                    }}
                                    gutterBottom
                                >
                                    Medicamentos de patente
                                </Typography>
                                <Typography variant='body2'
                                    sx={{ textAlign: { xl: 'right', lg: 'right', md: 'right', sm: 'center', xs: 'center' } }} paragraph>
                                    Distribución y venta de medicamentos especializados en la Enfermedad Renal Crónica.
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: { xl: 'rigth', lg: 'rigth', md: 'rigth', sm: 'center', xs: 'center' } }}>
                                    <ColorButton
                                        variant='contained'
                                        sx={{ textTransform: 'none' }}
                                        LinkComponent={Link}
                                        to='/tienda'
                                    >
                                        Comprar
                                    </ColorButton>
                                </Box>
                            </Grid>
                            <Grid item xl={3.5} lg={3.5} md={4} sm={6} xs={12} sx={{ my: 'auto' }}
                                order={{ xl: 3, lg: 3, md: 3, sm: 3, xs: 2 }}
                            >
                                <div className='product_img_two'>
                                    <img src={product_2} alt="" width='100%' />
                                </div>
                            </Grid>
                        </Grid>
                    </Box>

                    <Grid container spacing={2}>
                        <Grid item xl={3.5} lg={3.5} md={4} sm={6} xs={12} sx={{ my: 'auto' }}>
                            <div className='product_img_two'>
                                <img src={product_3} alt="" width='100%' />
                            </div>
                        </Grid>
                        <Grid item xl={8.5} lg={8.5} md={8} sm={6} xs={12} sx={{ my: 'auto' }}>
                            <Typography sx={{ fontWeight: 'bold', color: '#5552B2', fontSize: '28px' }} gutterBottom>
                                Material educativo.
                            </Typography>
                            <Typography variant='body2' paragraph>
                                Nuestro Instituto de investigación diseña y desarrolla manuales y guías para el
                                tratamiento y cuidado de la ERC; desde el abordaje de la nutrición y sus complicaciones.
                            </Typography>
                            <ColorButton
                                variant='contained'
                                sx={{ textTransform: 'none' }}
                                LinkComponent={Link}
                                to='/tienda'
                            >
                                Comprar
                            </ColorButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <div id='servicios' style={{ marginTop: '20px' }}></div>

            <Container maxWidth='lg' sx={{ my: 10 }}>
                <Typography variant='h4' align='center' sx={{ color: '#5552B2', fontWeight: 'bold', mb: 5 }}>
                    Nuestros servicios
                </Typography>
                <Grid container spacing={6} sx={{ textAlign: 'center' }}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='bg_bowl'></div>
                        <Typography sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '28px' }} paragraph>
                            Nutrición renal
                        </Typography>
                        <Typography variant='body1'>
                            Consultas especializadas de Nutrición Renal y Análisis de composición corporal
                            BCM (herramienta diagnóstica valiosa para conocer la cantidad y distribución del agua).
                        </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div className='bg_research'></div>
                        <Typography sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '28px' }} paragraph>
                            Análisis de composición corporal
                        </Typography>
                        <Typography variant='body1'>
                            Análisis de composición corporal BCM (herramienta diagnóstica valiosa
                            para conocer la cantidad y distribución del agua)
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            {/* <Container maxWidth='lg' sx={{ my: 10 }}>
                <Card className='coupon_card' sx={{ mx: 'auto' }}>
                    <CardContent>
                        <Box className='coupon_flex'>
                            <Box className='coupon_left' sx={{ my: 'auto' }}>
                                <img src={desc} alt={desc} width='80%' />
                            </Box>
                            <Box className='coupon_rigth' sx={{ my: 'auto' }}>
                                <Typography variant="body1" gutterBottom>
                                    Descuento en tu primer consulta
                                </Typography>
                                <Typography variant="body1" sx={{ color: '#5552B2' }} paragraph>
                                    Cupón: NFPS_NR
                                </Typography>
                                <CouponButton
                                    variant='contained'
                                    LinkComponent='a'
                                    href='https://nutricionrenal.com/'
                                    target='_blank'
                                >
                                    Obtener cupón
                                </CouponButton>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Container> */}

            <Container maxWidth='xl' sx={{ my: 10 }}>
                <Typography variant='body1' sx={{ color: '#4FC380', fontWeight: '600' }} align='center'>Más cerca de tí</Typography>
                <Typography align='center' sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '38px' }}>
                    Sucursales
                </Typography>
                <Typography variant='body1' align='center' sx={{ mb: 5 }}>
                    Contamos con <b>15 puntos de venta</b> distribuidos por toda la república mexicana. <br></br>
                    Encuentra tu sucursal más cercana.
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}
                        sx={{ display: { xl: 'block', lg: 'block', md: 'block', sm: 'none', xs: 'none' } }}
                    >
                        <iframe src="https://www.google.com/maps/d/u/4/embed?mid=1s5j_fBzN7qDS0E4jXgpcN6CDecfDg_o&ehbc=2E312F&noprof=1"
                            width="100%" height="435" style={{ border: '0', overflowX: 'hidden' }} title="Sucursales en México">
                        </iframe>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <SucursalesIndexContainer />
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{ my: 10 }}>
                <Box className='dis_sec'>
                    <Box className='dis_left'>
                        <img src={img_4} alt={img_4} width='100%' />
                    </Box>
                    <Box className='dis_rigth' sx={{ p: '40px', my: 'auto' }}>
                        <Typography className='title_sec' variant='h4' sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '38px' }} paragraph>
                            Centro de desarrollo NIN
                        </Typography>
                        <Typography variant='body1' paragraph>
                            NIN Institute es un centro de investigación y desarrollo de productos nutricionales comprometidos
                            con tu salud. Los productos NIN, son parte de su línea de productos especializados para las
                            necesidades de personas con ERC.
                        </Typography>
                        <Typography variant='body1' paragraph>
                            ¿Te gustaría ser distribuidor de nuestra línea de productos NIN?
                        </Typography>
                        <ButtonGreen
                            variant='contained'
                            LinkComponent='a'
                            href='https://www.nin.com.mx/'
                            target='_blank'
                        >
                            Conoce más aquí
                        </ButtonGreen>
                    </Box>
                </Box>
                {/* <Box className='med_sec'>
                    <Box className='med_left' sx={{ p: '40px', my: 'auto' }}>
                        <Typography className='title_sec' variant='h4' sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '38px' }} paragraph>
                            Directorio médico
                        </Typography>
                        <Typography variant='body1' paragraph>
                            Contamos con una <b>red estratégica de médicos y especialistas</b> de soporte para la atención
                            oportuna de la Enfermedad Renal Crónica.
                        </Typography>
                        <ButtonGreen variant='contained'>Más información</ButtonGreen>
                    </Box>
                    <Box className='med_rigth'>
                        <img src={img_14} width='100%' />
                    </Box>
                </Box> */}
            </Box>

            {/* <Container maxWidth='xl' sx={{ my: 10 }}>
                <Typography variant='body1' sx={{ color: '#4FC380' }} align='center'>
                    Informate de los profesionales
                </Typography>
                <Typography align='center' sx={{ color: '#5552B2', fontWeight: 'bold', fontSize: '38px' }}>
                    Mundo Renal
                </Typography>
                <Typography variant='body1' align='center' sx={{ mb: 5 }}>
                    Conoce más sobre la investigación de protocolos, artículos científicos y <br></br>
                    material de soporte técnico de productos de la ERC.
                </Typography>
                <Grid container spacing={matches ? 2 : 4}>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
                        <img src={blog_1} alt={blog_1} width='100%' />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
                        <img src={blog_3} alt={blog_3} width='100%' />
                    </Grid>
                    <Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
                        <img src={blog_1} alt={blog_1} width='100%' />
                    </Grid>
                </Grid>
                <Box sx={{ textAlign: 'center', mt: 5 }}>
                    <ColorButton variant='contained'>Ver más</ColorButton>
                </Box>
            </Container> */}
        </div>
    )
}

export default Index