import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Components
import BlogItemAdmin from './BlogItemAdmin';

import { loadBlog } from '../../../actions/admin/blog';

//MUI
import {
    Button, Container, Grid, Typography, Alert, AlertTitle,
    Stack, LinearProgress
} from '@mui/material';
import BookIcon from '@mui/icons-material/Book';

const Blog = () => {
    const dispatch = useDispatch();
    // entradas de blog
    const { blog } = useSelector(state => state.admin);

    const { loading: { admin_modules } } = useSelector(state => state.ui);

    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (!admin_modules) {
            if (blog.length <= 0)
                dispatch(loadBlog(setLoading));
        }
    }, []);

    return (
        <Container maxWidth={ false }
            sx={{ backgroundColor: '#F5F5F5', p: 4, height: '100vh' }}>
            <Stack direction="row"
                alignItems="flex-start"
                spacing={12}>
                <Typography variant='h5' gutterBottom>Blog</Typography>
                <Link to="/nefro-admin/nuevo-blog" style={{ textDecoration: 'none' }}>
                    <Button
                        variant='outlined'
                        sx={{ mb: 3, textTransform: 'none' }}
                        endIcon={<BookIcon />}
                    >
                        Crear nuevo blog
                    </Button>
                </Link>
            </Stack>
            {
                (admin_modules || loading) ?
                    <Stack spacing={2}>
                        <Alert severity="info">
                            <Typography variant="h6" gutterBottom>
                                Cargando entradas de blog...
                            </Typography>
                        </Alert>
                        <LinearProgress color="info" />
                    </Stack>
                :
                    blog.length > 0 ?
                        <Grid container spacing={4}>
                            {
                                blog.map((item, i) => (
                                    <BlogItemAdmin
                                        key={i}
                                        item={ item }
                                    />
                                ))
                            }
                        </Grid>
                    :
                        <Alert severity="info" variant="outlined">
                            <AlertTitle>Sin resultados</AlertTitle>
                            No existen entradas de blog.
                        </Alert>
            }
        </Container>
    );
};

export default Blog;