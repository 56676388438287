import { types } from "../types/types";
import { msg, htmlMsg, basicMsg } from "./swal_msg";
import { protectedReq } from "../helpers/requestHelper";
import { adminModuleData, adminUserDialogToggle, adminUserModalToggle } from "./ui";

const reset = {
    status: false,
    action: 'add'
};

// cargar de pedidos/blog/usuarios admin para módulo de administrador
export const loadAdminData = () => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('admin/list');
            const body = await resp.json();
            if (body.status)
                dispatch(adminDataLoaded(body.resp));
            dispatch(adminModuleData(false));
        } catch (err) {
            dispatch(adminModuleData(false));
        };
    };
};

const adminDataLoaded = (payload) => ({
    type: types.adminDataLoaded, payload
});

// cargar lista de administradores
export const loadAdminList = () => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('admin/user-list');
            const body = await resp.json();
            if (body.status)
                dispatch(adminUsersLoaded(body.resp));
        } catch (err) {
            basicMsg('No se puede mostrar la lista de administradores');
        };
    };
};

// lista de usuarios admin cargada
const adminUsersLoaded = (payload) => ({
    type: types.adminUsersLoaded, payload
});

// guarda administrador seleccionado y abre dialog
export const startEdition = (model) => {
    return async(dispatch) => {
        dispatch(adminSelected(model));
        dispatch(adminUserDialogToggle(true));
    };
};

// administrador activo
const adminSelected = (payload) => ({
    type: types.adminSelected, payload
});

// registro de administrador
export const regAdmin = (obj) => {
    return async(dispatch) => {
        try {
            const model = { ...obj, privileges: { ...obj.privileges, main: true }}
            const resp = await protectedReq('admin/register', model, 'POST');
            const body = await resp.json();
            if (body.status) {
                const { password, password_c, ...rmng } = model;
                const { resp: { _id } } = body;
                dispatch(registered({ ...rmng, _id }));
                dispatch(adminUserModalToggle(reset));
                msg('success', 'Registro',
                'Administrador registrado');
            } else {
                if (body.msg === 'email-exists')
                    msg('warning', 'Registro',
                    'Ya existe un usuario con ese correo electrónico');
                else if (body.msg === 'username-exists')
                    msg('warning', 'Registro',
                    'Nombre de usuario ya fue utilizado');
                else if (body.msg === 'server-err')
                    msg('error', 'Registro',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Registro',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Registro',
            'No se pudo registrar el administrador');
        };
    };
};

// administrador registrado
const registered = (payload) => ({
    type: types.adminRegistered,
    payload
});


// actualizar administrador
export const updAdmin = (model) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('admin/update', model, 'PUT');
            const body = await resp.json();
            if (body.status) {
                dispatch(updated(model));
                //dispatch(adminUserModalToggle(reset));
                msg('success', '¡Éxito!',
                    'Administrador actualizado');
            } else {
                if (body.msg === 'not-found')
                    msg('warning', 'Actualización',
                    'No se encontró el administrador');
                else if (body.msg === 'server-err')
                    msg('error', 'Actualización',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Actualización',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Actualización',
            'No se pudo actualizar el administrador');
        }
    }
};

// administrador actualizado
const updated = (payload) => ({
    type: types.adminUpdated,
    payload
});

// eliminar administrador
export const delAdmin = (_id) => {
    return async(dispatch) => {
        try {
            const resp = await protectedReq('admin/delete', { _id }, 'DELETE');
            const body = await resp.json();
            if (body.status) {
                dispatch(deleted(_id));
                msg('success', '¡Éxito!',
                    'Administrador eliminado');
            } else {
                if (body.msg === 'not-found')
                    msg('warning', 'Eliminación',
                    'No se encontró el administrador');
                else if (body.msg === 'server-err')
                    msg('error', 'Eliminación',
                    'Ocurrió un incidente. Intente de nuevo, por favor');
                else msg('warning', 'Eliminación',
                    'Inténtelo de nuevo, por favor');
            }
        } catch (err) {
            msg('error', 'Eliminación',
            'No se pudo eliminar el administrador');
        }
    }
};

// administrador eliminado
const deleted = (payload) => ({
    type: types.adminDeleted,
    payload
});

// envío de correo de creación de cuentas al cliente
export const massMailing = async(_array) => {
    try {
        const filtered = _array.filter(e => e.correo_electronico);
        const array = filtered.map(({ nombre, nombre_usuario, correo_electronico, registro }) => {
            return {
                fullname: nombre ? nombre : '',
                username: nombre_usuario ? nombre_usuario : '',
                email: correo_electronico,
                registration_date: registro ? new Date(registro) : new Date()
            }
        });
        const resp = await protectedReq('admin/mass-mailing', { array }, 'POST');
        const body = await resp.json();
        if (body.status) {
            const { account_exist, account_me } = body.resp;
            const txt1 = account_exist.length === 0 && account_me.length === 0 ?
                'Todos los correos han sido enviados con éxito' :
                `<ul><li>${account_exist.length} cuentas ya existen. El correo no se envió a dichas direcciones.</li>
                <li>Falta el correo electrónico en ${account_me.length} filas. Las cuentas no fueron creadas.</li>
                </ul>`;
            htmlMsg('success', 'Correos enviados', txt1);
        } else {
            if (body.msg === 'miss')
                msg('warning', 'Envío de correos',
                'No hay información para enviar');
            else if (body.msg === 'server-err')
                msg('error', 'Envío de correos',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else msg('warning', 'Envío de correos',
                'Inténtelo de nuevo, por favor');
        }
        return body.status;
    } catch (err) {
        msg('error', 'Envío de correos',
        'No se pudo hacer el envío de correos');
        return false;
    }
};

// reenvío (generación) de facturas en YMF
export const ticketForwarding = async(array) => {
    try {
        const groupBy = (input, key) => {
            return input.reduce((acc, currentValue) => {
                let groupKey = currentValue[key];
                if (!acc[groupKey]) {
                    acc[groupKey] = [];
                }
                acc[groupKey].push(currentValue);
                return acc;
            }, {});
        };
        // const groupBy = (input, key) => {
        //     return input.reduce((acc, currentValue) => {
        //         const groupKey = currentValue[key];
        //         const found = acc.find(e => e.);
        //         if (!acc[groupKey]) {
        //             acc[groupKey] = [];
        //         }
        //         acc[groupKey].push(currentValue);
        //         return acc;
        //     }, []);
        // };
        const tickets = groupBy(array, 'Pedido');
        const resp = await protectedReq('payment/reenvio-facturas', { tickets }, 'POST');
        const body = await resp.json();
        if (body.status) {
            const { failed, success, no_info, not_found } = body.resp;
            const txt = 
                `Resumen: <ul><li>(${success.length}) facturas han sido actualizadas.</li><li>(${failed.length}) no actualizadas en YMF.</li>
                <li>Falta información en (${no_info.length}) pedidos.</li><li>Pedidos no encontrados (${not_found.length}).</li>
                </ul>`
            htmlMsg('success', 'Reenvío de facturas', txt);
        } else {
            if (body.msg === 'miss')
                msg('warning', 'Reenvío de facturas',
                'No hay información para enviar');
            else if (body.msg === 'server-err')
                msg('error', 'Reenvío de facturas',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else msg('warning', 'Reenvío de facturas',
                'Inténtelo de nuevo, por favor');
        }
        return body.status;
    } catch (err) {
        msg('error', 'Reenvío de facturas',
        'No se pudo hacer el envío de pedidos');
        return false;
    }
};

// recuperar información de pedidos para reporte de costo de ventas
export const costOfSaleInfo = async() => {
    try {
        const resp = await protectedReq('order/cost-of-sale');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'not-found')
                msg('warning', 'Reporte Costo de Ventas',
                'No hay pedidos');
            else if (body.msg === 'server-err')
                msg('error', 'Reporte Costo de Ventas',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else msg('warning', 'Reporte Costo de Ventas',
                'Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        msg('error', 'Costo de Ventas',
        'No se pudo generar el reporte');
        return false;
    }
};