import React, { useState } from 'react';

import { simpleConfirmDialog } from '../../../actions/swal_msg';
import { replyEmail } from '../../../actions/admin/form/contact_form';

import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Stack, Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const FormDialog = ({ setForms, open1, setDialog, selectedForm }) => {
    const {
        _id, fullname, email_content: { subject, msg }, replied
    } = selectedForm;

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClose = async () => {
        if (message.length >= 5) {
            if (await simpleConfirmDialog('¿Desea salir del formulario?', 'Sí, continuar')) {
                setMessage('');
                setDialog(e => ({ ...e, open2: true, open1: false }));
            }
        } else {
            setMessage('');
            setDialog(e => ({ ...e, open2: true, open1: false }));
        }
    };

    const handleOnChange = ({ target }) => {
        // const len = message.length;
        if (message.length <= 2000)
            setMessage(target.value);
    };

    const sendEmail = async () => {
        if (replied) {
            if (await simpleConfirmDialog(
                'Ya ha respondido a este correo por este medio, ¿Desea enviar otra respuesta?', 'Sí, enviar'
            ))
                await replyFn();
        } else
            await replyFn();
    };

    const replyFn = async () => {
        setLoading(true);
        const req = await replyEmail({ _id, message });
        setLoading(false);
        if (req.status) {
            setForms(e => e.map(
                u => u._id === _id ?
                { ...u, replied: true } : u
            ));
            setDialog(e => ({ ...e, open2: false, open1: false }));
            setMessage('');
        }
    }

    return (
        <Dialog
            fullWidth
            sx={{ zIndex: 1000 }}
            open={ open1 }
            onClose={ handleClose }>
            <DialogTitle>
                { subject }
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant="body2" gutterBottom>
                        { `${fullname} escribe...` }
                    </Typography>
                    <Typography variant="overline" gutterBottom
                        sx={{ display: 'block' }}>
                        { `"${msg}"` }
                    </Typography>
                    <TextField
                        variant="filled"
                        label="Respuesta:"
                        multiline
                        rows={4}
                        value={ message }
                        onChange={ handleOnChange }
                    />
                    
                </Stack>
            </DialogContent>
            
            <DialogActions>
                <Button onClick={ handleClose }>
                    Regresar
                </Button>
                <LoadingButton
                    autoFocus
                    endIcon={<CloudUploadIcon />}
                    loading={ loading }
                    loadingPosition="end"
                    onClick={ sendEmail }>
                    Enviar
                </LoadingButton>
                {/* <Button autoFocus
                    onClick={ sendEmail }>
                    Enviar
                </Button> */}
            </DialogActions>
        </Dialog>
    );
};

export default FormDialog;