import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CartPromotionCountdown from './CartPromotionCountdown';

import { basicMsg } from '../../../actions/swal_msg';
import { getTitle } from '../../../helpers/admin/productHelper';
import { addItemsToCart, saveItemToCart } from '../../../actions/shop';

import {
    Button, Dialog, DialogTitle, DialogContent, Stack, Divider,
    DialogActions, Typography, Link, Box, List, ListItem,
    ListItemText, ListItemAvatar, Avatar, IconButton, Grid
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../constants/constants';
import { CURRENT_PROMOTION } from '../../../constants/shop/promotion/promotion_const';

const CartPromotionDialog = ({
    cart_promotion, goToProductPage
}) => {
    const dispatch = useDispatch();
    // diálogo para agregar productos y activar la promoción
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogText, setDialogText] = useState({
        dialog_title: 'Completar la promoción',
        dialog_text: 'Productos ya en carrito:',
        completed: true
    });
    const { dialog_title, dialog_text, completed } = dialogText;
    const [currentPromotion, setCurrentPromotion] = useState(CURRENT_PROMOTION);
    const { promo_id, promo, pkg_qty, info, products, active } = currentPromotion;

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (promo_id !== '') {
            // misma promoción
            const Promotion = cart_promotion.find(e => e.promo_id === promo_id);
            if (Promotion) { // promoción sigue válida
                // cambió arreglo (se agregaron productos)
                if (products !== Promotion.products)
                    setCurrentPromotion(Promotion);
            }
        }
    }, [cart_promotion]); // todas las promos de productos en carrito

    useEffect(() => {
        // ¿promoción completa?
        const Completed = products.map(({ in_cart }) => in_cart);
        if (Completed.every(e => e === true) && active) {
            // handleClose(); // cerrar diálogo
            setDialogText(e =>
                ({ ...e,
                    dialog_title: 'Promoción activa',
                    dialog_text: 'Productos de promoción:',
                    completed: true
                })
            );
        } else
            setDialogText(e =>
                ({ ...e,
                    dialog_title: 'Completar la promoción',
                    dialog_text: 'Productos ya en carrito:',
                    completed: false
                })
            );
    }, [products]);

    const handleClose = () => {
        setDialogOpen(false);
    };

    // abrir diálogo
    const handleClickOpen = promotion => () => {
        setCurrentPromotion(promotion);
        setDialogOpen(true);
    };

    // agregar producto a carrito
    const addToCart = item => () => {
        setDisabled(true);
        let qty = 1;
        const { prod_id, prod_var_id, quantity, medicine, refrigerated } = item;
        if (promo === 'CompraX%Descuento') // cantidad faltante
            qty = Number(pkg_qty) - quantity;
        else if (promo === '4x3') {
            if (quantity === 1)
                qty = 3;
            else if (quantity === 2)
                qty = 2;
        }
        const model = {
            prod_id, prod_var_id, quantity: qty,
            refrigerated, medicine
        };
        dispatch(saveItemToCart(model, '', setDisabled));
        // Tag Manager
        window.dataLayer.push({
            event: 'add-to-cart'
        });
    };

    // agregar cantidad faltante a carrito
    // producto ya está en carrito
    const addManyToCart = () => {
        setDisabled(true);
        if (['2x1', '4x3', 'CompraX%Descuento', 'DescuentoPieza'].includes(promo)) { // un sólo producto necesario para activar promoción
            let qty = 1;
            const { prod_id, prod_var_id, quantity, medicine, refrigerated } = products[0];
            if (promo === 'CompraX%Descuento') // cantidad faltante
                qty = Number(pkg_qty) - Number(quantity);
            else if (promo === '4x3') {
                if (quantity === 1)
                    qty = 3;
                else if (quantity === 2)
                    qty = 2;
            }
            const model = {
                prod_id, prod_var_id, quantity: qty,
                refrigerated, medicine
            };
            dispatch(saveItemToCart(model, '', setDisabled));
            // Tag Manager
            window.dataLayer.push({
                event: 'add-to-cart'
            });
        } else {
            // productos faltantes para activar promoción (no están en carrito)
            const items = products.filter(e => !e.in_cart).map(({
                prod_id, prod_var_id, title, url, info,
                category, prod_category, variant_title,
                refrigerated, medicine
            }) => ({
                prod_id, prod_var_id, title, url, info,
                quantity: 1, category, prod_category,
                variant_title, refrigerated, medicine
            }));
            if (items.length > 0) {
                dispatch(addItemsToCart(
                    items, setDisabled, () => {},
                    { flag: false, navigate: () => {} }
                ));
                window.dataLayer.push({
                    event: 'add-to-cart'
                });
            } else {
                setDisabled(false);
                basicMsg('Ya ha completado la promoción');
            }
        }
    };

    return (
        <>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                {
                    cart_promotion.filter(u => u.active).length > 0 &&
                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                            <Box sx={{ p: 2.5 }} className="cart-box-shadow">
                                <Typography variant="subtitle1" gutterBottom>
                                    Promociones activas
                                </Typography>
                                {
                                    cart_promotion.filter(u => u.active).map((e, i) =>
                                        <Box key={i} mt={2}>
                                            <Stack direction="row" spacing={2}
                                                sx={{ mb: e.promo === 'Descuento%Producto' ? 2 : 0 }}>
                                                <CheckCircleIcon sx={{ color: '#229954' }} />
                                                <Typography variant="body1" gutterBottom>
                                                    { e.info }.
                                                </Typography>
                                            </Stack>
                                            <Button variant="outlined" size="small"
                                                onClick={ handleClickOpen(e) }>
                                                Ver productos
                                            </Button>
                                            {
                                                e.promo === 'Descuento%Producto' &&
                                                    <CartPromotionCountdown endDate={ new Date(e.end_date) } />
                                            }
                                            
                                            {
                                                (i !== cart_promotion.filter(u => u.active).length - 1) &&
                                                    <Divider sx={{ my: 2 }} />
                                            }
                                        </Box>
                                    )
                                }
                            </Box>
                        </Grid>
                }

                {
                    cart_promotion.filter(u => !u.active).length > 0 &&
                        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                            <Box sx={{ p: 2.5 }} className="cart-box-shadow">
                                <Typography variant="subtitle1" gutterBottom>
                                    Promociones disponibles
                                </Typography>
                                {
                                    cart_promotion.filter(u => !u.active).map((e, i) =>
                                        <Box key={i} mt={2}>
                                            <Stack direction="row" spacing={2}
                                                sx={{ mb: e.promo === 'Descuento%Producto' ? 2 : 0 }}>
                                                <PendingIcon sx={{ color: '#C0392B' }} />
                                                <Typography variant="body1" gutterBottom>
                                                    { e.info }.
                                                </Typography>
                                            </Stack>
                                            <Button variant="outlined" size="small"
                                                onClick={ handleClickOpen(e) }>
                                                Activar
                                            </Button>
                                            {
                                                e.promo === 'Descuento%Producto' &&
                                                    <CartPromotionCountdown endDate={ new Date(e.end_date) } />
                                            }

                                            {
                                                (i !== cart_promotion.filter(u => !u.active).length - 1) &&
                                                    <Divider sx={{ my: 2 }} />
                                            }
                                        </Box>
                                    )
                                }
                            </Box>
                        </Grid>
                }
            </Grid>
            <Dialog
                open={ dialogOpen }
                onClose={ handleClose }
                aria-labelledby="promotion-dialog-title"
                aria-describedby="promotion-dialog-description"
                sx={{ zIndex: 1000 }}
            >
                <DialogTitle id="promotion-dialog-title">
                    { dialog_title }
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                        { `Promoción: ${info}.` }
                    </Typography>
                    
                    <Box sx={{ mt: 2 }}>
                        {
                            products.filter(u => !u.in_cart).length > 0 &&
                                <Box>
                                    <Typography variant="subtitle2" display="block" gutterBottom sx={{ fontStyle: 'italic' }}>
                                        Agrega los siguientes productos para completar la promoción:
                                    </Typography>
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', p: 0 }}>
                                        {
                                            products.filter(u => !u.in_cart).map(e => {
                                                const {
                                                    prod_var_id, title, variant_title, url, barcode, price, images
                                                } = e;
                                                return (
                                                    <ListItem key={ prod_var_id }
                                                        alignItems="flex-start"
                                                        secondaryAction={
                                                            <IconButton aria-label="add-to-cart"
                                                                size="large" color="primary"
                                                                onClick={ addToCart(e) }
                                                                disabled={ disabled }>
                                                                <AddShoppingCartIcon />
                                                            </IconButton>
                                                        }>
                                                        <ListItemAvatar sx={{ pr: 1, cursor: 'pointer' }}
                                                            onClick={ goToProductPage(url, barcode) }>
                                                            <Avatar alt={
                                                                    images.length > 0 ?
                                                                        images[0].alt
                                                                    :
                                                                        'producto-nefropolis'
                                                                } src={
                                                                    `${GOOGLE_DRIVE_CDN}${
                                                                        images.length > 0 ? images[0].id : PRODUCT_NO_IMG
                                                                    }`
                                                                }
                                                            sx={{ width: 56, height: 56 }} variant="square" />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Link
                                                                    component="button"
                                                                    variant="body2"
                                                                    sx={{ color: '#000', textDecoration: 'none', textAlign: 'left' }}
                                                                    onClick={ goToProductPage(url, barcode) }>
                                                                    { getTitle(title, variant_title) }
                                                                </Link>
                                                            }
                                                            secondary={ `$${price}` }
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Box>
                        }
                        
                        <Typography variant="subtitle2" display="block"
                            gutterBottom sx={{ fontStyle: 'italic' }}>
                            { dialog_text }
                        </Typography>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', p: 0 }}>
                            {
                                products.filter(u => u.in_cart).map(e => {
                                    const {
                                        prod_var_id, title, variant_title, url, barcode, price, images
                                    } = e;
                                    return (
                                        <ListItem key={ prod_var_id }
                                            alignItems="flex-start"
                                            secondaryAction={
                                                (!completed && ['2x1', '4x3', 'CompraX%Descuento'].includes(promo)) &&
                                                    <IconButton aria-label="add-to-cart"
                                                        size="large" color="primary"
                                                        onClick={ addToCart(e) }
                                                        disabled={ disabled }>
                                                        <AddShoppingCartIcon />
                                                    </IconButton>
                                            }>
                                            <ListItemAvatar sx={{ pr: 1, cursor: 'pointer' }}
                                                onClick={ goToProductPage(url, barcode) }>
                                                <Avatar alt={
                                                        images.length > 0 ?
                                                            images[0].alt
                                                        :
                                                            'producto-nefropolis'
                                                    } src={
                                                        `${GOOGLE_DRIVE_CDN}${
                                                            images.length > 0 ? images[0].id : PRODUCT_NO_IMG
                                                        }`
                                                    }
                                                sx={{ width: 56, height: 56 }} variant="square" />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        sx={{ color: '#000', textDecoration: 'none', textAlign: 'left' }}
                                                        onClick={ goToProductPage(url, barcode) }>
                                                        { getTitle(title, variant_title) }
                                                    </Link>
                                                }
                                                secondary={ `$${price}` }
                                            />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions>
                    {
                        !completed && (
                            disabled ?
                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined">
                                    Guardando
                                </LoadingButton>
                            :
                                <Button onClick={ addManyToCart }
                                    disabled={ disabled }>
                                    Agregar faltantes
                                </Button>
                        )
                    }
                    <Button onClick={ handleClose } autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CartPromotionDialog;