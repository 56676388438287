import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';

// import './shopcategory.css';
import BrandSidebar from './BrandSidebar';
import BrandProducts from './BrandProducts';
import ShopFilter from '../Category/ShopFilter';

import {
    Container, Breadcrumbs, Grid, Typography, useMediaQuery,
} from '@mui/material';

import { ALL_CATEGORIES } from '../../../constants/shop/shop_constants';

const ShopBrand = () => {
    const navigate = useNavigate();
    const { brand_url } = useParams();
    
    const myRef = useRef(null);
    const matches = useMediaQuery('(max-width: 950px)');
    const cat_ref_mat = useMediaQuery('(max-width: 991px)');

    // marcas y productos de tienda
    const { brands, products } = useSelector(state => state.shop);

    // filtro de productos según categoría/subcategoría
    const [filteredProducts, setFilteredProducts] = useState([]);

    // marca seleccionada
    const [selectedBrand, setSelectedBrand] = useState(ALL_CATEGORIES);
    
    // la ruta (marca) ha cambiado
    useEffect(() => {
        if (brands.length > 0) {
            const found = brands.find(e => e.url === brand_url);
            if (found)
                setSelectedBrand(found.brand);
            else {
                setSelectedBrand('TODAS');
                navigate('/tienda/marca/todas');
            }
        }
    }, [brand_url, brands]);

    // productos actualizados / marca de producto ha cambiado
    // (filtrar productos)
    useEffect(() => {
        if (selectedBrand === ALL_CATEGORIES)
            setFilteredProducts(products);
        else {
            const filtered = products.filter(e => e.brand === selectedBrand);
            if (filtered)
                setFilteredProducts(filtered);
        }
    }, [products, selectedBrand]);

    // selección de marca
    const onBrandSelected = (brand, url) => () => {
        setSelectedBrand(brand);
        navigate(`/tienda/marca/${url}`);
    };

    return (
        <>
            {/* Productos */}
            <Container maxWidth='xxl' sx={{ mt: matches ? 5 : 18 }}
                className="last-view" ref={ cat_ref_mat ? null : myRef }>
                <Typography variant='h5' sx={{ fontWeight: 'light' }} gutterBottom>
                    { selectedBrand }
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit"
                        to="/tienda">Tienda
                    </Link>
                    <Typography color="text.primary">Marcas de productos</Typography>
                </Breadcrumbs>

                {
                    cat_ref_mat &&
                        <div ref={ myRef }></div>
                }

                {/* Filtros ordenar por... */}
                <ShopFilter
                    filteredProducts={ filteredProducts }
                    setFilteredProducts={ setFilteredProducts }
                />
                {/* fin filtros ordenar por... */}

                <Grid container
                    spacing={3.5}
                    justifyContent="center">
                    {
                        brands.length > 0 &&
                            <BrandSidebar
                                onBrandSelected={ onBrandSelected }
                            />
                    }

                    {/* Productos */}
                    <BrandProducts
                        myRef={ myRef }
                        brand={ selectedBrand }
                        filteredProducts={ filteredProducts }
                    />
                </Grid>
            </Container>
            {/* Fin Productos */}
        </>
    );
};

export default ShopBrand;