// control de productos y reseñas, carrito, cupones y resumen de compra
import { types } from '../types/types';

import { CART_COUPON } from '../constants/shop/coupon/coupon_const';
import { PRODUCT_ACTIVE_REVIEW } from '../constants/shop/product_const';
import { CART_SUMMARY, SHIPPING_METHOD } from '../constants/shop/shop_constants';

// const navigation = {
//     nav_url: '', // url para redirección
//     nav_id: '' // _id de variante a mostrar en redirección
// };

// información de tienda
const initialState = {
    action: '', // tipo de mensaje a mostrar al agregar producto a carrito
    products: [], // productos de tienda
    rvProducts: [], // productos vistos recientemente
    featuredProducts: [], // productos destacados
    promotions: [], // promociones
    categories: [], // categorías de productos
    brands: [], // marcas de productos
    banners: { // banners
        desktop: [],
        mobile: [],
        tablet: []
    },
    shipping: {
        methods: { // métodos de envío ONL
            full_list: [], // todos
            client_list: [], // se excluye envío gratis si no cumple condiciones
            selected: SHIPPING_METHOD, // elegido
            radio_button: null
        },
        action: '', // actualizar/eliminar (admin)
    },
    cart: { // carrito de cliente
        refrig_shipment: false, // envío necesita refrigeración
        item: [], // productos en carrito
        summary: CART_SUMMARY, // resumen de carrito
        item_summary: [], // Tu orden (productos, precio individual y total)
        cart_promotion: [], // promociones activas (en base a productos de carrito)
        coupon: CART_COUPON // cupón aplicado
    },
    coupons: [], // cupones disponibles
    activeReview: PRODUCT_ACTIVE_REVIEW
};

export const shopReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.shippingMethodsLoaded:
            return {
                ...state,
                shipping: {
                    ...state.shipping,
                    methods: {
                        ...state.shipping.methods,
                        full_list: action.payload,
                        client_list: action.payload.filter(e => e.cost !== 0)
                    }
                }
            }
        case types.shippingMethodSelected: {
            return {
                ...state,
                shipping: {
                    ...state.shipping,
                    methods: {
                        ...state.shipping.methods,
                        ...action.payload
                    }
                },
                cart: {
                    ...state.cart,
                    summary: {
                        ...state.cart.summary,
                        total: // sumar costo de envío a total de carrito
                            state.cart.summary.subtotal + action.payload.selected.cost
                    }
                }
            }
        }
        case types.shippingMethodSaved:
            return {
                ...state,
                shipping: {
                    ...state.shipping,
                    methods: {
                        ...state.shipping.methods,
                        full_list: [
                            ...state.shipping.methods.full_list,
                            action.payload
                        ]
                    }
                }
            }
        case types.shippingMethodsDeleted:
            return {
                ...state,
                shipping: {
                    ...state.shipping,
                    methods: {
                        ...state.shipping.methods,
                        full_list: state.shipping.methods.full_list.filter(e => !action.payload.includes(e._id))
                    }
                }
            }
        case types.shopProductsLoaded:
            return {
                ...state,
                ...action.payload
            }
        case types.shopInfoLoaded:
            const { categories, brands, banners, shipping } = action.payload;
            return {
                ...state,
                categories, brands, banners,
                shipping: {
                    ...state.shipping,
                    methods: {
                        ...state.shipping.methods,
                        full_list: shipping,
                        client_list: shipping.filter(e => e.cost !== 0)
                    }
                }
            }
        case types.shopCouponsLoaded:
            return {
                ...state,
                coupons: action.payload
            }
        case types.shoppingCart:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    ...action.payload,
                    summary: {
                        ...state.cart.summary,
                        ...action.payload.summary,
                        total: action.payload.summary.subtotal + state.shipping.methods.selected.cost
                    },
                }
            }
        case types.shopClearAction:
            return {
                ...state,
                action: action.payload
            }
        case types.shopUpdateQuantity:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    item: state.cart.item.map(e => (e.prod_var_id === action.payload.prod_var_id) ?
                    { ...e, quantity: action.payload.quantity }
                    : e)
                }
            }
        case types.shopEmptyCart:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    refrig_shipment: false,
                    item: [],
                    summary: CART_SUMMARY,
                    item_summary: [],
                    cart_promotion: [],
                    coupon: CART_COUPON 
                }
            }
        case types.shopCoupon:
            return {
                ...state,
                coupon: action.payload
            }
        case types.shopProductViewed: {
            return {
                ...state,
                rvProducts: [
                    action.payload,
                    ...state.rvProducts.filter(e => e.prod_id !== action.payload.prod_id).slice(0, Math.min(10, state.rvProducts.length))
                ]
            }
        }
        case types.reviewAdded:
            return {
                ...state,
                products: state.products.map(
                    e => (e._id === action.payload.prod_id) ?
                    { ...e, reviews: [...e.reviews, action.payload] }
                    : e
                ),
                activeReview: PRODUCT_ACTIVE_REVIEW
            }
        case types.reviewSelected:
            return {
                ...state,
                activeReview: action.payload,
            }
        case types.reviewUpdated:
            return {
                ...state,
                products: state.products.map(
                    e => (e._id === action.payload.prod_id) ?
                    { ...e, reviews: e.reviews.map(
                            r => (r._id === action.payload._id) ? action.payload : r
                        )
                    }
                    : e
                ),
                activeReview: PRODUCT_ACTIVE_REVIEW
            }
        case types.reviewDeleted:
            return {
                ...state,
                products: state.products.map(
                    e => (e._id === action.payload.prod_id) ?
                    { ...e, reviews: e.reviews.filter(
                            r => (r._id !== action.payload._id)
                        )
                    }
                    : e
                )
            }
        default:
            return state;
    }
};