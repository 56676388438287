// Productos comprados frecuentemente (vista de producto en tienda)
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { basicMsg } from '../../../actions/swal_msg';
import { addItemsToCart } from '../../../actions/shop';
import { getTitle } from '../../../helpers/admin/productHelper';

import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../constants/constants';

import {
    Typography, Box, FormGroup,
    FormControlLabel, Checkbox
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

const IMG_INIT = {
    img: `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`,
    alt: 'producto-nefropolis'
};

const ProductsFBT = ({ fbtItems, setFBTItems, product, variant, setVariant }) => {
    const dispatch = useDispatch();

    const {
        _id: prod_id, title, url, info, category,
        prod_category, refrigerated, medicine
    } = product;

    const {
        _id: prod_var_id, variant_title, price,
        images, checked, item_promotion
    } = variant;
    // carga de botón
    const [loading, setLoading] = useState(false);
    // uno o más productos agregados a producto
    const [submitted, setSubmitted] = useState(false);

    const [mainImg, setMainImg] = useState(IMG_INIT);
    const [text, setText] = useState('Agregar todo al carrito');
    
    useEffect(() => {
        if (submitted)
            setText('Agregar de nuevo al carrito');
    }, [submitted]);

    useEffect(() => {
        if (images.length > 0)
            setMainImg({
                id: `${GOOGLE_DRIVE_CDN}${images[0].id}`,
                alt: images[0].alt ? images[0].alt : 'producto-nefropolis'
            });
        else setMainImg(IMG_INIT);
    }, [prod_id]);

    // checkbox producto principal
    const uncheckMainProduct = () => {
        setVariant({ ...variant, checked: !checked });
    };

    // checkbox arreglo de productos
    const handleCheckbox = _prod_var_id => () => {
        setFBTItems(e =>
            e.map(u => u.prod_var_id === _prod_var_id ?
                { ...u, checked: !u.checked } : u)
        );
    };

    // agregar productos seleccionados a carrito
    const addToCart = () => {
        let items = fbtItems.filter(e => e.checked).map(({
            prod_id, prod_var_id, title, url, info,
            category, prod_category, refrigerated, medicine,
            variant_title
        }) => ({
            prod_id, prod_var_id, title, url, info,
            quantity: 1, category, prod_category,
            variant_title, refrigerated, medicine
        }));
        if (checked) { // agregar producto en visualización
            const model = {
                prod_id, prod_var_id, title, url, info,
                quantity: 1, category, prod_category,
                variant_title, refrigerated, medicine
            };
            items = [...items, model];
        }
        if (items.length > 0) {
            setLoading(true);
            dispatch(addItemsToCart(
                items, setLoading, setSubmitted,
                { flag: false, navigate: () => {} }
            ));
        } else basicMsg('No ha seleccionado ningún producto');
    };

    return (
        <div className="product-recomended" style={{ marginTop: '60px' }}>
            <Typography variant="h5" sx={{ fontWeight: 'light' }}>
                Frecuentemente se compran juntos
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '10px', my: 3 }}>
                <Box>
                    <img className="image-buy-tog" width='230' height="230"
                        style={{ objectFit: 'contain' }} alt={ mainImg.alt }
                        src={ mainImg.id }
                    />
                </Box>

                {
                    fbtItems.length > 0 &&
                        <Box sx={{ my: 'auto' }}>
                            <i className="fas fa-plus icon-bf" style={{ fontSize: '1.5rem' }}></i>
                        </Box>
                }

                {
                    fbtItems.map(({ prod_var_id, images }, i) => {
                        const img = images.length > 0 ? {
                            id: `${GOOGLE_DRIVE_CDN}${images[0].id}`,
                            alt: images[0].alt ? images[0].alt : 'producto-nefropolis'
                        } : {
                            id: `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`,
                            alt: 'producto-nefropolis'
                        };
                        return (
                            <React.Fragment key={ prod_var_id }>
                                <Box>
                                    <img className="image-buy-tog" width='230' height="230"
                                        style={{ objectFit: 'contain' }} alt={ img.alt }
                                        src={ img.id }
                                    />
                                </Box>
                                {
                                    i < fbtItems.length - 1 &&
                                        <Box sx={{ my: 'auto' }}>
                                            <i className="fas fa-plus icon-bf" style={{ fontSize: '1.5rem' }}></i>
                                        </Box>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </Box>

            <FormGroup>
                {/* producto en cuestión */}
                <FormControlLabel control={
                    <Checkbox checked={ checked } onChange={ uncheckMainProduct } />
                } label={
                    ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo) ?
                        `Este producto: ${ getTitle(title, variant_title) } - $${item_promotion.price}`
                        :
                        `Este producto: ${ getTitle(title, variant_title) } - $${price}`
                } />

                {/* comprados juntos frecuentemente */}
                {
                    fbtItems.map(({ prod_var_id, title, variant_title, price, checked }) =>
                        <FormControlLabel key={prod_var_id} control={
                            <Checkbox checked={ checked }
                                onChange={ handleCheckbox(prod_var_id) } />
                        } label={`${ getTitle(title, variant_title) } - $${price}`} />
                    )
                }
            </FormGroup>

            <LoadingButton
                endIcon={<AddShoppingCartIcon />}
                loading={ loading }
                loadingPosition="end"
                variant="contained"
                onClick={ addToCart }>
                { text }
            </LoadingButton>
        </div>
    );
};

export default ProductsFBT;