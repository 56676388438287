import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CustomToolbar from './CustomToolbar';
import DragDropRow from './Content/DragDropRow';
import BlockDialog from './Content/BlockDialog';
import BlogCategory from './Category/BlogCategory';
import QuillEditDialog from './Content/QuillEditDialog';
import BasicDateTimePicker from './Content/BlogDatePicker';

import { uiLoading2 } from '../../../actions/ui';
import { basicMsg } from '../../../actions/swal_msg';
import { saveBlogDB, uploadBlogContentImages } from '../../../actions/admin/blog';

import { blogFormValidator } from '../../../validators/Blog/BlogSaveValidator';

import {
    blogInit, blogMainImg, blogRowInit,
    blogVideo, default_blog_cover, formats, modules
} from '../../../constants/admin/blog_constants';
import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

//Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//MUI
import {
    Box, Container, TextField, Typography,
    Button, Stack, Fab, Grid, Backdrop,
    CircularProgress, IconButton
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { generateURL } from '../../../helpers/admin/productHelper';

const AddBlog = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        // blog,
        categories
    } = useSelector(state => state.blog);
    const { loading2 } = useSelector(state => state.ui);

    // texto de Quill
    const [text, setText] = useState('');
    // contenido en filas/columnas
    const [row, setRow] = useState(blogRowInit);
    // mostrar diálogo de edición de bloque
    const [open, setOpen] = useState(false);
    // mostrar diálogo de longitud de bloque
    const [open1, setOpen1] = useState(false);

    // contador de filas y columnas (para asignar id's)
    const [rowCounter, setRowCounter] = useState(2);
    const [colCounter, setColCounter] = useState(0);
    // longitud de columnas según el dispositivo
    const [xs, setXS] = useState(12);
    const [md, setMD] = useState(6);

    const [mainImg, setMainImg] = useState(blogMainImg);
    const { imgSrc, imgFile } = mainImg;

    // redireccionar al guardar entrada de blog
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        if (flag)
            navigate('/nefro-admin/blog');
    }, [flag]);

    // información de blog
    const [values, setValues] = useState(blogInit);
    const { title, author } = values;

    // cambios en Quill Editor
    const handleChange = (html) => {
        if (html === '<p><br></p>')
            setText('');
        else
            setText(html);
    };

    // cambios en campos
    const handleOnChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'title') {
            if (value.length <= 200)
                setValues({ ...values, [name]: value });
        } else if (name === 'author') {
            if (value.length <= 100)
                setValues({ ...values, [name]: value });
        }
    };
    
    // guardar bloque
    const addBlock = () => {
        if (text !== '') {
            setOpen1(true);
        } else basicMsg('Ingrese contenido en el recuadro');
    };

    // seleccionar longitud de bloque y guardar
    const insertBlock = () => {
        let content = [...row];
        content[0].col.push({
            item: text,
            src: '',
            file: '',
            type: 'txt',
            xs,
            md,
            id: `col-${colCounter}`
        });
        setRow(content);
        setColCounter(colCounter + 1);
        setOpen1(false);
        // limpiar campo de Quill
        setText('');
    };

    // limpiar contenido de Quill
    const cleanBlock = () => {
        if (text !== '')
            Swal.fire({
                title: 'Limpiar el contenido del bloque',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed)
                    setText('');
            });
    };

    // agregar fila de contenido
    const addRow = () => {
        setRow([...row, { col: [], id: `row-${rowCounter}` }]);
        setRowCounter(rowCounter + 1);
    };

    // subir imagen de categoría
    const uploadFiles = async({ target }) => {
        const promise = await loadFiles(target.files);
        if (promise) {
            const { columns, counter } = promise;
            setRow(e => e.map((u, i) =>
                i === 0 ? ({ ...u, col: [...u.col, ...columns] }) : u
            ));
            setColCounter(counter);
        }
    };

    // cargar archivos mediante Promesa
    const loadFiles = async(files) => {
        let counter = colCounter;
        const filePromise = [...files].map((file, i) => {
            return new Promise((resolve, reject) => {
                if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async (event) => {
                        const src = event.target.result;
                        const item = `<img class="img-fluid" src="${src}" alt="imagen-blog-Nefropolis">`;
                        resolve({
                            item,
                            src,
                            file,
                            type: 'img',
                            xs: 12,
                            md: 6,
                            id: `col-${counter}`
                        });
                        counter++;
                    };
                } else if (file.type === 'video/mp4') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (event) => {
                        const src = event.target.result;
                        const item = `<div><h6>Video: ${file.name}</h6><img style="height: 200px; display:block; margin: auto;" src=${blogVideo} alt="video-blog-Nefropolis"></div>`;
                        resolve({
                            item,
                            src,
                            file,
                            type: 'video',
                            xs: 12,
                            md: 6,
                            id: `col-${counter}`
                        });
                        counter++;
                    };
                }
            });
        });
        return {
            columns: await Promise.all(filePromise),
            counter
        }
    };

    // subir portada de Blog
    const uploadMainImg = ({ target }) => {
        const file = target.files[0]
        if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (event) => {
                const src = event.target.result;
                setMainImg({ ...mainImg,
                    imgSrc: src,
                    imgFile: file
                });
            };
        }
    };
    
    // guardar blog en BD
    const saveBlog = () => {
        const SelectedCategories = categories.filter(e => e.checked).map(u => u.category);
        if (blogFormValidator({ ...values, categories: SelectedCategories, imgFile, row })) {
            Swal.fire({
                title: '¿Guardar el blog?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Cancelar',
            }).then(async(result) => {
                if (result.isConfirmed) {
                    dispatch(uiLoading2(true));

                    let filesCount = 1; // +1 por imagen de portada

                    const data = new FormData();
                    data.append('title', title);
                    data.append('file', imgFile);
                    // quitamos la fila de 'bloques añadidos' y filas sin contenido
                    const rowContent = row.filter((e, i) => i !== 0 && e.col.length > 0);
                    for (const e of rowContent) {
                        for (const col of e.col) {
                            if (col.type === 'img' || col.type === 'video') {
                                data.append('file', col.file);
                                filesCount++;
                            }
                        };
                    };
                    // subida de imágenes a Drive
                    const req = await uploadBlogContentImages(data, 'upload');
                    if (req.status) {
                        const { folderId, imagesId, html } = req.resp;

                        const url = generateURL(title);
                        
                        let i = 0; // contador de filas
                        let content = []; // contenido dinámico hacia BD

                        if (filesCount === imagesId.length) {
                            let j = 0; // contador de archivos
                            // arreglo de id's de archivos
                            const images = imagesId.length > 1 ? imagesId.filter((e, i) => i !== 0) : [];
                            for (const e of rowContent) {
                                content = [...content, {
                                    col: []
                                }];
                                for (const col of e.col) {
                                    const { item, type, xs, md } = col;
                                    if (col.type === 'img') {
                                        content[i].col = [...content[i].col, {
                                            item: `<img class="img-fluid" src="${GOOGLE_DRIVE_CDN}${images[j]}" alt="imagen-blog-Nefropolis">`,
                                            type, xs, md,
                                            fileId: images[j]
                                        }];
                                        j++;
                                    } else if (col.type === 'video') {
                                        content[i].col = [...content[i].col, {
                                            item: `<iframe src="https://drive.google.com/file/d/${images[j]}/preview" title="video-blog-Nefropolis" width="100%" height="100%"></iframe>`,
                                            type, xs, md,
                                            fileId: images[j]
                                        }];
                                        j++;
                                    } else {
                                        content[i].col = [...content[i].col, {
                                            item, type, xs, md,
                                            fileId: ''
                                        }];
                                    }
                                }
                                i++;
                            }
                            dispatch(saveBlogDB({
                                ...values,
                                url,
                                category: SelectedCategories,
                                content,
                                folderId,
                                imgId: imagesId[0],
                                imgUploaded: true
                            }, html, setFlag));
                        } else {
                            for (const e of rowContent) {
                                content = [...content, {
                                    col: []
                                }];
                                for (const col of e.col) {
                                    const { item, type, xs, md } = col;
                                    if (col.type !== 'img' && col.type !== 'video')
                                        content[i].col = [...content[i].col, {
                                            item, type, xs, md,
                                            fileId: ''
                                        }];
                                };
                                i++;
                            }
                            dispatch(saveBlogDB({
                                ...values,
                                url,
                                category: SelectedCategories,
                                content,
                                folderId,
                                imgId: default_blog_cover,
                                imgUploaded: false
                            }, html, setFlag));
                        }
                    } else
                        dispatch(uiLoading2(false));
                }
            });
        }
    };
    
    return (
        <Container maxWidth="xl" sx={{ mb: 5, mt: '90px' }}>
            <Stack direction="row"
                alignItems="flex-start">
                <IconButton aria-label="go-back"
                    onClick={ () => navigate('/nefro-admin/blog') }>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h5' paragraph>
                    Agregar nuevo blog
                </Typography>
            </Stack>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <TextField fullWidth
                        variant='outlined'
                        label="Título del blog"
                        size='small'
                        name='title'
                        inputProps={{ maxLength: 200 }}
                        value={ title }
                        onChange={ handleOnChange }
                    />
                    <TextField fullWidth
                        variant='outlined'
                        label="Autor del blog"
                        size='small'
                        name='author'
                        inputProps={{ maxLength: 100 }}
                        value={ author }
                        onChange={ handleOnChange }
                        sx={{ my: 2 }}
                    />
                    <BasicDateTimePicker
                        fullWidth
                        values={ values }
                        setValues={ setValues }
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <Stack direction="column">
                        <Button variant="outlined" startIcon={<InsertPhotoIcon />}
                            component="label">
                            <input hidden accept="image/*"
                                type="file" onChange={ uploadMainImg }
                            />
                            Imagen de portada
                        </Button>
                        <img src={ imgSrc } alt='portada-blog'
                            className='img-fluid'/>
                    </Stack>
                </Grid>
            </Grid>
            
            <Box sx={{ mt: 3 }}>
                <CustomToolbar />
                <ReactQuill
                    value={text}
                    name='quill'
                    onChange={handleChange}
                    modules={modules}
                    formats={formats}
                />
            </Box>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button variant="contained"
                    onClick={ addBlock }>
                    Guardar
                </Button>
                <Button onClick={ cleanBlock }>
                    Limpiar
                </Button>
                <Button variant="outlined" startIcon={<InsertPhotoIcon />} component="label">
                    <input hidden accept="image/*, video/*"
                        type="file" onChange={ uploadFiles }
                        multiple="multiple" />
                    Agregar imagen/video
                </Button>
            </Stack>

            <Box sx={{ mt: 3 }}>
                <Typography variant='body1'>Preview en HTML</Typography>
                <textarea defaultValue={text} rows={5} style={{ width: '100%' }} disabled></textarea>
            </Box>

            <BlogCategory />
            
            <DragDropRow setOpen={ setOpen }
                row={ row }
                setRow={ setRow }
            />

            <Button variant='contained'
                onClick={ addRow }>
                Agregar fila
            </Button>

            <QuillEditDialog open={ open }
                setOpen={ setOpen }
                row={ row } setRow={ setRow }
            />
            <BlockDialog open={ open1 }
                setOpen={ setOpen1 }
                xs={ xs } setXS={ setXS }
                md={ md } setMD={ setMD }
                insertBlock={ insertBlock }
            />

            <Fab color="primary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}
                disabled={ loading2 }
                onClick={ saveBlog }>
                <SaveIcon />
            </Fab>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ loading2 }>
                <CircularProgress />
            </Backdrop>
        </Container>
    );
};

export default AddBlog;