import React, { useState } from 'react'

//MUI
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

//constants
import { regex_num } from '../../../constants/regex'

//swal
// import { basicMsg } from '../../../actions/swal_msg'

const WhatsAppButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    height: 40,
    color: '#fff',
    backgroundColor: '#25d366',
    '&:hover': {
        backgroundColor: '#52BE80',
        color: '#fff'
    },
}));

const CustomButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: '600',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

const init = {
    postalCode: '',
}

const initErr = {
    postalCodeErr: ''
}

const validPostalCodes = [
    '12345',
    '12346',
    '12347',
    '80029',
    '45403'
]

const PostalCodeModal = ({ cpModal, setCpModal, stock, qty, handleChangeQty, handleSubmit }) => {

    const [data, setData] = useState(init)
    const { postalCode } = data

    const [inputErr, setInputErr] = useState(initErr);
    const { postalCodeErr } = inputErr;

    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'postalCode') {
            if (value === '' || regex_num.test(value)) {
                setData(e => ({
                    ...e,
                    [name]: value
                })
                );
            }
        }
        else setData(e => ({
            ...e,
            [name]: value
        }))
        switch (name) {
            case 'postalCode':
                setInputErr({
                    ...inputErr,
                    postalCodeErr: (value.length != 5) ? true : false
                });
                break;
            default:
                break;
        };
    }

    const formValidator = () => {
        const postalCode_len = postalCode.trim().length
        if (postalCode_len === 5) {
            return true
        }
        else {
            // basicMsg('Ingrese el código postal')
            setInputErr({
                ...inputErr,
                postalCodeErr: true
            });
        }
    }

    const [msg, setMsg] = useState()

    const submit = () => {
        if (formValidator()) {
            const result = validPostalCodes.some(e => e == postalCode)
            localStorage.setItem('postalCode', JSON.stringify(postalCode))
            if (result === true) {
                setMsg(true)
            }
            else {
                setMsg(false)
            }
        }
        else {
            console.log('algo anda mal')
        }
    }

    const handleClose = () => {
        setCpModal(false)
        setData(init)
        setMsg()
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth='sm'
            open={cpModal}
            onClose={handleClose}
            sx={{ zIndex: 3 }}
        >
            <DialogTitle>Comprobar disponibilidad</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Ingresa tu código postal para validar el envío de este producto hasta tu domicilio
                </DialogContentText>
                <Box sx={{ mt: 3 }}>
                    <FormControl fullWidth sx={{ mb: 1 }}>
                        <TextField label='Código postal' name='postalCode' value={postalCode} onChange={handleInputChange} error={postalCodeErr}
                            helperText={postalCodeErr ? "Ingrese un código postal correcto" : ""} inputProps={{ maxLength: 5 }} />
                    </FormControl>
                    <Box display='flex' justifyContent='flex-start' gap='15px'>
                        <CustomButton sx={{ my: 'auto' }} onClick={submit} variant='contained'>Comprobar</CustomButton>
                        <Box sx={{ my: 'auto' }}>
                            <a href='https://micodigopostal.org/' target='_blank' rel="noreferrer" style={{ textDecoration: 'none', fontSize: '14px' }}>No sé mi código</a>
                        </Box>
                    </Box>
                </Box>
                {
                    msg === true
                        ?
                        <Box sx={{ mt: 5 }}>
                            <Typography variant='body1' sx={{ color: 'green' }}>
                                Este producto si puede ser enviado a la dirección proporcionada, ya que su código postal está dentro de nuestra área de entrega.
                            </Typography>
                            <FormControl fullWidth sx={{ mt: 3 }}>
                                <TextField
                                    label="Cantidad"
                                    variant="outlined"
                                    value={qty}
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                    onChange={handleChangeQty}
                                />
                            </FormControl>
                            <Typography variant="body2" display="block" sx={{ mt: .5, mb: 1.5 }}>
                                ({stock} disponibles)
                            </Typography>
                            <Button variant='contained' onClick={handleSubmit} sx={{ textTransform: 'none' }}>Agregar al carrito</Button>
                        </Box>
                        :
                        msg === false
                            ?
                            <Box sx={{ mt: 5 }}>
                                <Typography variant='body1' sx={{ color: 'red' }} paragraph>
                                    Gracias por tu interés en nuestros productos, Lamentamos informarte que el código postal ingresado no cuenta con servicio
                                    de entrega para este producto. Le sugerimos intentar con un código postal diferente o contactar a nuestro equipo de atención
                                    al cliente para más información.
                                </Typography>

                                <WhatsAppButton
                                    variant='contained'
                                    startIcon={<WhatsAppIcon />}
                                    LinkComponent='a'
                                    href={
                                        `https://api.whatsapp.com/send?phone=523324966190&text=Hola,+quisiera+más+información+acerca+de`
                                    }
                                    target="_blank" rel="noopener noreferrer"
                                >
                                    Contactar a un asesor
                                </WhatsAppButton>
                            </Box>
                            :
                            null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>cancelar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PostalCodeModal