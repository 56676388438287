// promoción descuento en producto A
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { savePromoOne } from '../../../../actions/admin/promotion';
import { basicMsg, confirmDialog } from '../../../../actions/swal_msg';
import { getProductPromotions } from '../../../../helpers/admin/promotion/promotionHelper';

import no_image from '../../../../assets/images/banners/coleccion-todos1.jpg';

import { GOOGLE_DRIVE_CDN, prodPromo } from '../../../../constants/constants';

import {
    Box, Button, Typography, Grid, TextField,
    Stack, Input, InputAdornment, CardActionArea,
    Card, CardContent, CardMedia
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getTitle } from '../../../../helpers/admin/productHelper';

const num_regex = /^[0-9]*$/;
const num_dot_regex = /^(?!,$)[\d,.]+$/;

const PromoThree = ({
    promotion,
    activePromotion, setActivePromotion,
    otherPromotion, setOtherPromotion,
    today
}) => {
    const matches = useMediaQuery('(max-width:600px)');
    const { promo, start_date, end_date } = promotion;
    // lista de productos
    const { products } = useSelector(state => state.product);
    const [allProducts, setAllProducts] = useState([]);

    const [data, setData] = useState({
        label: '%',
        maxLength: 3,
        helperText: '% de descuento',
        type: 'Descuento % en producto'
    });
    const { label, maxLength, helperText, type } = data;
    
    // búsqueda de producto
    const [discount, setDiscount] = useState('');
    // búsqueda de producto
    const [search, setSearch] = useState('');
    // producto A seleccionado
    const [productA, setProductA] = useState(prodPromo);
    const { variant_id, title, variant_title, price, image_id } = productA;
    
    useEffect(() => {
        // setType(promo === 'Descuento$Producto' ?
        //     'Descuento neto' : 'Descuento porcentual');
        if (promo === 'Descuento$Producto')
            setData(e => ({
                ...e, label: 'Descuento ($)',
                maxLength: 10,
                helperText: `$${discount} de descuento`,
                type: `$${discount} de descuento en ${
                    variant_id ? getTitle(title, variant_title)
                    : 'producto'
                }`
            }));
        else if (promo === 'Descuento%Producto')
            setData(e => ({
                ...e, label: 'Descuento (%)',
                maxLength: 3,
                helperText: `${discount}% de descuento`,
                type: `${discount}% de descuento en ${
                    variant_id ? getTitle(title, variant_title)
                    : 'producto'
                }`
            }));
        else
            setData(e => ({
                ...e, label: 'X',
                maxLength: 1,
                helperText: `Descuento incorrecto`,
                type: 'No se reconoce tipo de descuento en producto'
            }));
    }, [promotion, discount, productA]);

    // useEffect(() => {
    //     if (promo === 'Descuento$Producto')
    //         setType();
    //     else if ()
    //         setType();
    //     else
    //         setType(`Descuento incorrecto`);
    // }, [promotion, discount, productA]);

    useEffect(() => {
        setProducts();
    }, [products]);

    // producto buscando
    const handleInputChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'discount') {
            if (promo === 'Descuento$Producto') {
                if (value === '' || num_dot_regex.test(value))
                    setDiscount(value);
            } else {
                if (value === '' || num_regex.test(value))
                    setDiscount(value);
            }
        } else {
            setSearch(value);
            if (value !== '') filterProducts(value);
            else setProducts();
        }
    };

    // mostrar todos los productos
    const setProducts = () => {
        if (products.length > 0) {
            let Products = [];
            for (const e of products) {
                const { prod_variants, ..._product } = e;
                for (const variant of prod_variants) {
                    const { _id, ..._variant } = variant;
                    Products = [...Products, {
                        ..._product, ..._variant,
                        prod_var_id: _id,
                        checked: true
                    }];
                }
            }
            setAllProducts(Products);
        }
    };

    // filtrar productos por búsqueda
    const filterProducts = (value) => {
        // primero filter y luego set
        if (products.length > 0) {
            let Products = [];
            for (const e of products) {
                const { prod_variants, ..._product } = e;
                for (const variant of prod_variants) {
                    const { _id, ..._variant } = variant;
                    Products = [...Products, {
                        ..._product, ..._variant,
                        prod_var_id: _id,
                        checked: true
                    }];
                }
            }
            const _search = value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            setAllProducts(Products.filter((e) =>
                e.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
                ||
                e.variant_title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(_search)
                ||
                e.category.find(u => u.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(search))
            ));
        }
    };

    // seleccionar producto A
    const selectProduct = _prod_var_id => () => {
        const item = allProducts.find(e => e.prod_var_id === _prod_var_id);
        if (item) {
            setProductA({
                variant_id: item.prod_var_id,
                title: item.title,
                variant_title: item.variant_title,
                price: item.price,
                image_id: item.images.length > 0 ?
                    `${GOOGLE_DRIVE_CDN}${item.images[0].id}`
                    : no_image
            });
        }
    };

    // productos A seleccionado, se introdujo descuento
    const validation = () => {
        // cuenta el número de puntos en descuento
        const count = (discount.match(/\./g) || []).length;
        // valor de descuento a numérico
        const num = Number(discount);
        if (productA.variant_id === '')
            basicMsg('Seleccione producto');
        else if (discount === '')
            basicMsg(promo === 'Descuento$Producto' ?
                'Seleccione la cantidad de descuento' :
                'Seleccione el porcentaje de descuento'
            );
        else if (count > 1)
            basicMsg('La cantidad de descuento es incorrecta');
        else if (promo === 'Descuento%Producto' && (num < 1 || num > 100))
            basicMsg('Porcentaje de descuento es incorrecto');
        else if (promo === 'Descuento$Producto' && num > price)
            basicMsg('Descuento supera precio de producto');
        else return true;
        return false;
    };

    // verificar si existen promociones con el producto
    const checkPromos = async() => {
        if (validation()) {
            const ActivePromotions = getProductPromotions(promo, activePromotion, otherPromotion, { productA });
            if (ActivePromotions.status) {
                if (await confirmDialog('¿Registrar la promoción?', ActivePromotions.html, 'Registrar'))
                    savePromo();
            } else savePromo();
        }
    };

    // guardar promoción del producto
    const savePromo = async () => {
        const req = await savePromoOne({
            ...promotion,
            // discount_type: ''
            discount,
            variant_a: {
                _id: variant_id,
                title: title,
                variant_title: variant_title.trim()
            }
        }, 'save-promo-three');
        if (req.status) {
            // promoción activa
            if (start_date <= today && end_date >= today)
                setActivePromotion(e => ([
                    ...e,
                    {
                        ...promotion,
                        discount,
                        variant_a: {
                            _id: variant_id,
                            title: title,
                            variant_title: variant_title.trim()
                        },
                        _id: req.resp._id,
                    }
                ]));
            else // promoción guardada (no activa)
                setOtherPromotion(e => ([
                    ...e,
                    {
                        ...promotion,
                        discount,
                        variant_a: {
                            _id: variant_id,
                            title: title,
                            variant_title: variant_title.trim()
                        },
                        _id: req.resp._id,
                    }
                ]));
            // limpiar campos
            setProductA(prodPromo);
            setDiscount('');
        }
    };

    return (
        <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12}>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: 2 }}>
                    <Typography variant="h6">
                        { `Promoción: ${type}` }
                    </Typography>
                    <StarPurple500Icon />
                </Stack>

                <Grid container spacing={4}>
                    <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        {
                            variant_id !== '' &&
                            <>
                                <Card sx={{ width: '100%', mb: 2 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            sx={{ height: 200, backgroundSize: 'contain' }}
                                            image={ image_id }
                                            alt="product-A"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div">
                                                { `${title} ${variant_title}`.trim() }
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Producto seleccionado
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                                <Button variant="contained"
                                    onClick={ checkPromos }>Guardar promoción
                                </Button>
                            </>
                        }
                    </Grid>
                </Grid>

                <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    name="discount"
                    label={ label }
                    variant="standard"
                    inputProps={{ maxLength }}
                    helperText={ helperText }
                    value={ discount }
                    onChange={ handleInputChange } />

                <Box sx={{ my: 3 }}>
                    <Input sx={ matches ? { width: '100%' } : { width: '50%' } }
                        placeholder="Buscar producto (título/categoría)"
                        value={ search }
                        onChange={ handleInputChange }
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </Box>

                <Grid container spacing={4}>
                    {
                        allProducts.map(item =>
                            <Grid item xl={4} lg={6} md={6} sm={6} xs={12} key={ item.prod_var_id }>
                                <Box sx={{
                                    display: 'flex', justifyContent: 'flex-start', gap: '15px',
                                    borderRadius: '5px', padding: '10px', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)'
                                }}>
                                    <Box sx={{ my: 'auto' }}>
                                        <img width='120' height="120" style={{ objectFit: 'contain' }}
                                            src={
                                                item.images.length > 0 ?
                                                    `${GOOGLE_DRIVE_CDN}${item.images[0].id}`
                                                    :
                                                    no_image
                                            }
                                            alt={
                                                item.images.length > 0 ?
                                                    item.images[0].alt
                                                :
                                                    'producto-nefropolis'
                                            } />
                                    </Box>
                                    <Box sx={{ my: 'auto' }}>
                                        <Typography component="div" variant="h6">
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body1" color="text.secondary" component="div" gutterBottom>
                                            { item.variant_title }
                                        </Typography>
                                        <Button size="small" variant='outlined'
                                            onClick={ selectProduct(item.prod_var_id) }>Seleccionar</Button>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PromoThree;