export const SELECTED_FORM = {
    _id: '',
    fullname: '',
    email: '',
    tel: '',
    email_content: {
        subject: '',
        msg: '',
    },
    images: [],
    form_date: new Date()
};