import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useLocation } from "react-router-dom";

import { loadAdminData } from '../actions/admin';
import { clearRdrPage, initLoading } from '../actions/user';
import {
  getCartItems, loadShop, shopProducts
} from '../actions/shop';
import { getCFDIData, getTaxSystemData } from '../actions/checkout';

import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import SignUp from '../components/User/SignUp/SignUp';
import LoginComponent from '../components/User/Login/LoginComponent';
// import SpeedDialComponent from '../components/SpeedDial/SpeedDialComponent';
import FabFAQS from '../components/SpeedDial/FabFAQS/FabFAQS';
import LoginCheckOutComponent from '../components/Tienda/Checkout/Login-chekout/LoginCheckoutComponent';

import { ShopRoute } from './ShopRoute';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { ProtectedRoute } from './ProtectedRoute';

import { UserRoutes } from './UserRoute';
import { AdminRoutes } from './AdminRoutes';

import { Container, CircularProgress } from '@mui/material';

const AppRoute = () => {
  // const location = useLocation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  
  //Scroll to form footer
  const FormRef = useRef(null);

  const handleRef = () => {
    FormRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  // credenciales de usuario
  const { loading } = useSelector(state => state.user);
  
  useEffect(() => {
    // tienda
    dispatch(loadShop());
    // carga de productos
    dispatch(shopProducts('shop'));

    dispatch(getTaxSystemData());
    dispatch(getCFDIData());
  }, []);

  useEffect(() => {
    // sesión de usuario se actualiza
    dispatch(initLoading(loading));
  }, [dispatch]);

  useEffect(() => {
    if (!loading) { // se comprobó sesión de usuario
      // productos de carrito
      dispatch(getCartItems(true));

      // carga en caso de ser admin
      // blog módulo (admin)
      dispatch(loadAdminData());
    }
  }, [loading]);

  useEffect(() => {
    // navegar correctamente al estar logueado
    dispatch(clearRdrPage());
  }, [pathname]);

  if (loading) {
    return (
      <>
        <Container maxWidth='xl' sx={{ textAlign: 'center', mt: 5 }}>
          <CircularProgress />
        </Container>
      </>
    );
  }
  
  return (
    <>
      {
        !pathname.includes('nefro-admin') &&
        <>
          <Header handleRef={handleRef} />
          <FabFAQS />
        </>
      }
      <Routes>
        <Route path="/login" element={
          <PublicRoute>
            <LoginComponent />
          </PublicRoute>
        } />
        <Route path="/signup" element={
          <PublicRoute>
            <SignUp />
          </PublicRoute>
        } />
        <Route path="/login-l" element={
          <PublicRoute>
            <LoginCheckOutComponent />
          </PublicRoute>
        } />
        <Route path="/*" element={
          <ShopRoute />
        } />
        <Route path="/mi-cuenta/*" element={
          <PrivateRoute>
            <UserRoutes />
          </PrivateRoute>
        } />
        <Route path="/nefro-admin/*" element={
          <ProtectedRoute>
            <AdminRoutes />
          </ProtectedRoute>
        } />
      </Routes>
      {
        !pathname.includes('nefro-admin') &&
        <Footer FormRef={FormRef} />
      }
    </>
  );
};

export default AppRoute;