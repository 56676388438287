import { protectedReq } from "../../helpers/requestHelper";
import { msg, htmlMsg, basicMsg } from "../swal_msg";

// obtener todas las promociones
export const getActivePromotions = async() => {
    try {
        const resp = await protectedReq('promotion/active-promos');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'server-err')
                msg('error', 'Promociones activas',
                'Ocurrió un incidente. Intente de nuevo, por favor');
        }
        return body;
    } catch (err) {
        htmlMsg('error', 'Promociones activas',
        `<p>No se pudo cargar las promociones activas</p><h6>Error: ${err}</h6>`);
        return { status: false };
    }
};

// guardar promoción
export const savePromoOne = async(model, endpoint) => {
    try {
        const resp = await protectedReq(`promotion/${endpoint}`, model, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'miss')
                basicMsg('Faltan datos en la promoción');
            else if (body.msg === 'promo-exists') {
                if (['2x1','4x3','Descuento$Producto','Descuento%Producto', 'DescuentoPieza'].includes(model.promo))
                    msg('info', 'Promoción',
                    'La promoción con el producto ya existe');
                else
                    msg('info', 'Promoción',
                    'La promoción con uno de los productos ya existe');
            } else if (body.msg === 'identical-promo')
                basicMsg('Ya existe una promoción idéntica');
            else if (body.msg === 'server-err')
                msg('error', 'Promoción',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else if (body.msg === 'not-saved')
                basicMsg('Promoción no ha sido guardada');
            else
                basicMsg('Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        htmlMsg('error', 'Promoción',
        `<p>No se pudo guardar la promoción</p><h6>Error: ${err}</h6>`);
        return { status: false };
    };
};

// eliminar promoción
export const deletePromo = async(model) => {
    try {
        const resp = await protectedReq('promotion/delete', model, 'DELETE');
        const body = await resp.json();
        if (body.status)
            msg('success', 'Promoción',
            'Promoción eliminada');
        else {
            if (body.msg === 'server-err')
                msg('error', 'Promoción',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else
                msg('warning', 'Promoción',
                'Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        htmlMsg('error', 'Promoción',
        `<p>No se pudo eliminar la promoción</p><h6>Error: ${err}</h6>`);
        return { status: false };
    }
};

// actualizar promoción
export const updatePromo = async(promotion) => {
    try {
        const { url, model } = getPromoModel(promotion);
        const resp = await protectedReq(url, model, 'PUT');
        const body = await resp.json();
        if (body.status) {
            msg('success', 'Promoción',
            'Promoción actualizada');
            return { ...body, model };
        } else {
            if (body.msg === 'server-err')
                msg('error', 'Promoción',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else
                msg('warning', 'Promoción',
                'Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        htmlMsg('error', 'Promoción',
        `<p>No se pudo actualizar la promoción</p><h6>Error: ${err}</h6>`);
        return { status: false };
    }
};

export const getPromoModel = (promotion) => {
    const { _id, promo, pkg_qty, discount, startDate, endDate } = promotion;
    if (['2x1', '4x3', 'ABC'].includes(promo))
        return { url: 'promotion/update-promo-one', model: { _id, start_date: startDate, end_date: endDate } };
    else if (['Descuento%Producto', 'Compra%Descuento', 'CompraAB%Descuento'].includes(promo))
        return { url: 'promotion/update-promo-two', model: { _id, discount, start_date: startDate, end_date: endDate } };
    else if (promo === 'CompraX%Descuento')
        return { url: 'promotion/update-promo-three', model: { _id, pkg_qty, discount, start_date: startDate, end_date: endDate } };
    else
        return { url: 'promotion/update-promo-one', model: { _id } };
};