import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '../FeaturedProducts/FeaturedProducts.css';

//IMG
import shape from '../../../../assets/resources/shape.png';

//MUI
import { Box, Typography, Stack, useMediaQuery, Button } from '@mui/material';
import { styled } from "@mui/material/styles";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";

import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../../constants/constants';
import { getTitle } from '../../../../helpers/admin/productHelper';

const CustomButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#56C596',
    paddingLeft: '20px',
    paddingRight: '20px',
    fontWeight: '600',
    '&:hover': {
        backgroundColor: '#358f69',
        color: '#fff'
    }
}));

const PromotionProducts = () => {
    const matches = useMediaQuery('(max-width:610px)');
    // productos en tienda
    const { products } = useSelector(state => state.shop);
    
    // productos en promoción
    const [promotionProducts, setPromotionProducts] = useState([]);

    useEffect(() => {
        // solo productos y variantes con promoción activa
        setPromotionProducts(
            products.map(e => {
                const prod_variants = e.prod_variants.filter(
                    u => u.item_promotion && u.item_promotion.promo !== ''
                );
                return { ...e, prod_variants };
            }).filter(u => u.prod_variants.length > 0)
        );
    }, [products]);

    return (
        <>
            {
                promotionProducts.length >= 1 &&
                    <>
                        <Box mb={3} textAlign={matches ? 'center' : 'left'}>
                            <Typography
                                sx={{ fontWeight: 'bold', fontSize: '26px' }}
                                gutterBottom
                            >
                                Productos en promoción
                            </Typography>
                            <CustomButton variant='contained' sx={{ textTransform: 'none' }}
                                LinkComponent={Link} to='/promociones'>
                                Ver todo
                            </CustomButton>
                        </Box>
                        <Swiper
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                610: {
                                    slidesPerView: 2,
                                },
                                800: {
                                    slidesPerView: 2,
                                },
                                950: {
                                    slidesPerView: 3,
                                },
                                1100: {
                                    slidesPerView: 3,
                                },
                                1200: {
                                    slidesPerView: 5,
                                },
                            }}
                            spaceBetween={25}
                            autoHeight={true}
                            navigation={{
                                nextEl: '.promotion-swiper-button-next',
                                prevEl: '.promotion-swiper-button-prev',
                            }}
                            loop={true}
                            pagination={{
                                clickable: true,
                                dynamicBullets: true,
                            }}
                            modules={[Navigation, Pagination, Autoplay]}
                            className="promotion_swiper">
                            {
                                promotionProducts.map(({ _id, title, url, prod_variants, reviews }) => {
                                    let review_prom = 0; // promedio reseñas
                                    let review_count = 0 // número reseñas

                                    // precios de variantes
                                    const prices = prod_variants.map(e => e.price);
                                    // precio más económico de variante
                                    const min_price = Math.min(...prices);

                                    // variante con precio más bajo
                                    const CheaperVariant = prod_variants.find(e => e.price === min_price);

                                    // variante a mostrar
                                    const MainVariant = CheaperVariant ?
                                        CheaperVariant : prod_variants[0];

                                    if (reviews.length > 0) {
                                        reviews.forEach(element => {
                                            review_prom += element.rating;
                                            review_count++;
                                        });
                                        // redondea promedio de reseñas
                                        review_prom = Math.round(review_prom / review_count);
                                    }

                                    // variante a mostrar
                                    const { variant_title, barcode, item_promotion, images } = MainVariant;
                                    const img = images.length > 0 ?
                                        {
                                            id: `${GOOGLE_DRIVE_CDN}${images[0].id}`,
                                            alt: images[0].alt ? images[0].alt : 'producto-nefropolis'
                                        }
                                    :
                                        {
                                            id: `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`,
                                            alt: 'producto-nefropolis'
                                        };
                                    const full_title = getTitle(title, variant_title);

                                    return (
                                        <SwiperSlide style={{ textAlign: 'center', marginTop: '5px' }} key={_id}>
                                            <Box className='box_promotion' sx={{ p: 2 }}>
                                                <Link to={`/tienda/producto/${url}/${barcode}`}>
                                                    <div className="product-in-promotion">
                                                        <img src={ img.id }
                                                            width='100%' style={{ objectFit: 'contain' }}
                                                            className="shop-product" alt={ img.alt }
                                                        />
                                                        {
                                                            item_promotion.promo !== '' &&
                                                                <div className="shop_top-image">
                                                                    <img src={shape} alt={shape} width='75'
                                                                        style={{ pointerEvents: 'none' }}
                                                                    />
                                                                    <div className="shop_centered_image">
                                                                        {
                                                                            ['2x1', '4x3', 'Descuento%Producto'].includes(item_promotion.promo) ?
                                                                                <Typography sx={{
                                                                                    color: '#fff', fontSize: '16px', fontWeight: 'bold'
                                                                                }}>
                                                                                    { item_promotion.text }
                                                                                </Typography>
                                                                            :
                                                                                <Typography sx={{
                                                                                    color: '#fff', fontSize: '12px', fontWeight: 'bold'
                                                                                }}>
                                                                                    Promoción
                                                                                </Typography>
                                                                        }
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </Link>

                                                <Box sx={{ textAlign: 'center', mt: 1 }}>
                                                    <Link to={`/tienda/producto/${url}/${barcode}`}
                                                        style={{ textDecoration: 'none', color: '#000' }}>
                                                        {
                                                            !matches ?
                                                                <Typography
                                                                    variant="body1"
                                                                    component="div"
                                                                    sx={{ fontWeight: '600' }}
                                                                >
                                                                    {
                                                                        full_title.length > 28 ?
                                                                            `${full_title.substring(0, 28)}...`
                                                                        :
                                                                            `${full_title}`
                                                                    }
                                                                </Typography>
                                                            :
                                                                <Typography
                                                                    variant="body1"
                                                                    component="div"
                                                                    sx={{ fontWeight: '600' }}
                                                                >
                                                                    { full_title }
                                                                </Typography>
                                                        }
                                                    </Link>
                                                </Box>

                                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: .5 }}>
                                                    <div className="ratings-shop">
                                                        {
                                                            review_count > 0 ?
                                                                Array.from(Array(review_prom), (e, j) => {
                                                                    return <i className="fas fa-star"
                                                                        style={{
                                                                            color: '#FFA41C',
                                                                            stroke: "#FFA41C",
                                                                            strokeWidth: 0
                                                                        }}
                                                                        key={j}></i>
                                                                })
                                                                :
                                                                <>
                                                                    <i className="far fa-star"></i>
                                                                    <i className="far fa-star"></i>
                                                                    <i className="far fa-star"></i>
                                                                    <i className="far fa-star"></i>
                                                                    <i className="far fa-star"></i>
                                                                </>
                                                        }
                                                    </div>
                                                </Box>

                                                <Box sx={{
                                                    display: 'flex', mt: 1,
                                                    justifyContent: { xs: 'center', sm: 'center', md: 'center' },
                                                }}>
                                                    {
                                                        ['Descuento$Producto', 'Descuento%Producto'].includes(item_promotion.promo) ?
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="baseline"
                                                                spacing={1}>
                                                                <Typography variant="h6"
                                                                    sx={{ fontWeight: '600', color: '#000', textDecoration: 'line-through' }}>
                                                                    ${min_price}
                                                                </Typography>
                                                                <Typography variant="h6"
                                                                    sx={{ fontWeight: '600', color: '#F90606' }} align='center'>
                                                                    ${item_promotion.price}
                                                                </Typography>
                                                            </Stack>
                                                            :
                                                            <Typography variant="h6"
                                                                sx={{ fontWeight: '600', color: '#F90606' }} align='center'>
                                                                ${min_price}
                                                            </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </>
            }
        </>
    );
};

export default PromotionProducts;