import React from 'react'

//MUI
import { Fab, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

//Router dom
import { useNavigate } from 'react-router-dom';


const CustomFab = styled(Fab)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#56C596',
  '&:hover': {
    backgroundColor: '#358f69',
    color: '#fff'
  },
}));

const FabFAQS = () => {

  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate('/preguntas-frecuentes');
  }

  return (
    <Tooltip title="Preguntas frecuentes" placement='right'>
      <CustomFab color="primary"
        onClick={handleNavigate}
        sx={{
          position: "fixed",
          bottom: 90,
          left: 25
        }}
      >
        <QuestionMarkIcon />
      </CustomFab>
    </Tooltip>
  )
}

export default FabFAQS