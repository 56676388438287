import React from 'react';

import es from "date-fns/locale/es";
import { format } from 'date-fns';

import { deletePromo } from '../../../actions/admin/promotion';
import { simpleConfirmDialog } from '../../../actions/swal_msg';

import {
    Typography, Card, CardContent, CardHeader, IconButton, Menu, MenuItem
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getTitle } from '../../../helpers/admin/productHelper';

const ActivePromotions = ({
    promotion, setOtherPromotion, setActivePromotion,
    setDialogStatus, setCurrentPromotion
}) => {
    
    const {
        _id, promo, pkg_qty, discount, start_date, end_date,
        variant_a, variant_b, variant_c
    } = promotion;

    // eliminar promoción
    const deletePromotion = async () => {
        setAnchorEl(null);
        if (await simpleConfirmDialog('¿Deseas eliminar la promoción?', 'Eliminar')) {
            const req = await deletePromo({ _id });
            if (req.status) {
                setOtherPromotion(e => e.filter(e => e._id !== _id));
                setActivePromotion(e => e.filter(e => e._id !== _id));
            }
        }
    };

    // actualizar promoción (mostrar promoción en diálogo)
    const updatePromotion = async () => {
        setCurrentPromotion(e => ({ ...e, ...promotion }));
        setAnchorEl(null);
        setDialogStatus(true);
    };

    //Menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return (
        <>
            <Card className='promotion_active_card'>
                <CardHeader
                    action={
                        <IconButton onClick={ handleClick }>
                            <MoreVertIcon />
                        </IconButton>
                    }
                    title={promo}
                    subheader={
                        `Vigencia: ${format(new Date(start_date), "d 'de' MMMM yyyy", { locale: es })} - ${format(new Date(end_date), "d 'de' MMMM yyyy", { locale: es })}`
                    }
                />
                <CardContent>
                    <Typography variant="body1">
                        {
                            promo === '2x1' &&
                            `2 productos ${variant_a.title} ${variant_a.variant_title} por el precio de 1`
                        }
                        {
                            promo === '4x3' &&
                            `4 productos ${variant_a.title} ${variant_a.variant_title} por el precio de 3`
                        }
                        {
                            promo === 'ABC' &&
                            `Compra ${variant_a.title} ${variant_a.variant_title} y ${variant_b.title} ${variant_b.variant_title} y llévate ${variant_c.title} ${variant_c.variant_title} gratis`
                        }
                        {
                            promo === 'Descuento$Producto' &&
                            `Descuento de $${discount} en ${variant_a.title} ${variant_a.variant_title}`.trim()
                        }
                        {
                            promo === 'Descuento%Producto' &&
                            `Descuento de ${discount}% en ${variant_a.title} ${variant_a.variant_title}`.trim()
                        }
                        {
                            promo === 'Compra$Descuento' &&
                            `Descuento de $${discount} en ${variant_b.title} ${variant_b.variant_title} al comprar ${variant_a.title} ${variant_a.variant_title}`.trim()
                        }
                        {
                            promo === 'Compra%Descuento' &&
                            `Descuento de ${discount}% en ${variant_b.title} ${variant_b.variant_title} al comprar ${variant_a.title} ${variant_a.variant_title}`.trim()
                        }
                        {
                            promo === 'CompraAB%Descuento' &&
                            `Descuento de ${discount}% en ${variant_a.title} ${variant_a.variant_title} y ${variant_b.title} ${variant_b.variant_title} al comprar ambos productos`
                        }
                        {
                            promo === 'CompraX%Descuento' &&
                            `Descuento de ${discount}% en ${variant_a.title} ${variant_a.variant_title} al comprar ${pkg_qty} piezas (${pkg_qty} pack)`
                        }
                        {
                            promo === 'DescuentoPieza' &&
                            `Descuento de ${discount}% en el segundo producto del mismo tamaño (${getTitle(variant_a.title, variant_a.variant_title)})`
                        }
                    </Typography>
                </CardContent>
            </Card>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={ deletePromotion }>Eliminar</MenuItem>
                <MenuItem onClick={ updatePromotion }>Actualizar</MenuItem>
            </Menu>
        </>
    );
};

export default ActivePromotions;