import { types } from '../types/types';

const initialState = {
    categoryToggle: 'list',
    variantAccordion: false,
    adminUserDialog: false,
    adminUserModal: {
        status: false,
        action: 'add'
    },
    formsModal: {
        open: false,
        action: 'add'
    },
    commentModal: {
        open: false,
        action: 'add',
        loading: false
    },
    loginModal: false,
    shopSnackbar: {
        status: false, // mostrar/ocultar snackbar
        origin_page: '', // tipo de error en petición
        err_msg: '', // mensaje de error para snackbar
    },
    loading1: false,
    loading2: false,
    user: {
        code_sent: false
    },
    user_account: {
        userOrdersTab: '1'
    },
    loading: { // ¿carga inicial de datos finalizada?
        admin_modules: true,
        shop_products: true
    }
};

export const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ui_categoryToggle:
            return {
                ...state,
                categoryToggle: action.payload
            }
        case types.ui_variantAccordion:
            return {
                ...state,
                variantAccordion: action.payload
            }
        case types.ui_adminUserModal:
            return {
                ...state,
                adminUserModal: action.payload
            }
        case types.ui_adminUserDialog:
            return {
                ...state,
                adminUserDialog: action.payload
            }
        case types.ui_formsModal:
            return {
                ...state,
                formsModal: action.payload
            }
        case types.ui_commentLoading:
            return {
                ...state,
                commentModal: {
                    ...state.commentModal,
                    ...action.payload
                }
            }
        case types.ui_commentModal:
            return {
                ...state,
                commentModal: action.payload
            }
        case types.ui_loginModal:
            return {
                ...state,
                loginModal: action.payload
            }
        case types.ui_shopSnackbar:
            return {
                ...state,
                shopSnackbar: action.payload
            }
        case types.uiLoading1:
            return {
                ...state,
                loading1: action.payload
            }
        case types.uiLoading2:
            return {
                ...state,
                loading2: action.payload
            }
        case types.uiAdminModuleData:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    admin_modules: action.payload
                }
            };
        case types.uiShopProductData:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    shop_products: action.payload
                }
            };
        case types.loginCodeModal:
            return {
                ...state,
                user: {
                    ...state.user,
                    code_sent: action.payload
                }
            };
        case types.userOrdersTab:
            return {
                ...state,
                user_account: {
                    ...state.user_account,
                    userOrdersTab: action.payload
                }
            };
        default:
            return state;
    };
};