import { types } from '../types/types';
// import { blockInEdition, blogInEdition } from '../constants/admin/blog_constants';

const initialState = {
    // blog: [],
    categories: [],
    // blockInEdition,
    // blogInEdition,
    userBlog: [],
};

export const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.blogCategoriesLoaded:
            return {
                ...state,
                categories: [...action.payload]
            };
        case types.blogCategorySaved:
            return {
                ...state,
                categories: [...state.categories, action.payload]
            };
        case types.userBlogLoaded:
            const { posts, categories } = action.payload;
            return {
                ...state,
                userBlog: posts,
                categories
            };
        default:
            return state;
    };
};