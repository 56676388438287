import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import { uiUserOrdersTab } from "../../../actions/ui";
import { orderSelected } from "../../../actions/user-account";

import {
    Box, Button, Card, CardActions, CardContent,
    Container, Divider, Grid, Typography, Alert,
    useMediaQuery
} from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';

import { DATE_OPTIONS } from "../../../constants/date_const";
import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from "../../../constants/constants";

const OrderList = () => {
    const matches = useMediaQuery('(max-width:425.9px)');
    const matches_img = useMediaQuery('(max-width: 530px)');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { orders } = useSelector(state => state.user_account);

    const goToProduct = (url, barcode) => () => {
        navigate(`/tienda/producto/${url}/${barcode}`);
    };

    const orderDetails = order_num => () => {
        const filter = orders.find(e => e.order_num === order_num);
        if (filter) {
            dispatch(orderSelected({ ...filter }));
            navigate('/mi-cuenta/detalles-pedido');
        }
    };

    const orderTracking = order_num => () => {
        const filter = orders.find(e => e.order_num === order_num);
        if (filter)
            navigate(`/rastrear-pedido/${filter.payment.nefro_reference}`, { state: filter })
    };

    return (
        orders.length > 0 ?
            orders.map(({ order_num, products, shipping, order_date }) => {
                const len = products.length;
                const today = new Date(order_date);
                const status = shipping.esp_status;
                return (
                    <Box sx={{ mb: 4 }} key={order_num}>
                        <Card sx={{ width: '100%', boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                            <CardContent onClick={matches ? orderTracking(order_num) : null}>
                                <Box sx={{
                                    flexWrap: 'wrap', justifyContent: 'space-between',
                                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' }
                                }}>
                                    <Box sx={{ mb: { xs: 0, sm: 0, md: 1.5, lg: 0, xl: 0 } }}>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary">
                                            No. Pedido: <b>ONL-{order_num}</b>
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <Typography sx={{ fontSize: 14 }}>
                                            {`Estatus del pedido: `}
                                            <Link to="/rastrear-pedido"
                                                style={
                                                    ['Enviado', 'Entregado'].includes(status) ?
                                                        { color: '#229954', fontWeight: '700' }
                                                        :
                                                        ['En espera', 'Preparando'].includes(status) ?
                                                            { color: '#F1C40F', fontWeight: '700' }
                                                            :
                                                            { color: '#C0392B', fontWeight: '700' }
                                                }>
                                                {status}
                                            </Link>
                                        </Typography>
                                        <Divider orientation="vertical" sx={{ mx: 1.5 }} />
                                        <Typography sx={{ fontSize: 14 }}>
                                            Fecha de compra: <b>{
                                                `${today.toLocaleDateString("es-MX", DATE_OPTIONS)}`
                                            }</b>
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{ my: 1.8, display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }} />
                                <Grid container spacing={matches ? 1.5 : 2} sx={{ px: { xs: 0, sm: 0, md: 0, lg: 2 } }}>
                                    {
                                        len > 1 ?
                                            <>
                                                {
                                                    products.map((res, index) => {
                                                        const { url, barcode } = res;
                                                        return (
                                                        <React.Fragment key={index}>
                                                            <Grid item xl={3} lg={3} md={4} sm={4} xs={4}
                                                                className="animate__animated animate__fadeInLeft">
                                                                <img width={
                                                                        matches_img ? '100%' : '90%'
                                                                    } alt={
                                                                        res.images.length > 0 ?  res.images[0].alt : 'producto-nefropolis'
                                                                    } src={
                                                                        `${GOOGLE_DRIVE_CDN}${
                                                                            res.images.length > 0 ? res.images[0].id : PRODUCT_NO_IMG
                                                                        }`
                                                                    } onClick={
                                                                        goToProduct(url, barcode)
                                                                    } style={{ cursor: 'pointer' }}
                                                                />
                                                            </Grid>
                                                            <Grid item xl={9} lg={9} md={8} sm={8} xs={8}
                                                                sx={{ my: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }}>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Box>
                                                                        <Typography variant={matches ? 'body1' : 'h6'} sx={{ fontWeight: '600' }} gutterBottom>
                                                                            {`${res.title} ${res.variant_title}`.trim()}
                                                                        </Typography>
                                                                        <Typography variant='body2' sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                                                                            Fecha de compra: <b>{
                                                                                `${today.toLocaleDateString("es-MX", DATE_OPTIONS)}`
                                                                            }</b>
                                                                        </Typography>
                                                                        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                                                                            <Typography variant='body2' color='text.secondary'
                                                                                sx={{ textTransform: 'uppercase' }} gutterBottom>
                                                                                Categorías: {
                                                                                    res.prod_category && res.prod_category.map(({ category }, i) => {
                                                                                        return i === res.prod_category.length - 1 ? `${category}` : `${category}, `
                                                                                    })
                                                                                }
                                                                            </Typography>
                                                                            <Button
                                                                                variant='contained'
                                                                                onClick={() => {
                                                                                    dispatch(uiUserOrdersTab('2'))
                                                                                    window.scrollTo({ top: 0, left: 0 })
                                                                                }}
                                                                                size='small'
                                                                                sx={{ textTransform: 'none' }}
                                                                                endIcon={<LoopIcon />}>
                                                                                Comprar nuevamente
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )})
                                                }
                                            </>
                                        :
                                            <>
                                                <Grid item xl={3} lg={3} md={4} sm={4} xs={4}
                                                    className="animate__animated animate__fadeInLeft">
                                                    <img width={
                                                            matches_img ? '100%' : '90%'
                                                        } alt={
                                                            products[0].images.length > 0 ?  products[0].images[0].alt : 'producto-nefropolis'
                                                        } src={
                                                            `${GOOGLE_DRIVE_CDN}${
                                                                products[0].images.length > 0 ? products[0].images[0].id : PRODUCT_NO_IMG
                                                            }`
                                                        } onClick={
                                                            goToProduct(products[0].url, products[0].barcode)
                                                        } style={{ cursor: 'pointer' }}
                                                    />
                                                </Grid>
                                                <Grid item xl={9} lg={9} md={8} sm={8} xs={8} sx={{ my: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto' } }}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Box>
                                                            <Typography variant={matches ? 'body1' : 'h6'} sx={{ fontWeight: '600' }} gutterBottom>
                                                                {products[0].title}
                                                                {len > 1 && (<span>{` + ${len - 1} producto(s) `}</span>)}
                                                            </Typography>
                                                            <Typography variant='body2' sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                                                                Fecha de compra: <b>{
                                                                    `${today.toLocaleDateString("es-MX", DATE_OPTIONS)}`
                                                                }</b>
                                                            </Typography>
                                                            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                                                                <Typography variant='body2' color='text.secondary'
                                                                    sx={{ textTransform: 'uppercase' }} gutterBottom>
                                                                    Categorías: {
                                                                        products[0].prod_category && products[0].prod_category.map(({ category }, i) => {
                                                                            return i === products[0].prod_category.length - 1 ? `${category}` : `${category}, `
                                                                        })
                                                                    }
                                                                </Typography>
                                                                <Button
                                                                    variant='contained'
                                                                    onClick={() => {
                                                                        dispatch(uiUserOrdersTab('2'))
                                                                        window.scrollTo({ top: 0, left: 0 })
                                                                    }}
                                                                    size='small'
                                                                    sx={{ textTransform: 'none' }}
                                                                    endIcon={<LoopIcon />}>
                                                                    Comprar nuevamente
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                            </CardContent>
                            <Divider />
                            <CardActions>
                                <Button
                                    onClick={ orderTracking(order_num) }
                                    sx={{ textTransform: 'none' }}
                                >
                                    Rastrear pedido
                                </Button>
                                <Button
                                    onClick={ orderDetails(order_num) }
                                    sx={{ textTransform: 'none' }}
                                >
                                    Ver detalles
                                </Button>
                            </CardActions>
                        </Card>
                    </Box>
                )
            })
        :
            <Container maxWidth="xl" sx={{ mt: 3 }}>
                <Alert severity="info">No tiene pedidos por el momento</Alert>
            </Container>
    );
};

export default OrderList;