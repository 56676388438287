import { htmlMsg } from "./swal_msg";
import { axiosProtectedReq, protectedReq } from "../helpers/requestHelper";
import { types } from "../types/types";

// lista de pedidos - administrador
export const getOrders = async(model) => {
    try {
        const resp = await protectedReq('order/list', model, 'POST');
        const body = await resp.json();
        return body;
    } catch (err) {
        return { status: false };
    };
};

export const loadedOrders = (payload) => ({
    type: types.adminOrders, payload
});

// pedidos perdidos - administrador
export const getLostOrders = async() => {
    try {
        const resp = await protectedReq('order/lost-list');
        const body = await resp.json();
        return body;
    } catch (err) {
        return { status: false };
    };
};

// subir archivo
export const uploadOrderFile = async(file, order_num) => {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('order_num', order_num);
        const resp = await axiosProtectedReq('order/file', formData, 'POST');
        if (!resp.status) {
            if (resp.msg === 'file-not-uploaded')
                alert('Archivo no subido');
            else if (resp.msg === 'folder-not-created')
                alert('Carpeta no creada');
            else if (resp.msg === 'miss')
                alert(`Falta información de pedido #${order_num}`);
            else
                alert('Ocurrió un incidente');
        }
        return resp;
    } catch (err) {
        alert(`Pedido ${order_num}: No se pudo subir archivo`);
        return { status: false };
    }
};

// agrega guía de seguimiento
export const addTrackingGuide = async(order) => {
    const { order_num, ...model } = order;
    try {
        const resp = await protectedReq('order/add-tracking', model, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'miss')
                alert(`Pedido ${order_num}: Falta información`);
            else if (body.msg === 'order-not-upd')
                alert(`Pedido ${order_num}: No actualizado`);
            else if (body.msg === 'server-err')
                alert(`Pedido ${order_num}: Error en el servidor`);
            else
                alert(`Pedido ${order_num}: Incidente al agregar guía de seguimiento`);
        }
        return body;
    } catch (err) {
        alert(`Pedido ${order_num}: No se pudo agregar guía de seguimiento`);
        return { status: false };
    }
};

// marcar pedido como entregado
export const orderDelivered = async(order) => {
    const { order_num, ...model } = order;
    try {
        const resp = await protectedReq('order/delivered', model, 'PUT');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'miss')
                alert(`Pedido ${order_num}: Falta información`);
            else if (body.msg === 'order-not-upd')
                alert(`Pedido ${order_num}: No actualizado`);
            else if (body.msg === 'server-err')
                alert(`Pedido ${order_num}: Error en el servidor`);
            else
                alert(`Pedido ${order_num}: Incidente al marcar como entregado (${
                    body.msg ? body.msg : 'Desconocido'
                })`);
        }
        return body;
    } catch (err) {
        alert(`Pedido ${order_num}: No se pudo marcar pedido como entregado`);
        return { status: false };
    }
};

export const odooOrders = async() => {
    try {
        const resp = await protectedReq('order/odoo');
        const body = await resp.json();
        return body;
    } catch (err) {
        htmlMsg('error', 'Pedidos en Odoo',
        `<p>No se pudo recuperar pedidos de BD</p><h6>Error: ${err}</h6>`);
        return { status: false };
    };
};