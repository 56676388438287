import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getTitle } from '../../../helpers/admin/productHelper';

import shape from '../../../assets/resources/shape.png';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";

import { GOOGLE_DRIVE_CDN, PRODUCT_NO_IMG } from '../../../constants/constants';

//MUI
import {
    Box, IconButton, Typography, Link, Grid
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const RecentlyViewed = ({ product_id }) => {
    const navigate = useNavigate();
    const {
        rvProducts // productos vistos recientemente
    } = useSelector(state => state.shop);
    
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setProducts(rvProducts.filter(e => e.prod_id !== product_id));
    }, [rvProducts]);

    // dirigirse a página de variante
    const goToProductPage = (url, barcode) => () => {
        navigate(`/tienda/producto/${url}/${barcode}`);
    };

    return (
        <>
            <Typography variant='h5' color='text.primary'
                sx={{ fontSize: '26px', mb: 5 }}
                textAlign='center'>
                Productos vistos recientemente
            </Typography>

            <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                    <IconButton className='recentlyViewed-swiper-button-prev' size='small'>
                        <ChevronLeftIcon />
                    </IconButton>
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={8} xs={8}>
                    <Swiper
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            610: {
                                slidesPerView: 2,
                            },
                            800: {
                                slidesPerView: 2,
                            },
                            950: {
                                slidesPerView: 3,
                            },
                            1100: {
                                slidesPerView: 3,
                            },
                            1200: {
                                slidesPerView: 4,
                            },
                        }}
                        spaceBetween={40}
                        navigation={{
                            nextEl: '.recentlyViewed-swiper-button-next',
                            prevEl: '.recentlyViewed-swiper-button-prev',
                        }}
                        loop={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        modules={[Navigation, Pagination]}>
                        {
                            products.map(({
                                prod_var_id, title, variant_title, url, price,
                                barcode, images, reviews, item_promotion
                            }) => {
                                let review_prom = 0;
                                let review_count = 0

                                if (reviews.length > 0) {
                                    reviews.forEach(element => {
                                        review_prom += element.rating;
                                        review_count++;
                                    });
                                    review_prom = Math.round(review_prom / review_count);
                                }

                                const img = images.length > 0 ?
                                    {
                                        id: `${GOOGLE_DRIVE_CDN}${images[0].id}`,
                                        alt: images[0].alt ? images[0].alt : 'producto-nefropolis'
                                    }
                                :
                                    {
                                        id: `${GOOGLE_DRIVE_CDN}${PRODUCT_NO_IMG}`,
                                        alt: 'producto-nefropolis'
                                    };
                                
                                return (
                                    <SwiperSlide key={prod_var_id}
                                        style={{ textAlign: 'center' }}>
                                        <Link component="button" variant="body1"
                                            onClick={ goToProductPage(url, barcode) }>
                                            <img src={ img.id }
                                                width='100%'
                                                style={{ objectFit: 'contain' }}
                                                alt={ img.alt }
                                            />
                                        </Link>

                                        {
                                            item_promotion.promo !== '' &&
                                            <div className="shop_top-image">
                                                <img src={shape} alt={shape} width='75'
                                                    style={{ pointerEvents: 'none' }}
                                                />
                                                <div className="shop_centered_image">
                                                    {
                                                        ['2x1', '4x3', 'Descuento%Producto'].includes(item_promotion.promo)
                                                            ?
                                                            <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                                {item_promotion.text}
                                                            </Typography>
                                                            :
                                                            <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>
                                                                Promoción
                                                            </Typography>
                                                    }
                                                </div>
                                            </div>
                                        }

                                        <Typography variant='body1'
                                            gutterBottom
                                            sx={{ fontWeight: '600' }}>
                                            { `${getTitle(title, variant_title)}` }
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                            <div className="ratings-shop">
                                                {
                                                    review_count > 0 ?
                                                        Array.from(Array(review_prom), (e, j) => {
                                                            return <i className="fas fa-star"
                                                                style={{
                                                                    color: '#FFA41C',
                                                                    stroke: "#FFA41C",
                                                                    strokeWidth: 0.2
                                                                }}
                                                                key={j}></i>
                                                        })
                                                        :
                                                        <>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                            <i className="far fa-star"></i>
                                                        </>
                                                }
                                            </div>
                                        </Box>

                                        <Typography variant="h6"
                                            sx={{ fontWeight: '600', color: '#F90606', mt: 1 }}
                                            align='center'>
                                            ${price} MXN
                                        </Typography>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={2} xs={2} sx={{ my: 'auto' }}>
                    <IconButton className='recentlyViewed-swiper-button-next' size='small'>
                        <ChevronRightIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    );
};

export default RecentlyViewed;