import { basicMsg, msg as swalMsg } from "../../swal_msg";
import { protectedReq } from "../../../helpers/requestHelper";

// obtener formularios de contacto
export const getContactForms = async() => {
    try {
        const resp = await protectedReq('form/contact/get');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'server-err')
                swalMsg('error', 'Contacto',
                'Ocurrió un incidente. Intente de nuevo, por favor');
        }
        return body;
    } catch (err) {
        swalMsg('error', 'Contacto',
        'Ocurrió un incidente. Intente de nuevo, por favor');
        return { status: false };
    };
};

// enviar respuesta a contacto
export const replyEmail = async(model) => {
    try {
        const resp = await protectedReq('form/contact/reply', model, 'POST');
        const body = await resp.json();
        if (!body.status) {
            if (body.msg === 'miss')
                basicMsg('Falta información del contacto');
            else if (body.msg === 'email-not-send')
                swalMsg('info', 'Contacto',
                'No se pudo enviar la respuesta');
            else if (body.msg === 'contact-not-found')
                swalMsg('No se encontró el contacto a responder');
            else if (body.msg === 'server-err')
                swalMsg('error', 'Contacto',
                'Ocurrió un incidente. Intente de nuevo, por favor');
            else
                swalMsg('warning', 'Contacto',
                'Inténtelo de nuevo, por favor');
        }
        return body;
    } catch (err) {
        swalMsg('error', 'Contacto',
        'No se pudo enviar el correo electrónico');
        return { status: false };
    };
};