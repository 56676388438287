import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';
import { deleteBlogDB, setBlogInEdition } from '../../../actions/admin/blog';

//MUI
import {
    Card, Grid, CardMedia, CardContent, Typography,
    CardActions, Button
} from '@mui/material';

const BlogItem = ({ item }) => {
    const { _id, title, url, author, folderId, imgId } = item;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { blogInEdition } = useSelector(state => state.blog);
    const { blog_data: { blogInEdition } } = useSelector(state => state.admin);

    // redireccionar
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (submitted) {
            if (_id)
                navigate(`/nefro-admin/actualizar-blog/${url}`);
        }
    }, [blogInEdition]);

    const updateBlog = () => {
        dispatch(setBlogInEdition(item));
        setSubmitted(true);
    };

    // eliminar blog
    const deleteBlog = () => {
        Swal.fire({
            title: '¿Desea eliminar el blog?',
            showDenyButton: true,
            confirmButtonText: 'Eliminar',
            denyButtonText: 'Cancelar',
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteBlogDB({ _id, folderId }));
            }
        });
    };

    return (
        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
            <Card sx={{ width: '100%' }}>
                <CardMedia
                    sx={{ height: 200 }}
                    image={`${GOOGLE_DRIVE_CDN}${imgId}`}
                    title={title}
                />
                <CardContent sx={{ pb: 0 }}>
                    <Typography variant='h6' component="div">
                        {title}
                    </Typography>
                    <Typography variant='body2' color='text.secondary' gutterBottom>
                        Autor: {author}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small"
                        onClick={ updateBlog }>Editar</Button>
                    <Button size="small"
                        onClick={ deleteBlog }>Eliminar</Button>
                </CardActions>
            </Card>
        </Grid>
    );
};

export default BlogItem;