import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CustomToolbar from '../CustomToolbar';
import BlogRowEdition from './BlogRowEdition';
import BlockDialog from '../Content/BlockDialog';
import BlogCategory from '../Category/BlogCategory';
import QuillEditDialog from '../Content/QuillEditDialog';
import BasicDateTimePicker from '../Content/BlogDatePicker';

import { uiLoading2 } from '../../../../actions/ui';
import { basicMsg, msg } from '../../../../actions/swal_msg';
import { getFilesCount } from '../../../../helpers/blog/blogUpdate';
import { blogEditionFormValidator } from '../../../../validators/Blog/BlogSaveValidator';
import {
    blogCategoriesLoaded, getBlogToUpdate,
    setBlogInEdition, updateBlogDB, uploadBlogContentImages
} from '../../../../actions/admin/blog';

import {
    blogUpdMainImg, blogVideo, formats, modules
} from '../../../../constants/admin/blog_constants';
import { GOOGLE_DRIVE_CDN } from '../../../../constants/constants';

import {
    Box, Container, TextField, Typography,
    Button, Stack, Fab, Grid, Backdrop,
    CircularProgress, IconButton
} from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { generateURL } from '../../../../helpers/admin/productHelper';

const BlogUpdate = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categories } = useSelector(state => state.blog);
    const { blog, blog_data: { blogInEdition } } = useSelector(state => state.admin);
    const { _id, category, imgId, imgUploaded, content } = blogInEdition;
    const { loading2 } = useSelector(state => state.ui);

    // url de blog
    const { url } = useParams();

    const [blogModel, setBlogModel] = useState(blogInEdition);
    const { title, author, folderId } = blogModel;

    // imagen de portada
    const [mainImg, setMainImg] = useState(blogUpdMainImg);
    const { imgSrc, imgFile } = mainImg;
    
    // campo Quill Editor
    const [text, setText] = useState('');

    // contenido en filas/columnas
    const [row, setRow] = useState([]);

    // contador de filas y columnas (para asignar id's)
    const [rowCounter, setRowCounter] = useState(0);
    const [colCounter, setColCounter] = useState(0);

    // longitud de columnas según el dispositivo
    const [xs, setXS] = useState(12);
    const [md, setMD] = useState(6);

    // mostrar diálogo de edición de bloque
    const [open, setOpen] = useState(false);
    // mostrar diálogo de longitud de bloque
    const [open1, setOpen1] = useState(false);

    // redireccionar
    const [submitted, setSubmitted] = useState(false);

    // archivos a eliminar
    const [oldFiles, setOldFiles] = useState([]);

    useEffect(() => {
        validateContent();
        if (submitted)
            navigate('/nefro-admin/blog');
    }, [blog]);
    
    // datos de blog actualizables
    useEffect(() => {
        // contenido editable
        setBlogModel(blogInEdition);
        setMainImg({
            imgSrc: `${GOOGLE_DRIVE_CDN}${imgId}`,
            imgFile: '',
            imgUploaded
        });
        buildContent();
        dispatch(blogCategoriesLoaded(
            categories.map(e => category.includes(e.category)
                ? { ...e, checked: true }
                : { ...e, checked: false }
            )
        ));
    }, [blogInEdition]);

    // validar que blog haya cargado para edición
    const validateContent = () => {
        // no hay blog activo
        if (_id === '') {
            if (blog.length > 0) { // blogs cargados
                const BlogModel = blog.find(e => e.url === url);
                if (BlogModel)
                    dispatch(setBlogInEdition(BlogModel));
                else
                    navigate('/nefro-admin/blog');
            } else // no se encontraron blogs
                dispatch(getBlogToUpdate(url));
        }
    };

    // colocar filas y columnas de contenido
    const buildContent = () => {
        let rowNumber = 0;
        let colNumber = -1;
        const _content = [
            {
                col: [],
                id: 'row-0'
            },
            ...content.map(e => {
                rowNumber++;
                return {
                    ...e,
                    id: `row-${rowNumber}`,
                    col: e.col.map(u => {
                        colNumber++;
                        return {
                            ...u,
                            src: '', file: '',
                            id: `col-${colNumber}`,
                            uploaded: ['img', 'video'].includes(u.type) ?
                                true : false
                        };
                    })
                };
            })
        ];
        // contadores para próximas filas y columnas
        setRowCounter(rowNumber + 1);
        setColCounter(colNumber + 1);
        setRow(_content);
    };

    // cambios en campos
    const handleOnChange = ({ target }) => {
        const name = target.name;
        const value = target.value;
        if (name === 'title') {
            if (value.length <= 200)
                setBlogModel({ ...blogModel, [name]: value });
        } else if (name === 'author') {
            if (value.length <= 100)
                setBlogModel({ ...blogModel, [name]: value });
        }
    };

    // subir portada de Blog
    const uploadMainImg = ({ target }) => {
        const file = target.files[0]
        if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async (event) => {
                const src = event.target.result;
                setMainImg({ ...mainImg,
                    imgSrc: src,
                    imgFile: file
                });
                // se remueve imagen principal
                if (imgUploaded)
                    setOldFiles([...oldFiles, imgId]);
            };
        }
    };

    // cambios en Quill Editor
    const handleChange = (html) => {
        if (html === '<p><br></p>')
            setText('');
        else
            setText(html);
    };

    // restablecer imagen de portada
    const previousMainImg = () => {
        setMainImg({
            imgSrc: `${GOOGLE_DRIVE_CDN}${imgId}`,
            imgFile: '',
            imgUploaded
        });
        if (imgUploaded)
            setOldFiles(oldFiles.filter(e => e !== imgId));
    };

    // guardar bloque
    const addBlock = () => {
        if (text !== '') {
            setOpen1(true);
        } else basicMsg('Ingrese contenido en el recuadro');
    };

    // seleccionar longitud de bloque y guardar
    const insertBlock = () => {
        let _content = [...row];
        _content[0].col.push({
            item: text,
            src: '',
            file: '',
            type: 'txt',
            xs,
            md,
            id: `col-${colCounter}`
        });
        setRow(_content);
        setColCounter(colCounter + 1);
        setOpen1(false);
        // limpiar campo de Quill
        setText('');
    };

    // limpiar contenido de Quill
    const cleanBlock = () => {
        if (text !== '')
            Swal.fire({
                title: 'Limpiar el contenido del bloque',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed)
                    setText('');
            });
    };

    // agregar fila de contenido
    const addRow = () => {
        setRow([...row, { col: [], id: `row-${rowCounter}` }]);
        setRowCounter(rowCounter + 1);
    };

    // subir imagen/video a contenido de blog
    const uploadFiles = async({ target }) => {
        const promise = await loadFiles(target.files);
        if (promise) {
            const { columns, counter } = promise;
            let content = [...row];
            content[0].col.push(...columns);
            setRow(content);
            setColCounter(counter);
        }
    };

    // cargar archivos mediante Promesa
    const loadFiles = async(files) => {
        let counter = colCounter;
        const filePromise = [...files].map((file, i) => {
            return new Promise((resolve, reject) => {
                if (['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = async (event) => {
                        const src = event.target.result;
                        const item = `<img class="img-fluid" src="${src}" alt="imagen-blog-Nefropolis">`;
                        resolve({
                            item,
                            src,
                            file,
                            type: 'img',
                            xs: 12,
                            md: 6,
                            id: `col-${counter}`,
                            uploaded: false
                        });
                        counter++;
                    };
                } else if (file.type === 'video/mp4') {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = (event) => {
                        const src = event.target.result;
                        const item = `<div><h6>Video: ${file.name}</h6><img style="height: 200px; display:block; margin: auto;" src=${blogVideo} alt="video-blog-Nefropolis"></div>`;
                        resolve({
                            item,
                            src,
                            file,
                            type: 'video',
                            xs: 12,
                            md: 6,
                            id: `col-${counter}`,
                            uploaded: false
                        });
                        counter++;
                    };
                }
            });
        });
        return {
            columns: await Promise.all(filePromise),
            counter
        }
    };

    const updateBlog = () => {
        const SelectedCategories = categories.filter(e => e.checked).map(u => u.category);
        if (blogEditionFormValidator({ ...blogModel, categories: SelectedCategories, row })) {
            Swal.fire({
                title: '¿Actualizar el blog?',
                showDenyButton: true,
                confirmButtonText: 'Si',
                denyButtonText: 'Cancelar',
            }).then(async(result) => {
                if (result.isConfirmed) {
                    const rowContent = row.filter((e, i) => i !== 0 && e.col.length > 0);
                    const { files, filesCount } = getFilesCount(rowContent, imgFile);

                    let i = 0; // contador de filas
                    let contentModel = []; // contenido dinámico hacia BD
                    const url = generateURL(title);

                    if (filesCount > 0) {
                        dispatch(uiLoading2(true));
                        const data = new FormData();
                        data.append('folderId', folderId);
                        for (const e of files) {
                            data.append('file', e);
                        };
                        const req = await uploadBlogContentImages(data, 'update');
                        if (req.status) {
                            const { imagesId } = req.resp;

                            if (filesCount === imagesId.length) {
                                let j = 0; // contador de archivos
                                
                                // imagen principal
                                const newMainImg = imgFile !== '' ? imagesId[0] : imgId;
                                // arreglo de id's de archivos
                                const images = imgFile !== '' ?
                                    imagesId.length > 1 ? imagesId.filter((e, i) => i !== 0) : []
                                : imagesId;

                                for (const e of rowContent) {
                                    contentModel = [...contentModel, {
                                        col: []
                                    }];
                                    for (const col of e.col) {
                                        const { item, type, xs, md, fileId } = col;
                                        if (col.type === 'img' && !col.uploaded) {
                                            contentModel[i].col = [...contentModel[i].col, {
                                                item: `<img class="img-fluid" src="${GOOGLE_DRIVE_CDN}${images[j]}" alt="imagen-blog-Nefropolis">`,
                                                type, xs, md,
                                                fileId: images[j]
                                            }];
                                            j++;
                                        } else if (col.type === 'video' && !col.uploaded) {
                                            contentModel[i].col = [...contentModel[i].col, {
                                                item: `<iframe src="https://drive.google.com/file/d/${images[j]}/preview" title="video-blog-Nefropolis" width="100%" height="100%"></iframe>`,
                                                type, xs, md,
                                                fileId: images[j]
                                            }];
                                            j++;
                                        } else
                                            contentModel[i].col = [...contentModel[i].col, {
                                                item, type, xs, md, fileId
                                            }];
                                    }
                                    i++;
                                }
                                setSubmitted(true);
                                dispatch(updateBlogDB({
                                    ...blogModel,
                                    url,
                                    category: SelectedCategories,
                                    content: contentModel,
                                    folderId,
                                    imgId: newMainImg,
                                    imgUploaded: imgFile !== '' ? true : imgUploaded
                                }, oldFiles));
                            } else {
                                msg('warning', 'Blog',
                                'No se han subido todas las imágenes');
                                for (const e of rowContent) {
                                    contentModel = [...contentModel, {
                                        col: []
                                    }];
                                    for (const col of e.col) {
                                        const { item, type, xs, md, fileId } = col;
                                        if (col.type !== 'img' && col.type !== 'video')
                                            contentModel[i].col = [...contentModel[i].col, {
                                                item, type, xs, md, fileId
                                            }];
                                    };
                                    i++;
                                }
                                setSubmitted(true);
                                dispatch(updateBlogDB({
                                    ...blogModel,
                                    url,
                                    category: SelectedCategories,
                                    content: contentModel,
                                    folderId,
                                    imgId,
                                    imgUploaded
                                }, oldFiles));
                            }
                        }
                    } else {
                        for (const e of rowContent) {
                            contentModel = [...contentModel, {
                                col: []
                            }];
                            for (const col of e.col) {
                                const { item, type, xs, md, fileId } = col;
                                contentModel[i].col = [...contentModel[i].col, {
                                    item, type, xs, md, fileId
                                }];
                            };
                            i++;
                        };
                        setSubmitted(true);
                        dispatch(updateBlogDB({
                            ...blogModel,
                            url,
                            category: SelectedCategories,
                            content: contentModel,
                            folderId,
                            imgId,
                            imgUploaded
                        }, oldFiles));
                    }
                }
            });
        }
    };

    return (
        <Container maxWidth="xl" sx={{ mb: 5, mt: '90px' }}>
            <Stack direction="row"
                alignItems="flex-start">
                <IconButton aria-label="go-back"
                    onClick={ () => navigate('/nefro-admin/blog') }>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant='h5' paragraph>
                    Actualizar blog
                </Typography>
            </Stack>
            <TextField fullWidth
                variant='outlined'
                label="Título del blog"
                size='small'
                name='title'
                inputProps={{ maxLength: 200 }}
                value={ title }
                onChange={ handleOnChange }
            />
            <TextField fullWidth
                variant='outlined'
                label="Autor del blog"
                size='small'
                name='author'
                inputProps={{ maxLength: 100 }}
                value={ author }
                onChange={ handleOnChange }
                sx={{ my: 2 }}
            />
            <BasicDateTimePicker
                values={ blogModel }
                setValues={ setBlogModel }
            />
            
            <Grid container spacing={1} my={2}>
                <Grid item xs={12} md={4}>
                    <img src={ imgSrc } alt='portada-blog'
                        className='img-fluid'/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}>
                        <Button variant="outlined" startIcon={<InsertPhotoIcon />} component="label">
                            <input hidden accept="image/*"
                                type="file" onChange={ uploadMainImg }
                            />
                            Imagen de portada
                        </Button>
                        {
                            imgFile !== '' &&
                                <Button variant="contained" startIcon={<UndoIcon />}
                                    onClick={ previousMainImg }>
                                    Restablecer imagen
                                </Button>
                        }
                    </Stack>
                </Grid>
            </Grid>

            <Box sx={{ mt: 3 }}>
                <CustomToolbar />
                <ReactQuill
                    value={text}
                    name='quill'
                    onChange={handleChange}
                    modules={modules}
                    formats={formats}
                />
            </Box>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button variant="contained"
                    onClick={ addBlock }>
                    Guardar
                </Button>
                <Button onClick={ cleanBlock }>
                    Limpiar
                </Button>
                <Button variant="outlined" startIcon={<InsertPhotoIcon />} component="label">
                    <input hidden accept="image/*, video/*"
                        type="file" onChange={ uploadFiles }
                        multiple="multiple" />
                    Agregar imagen/video
                </Button>
            </Stack>

            <Box sx={{ mt: 3 }}>
                <Typography variant='body1'>Preview en HTML</Typography>
                <textarea defaultValue={text} rows={5} style={{ width: '100%' }} disabled></textarea>
            </Box>

            <BlogCategory />

            <BlogRowEdition setOpen={ setOpen }
                row={ row }
                setRow={ setRow }
                oldFiles={ oldFiles }
                setOldFiles={ setOldFiles }
            />

            <Button variant='contained'
                onClick={ addRow }>
                Agregar fila
            </Button>

            <QuillEditDialog open={ open }
                setOpen={ setOpen }
                row={ row } setRow={ setRow }
            />

            <BlockDialog open={ open1 }
                setOpen={ setOpen1 }
                xs={ xs } setXS={ setXS }
                md={ md } setMD={ setMD }
                insertBlock={ insertBlock }
            />
            
            <Fab color="primary"
                aria-label="add" sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(2),
                    right: (theme) => theme.spacing(2)
                }}
                disabled={ loading2 }
                onClick={ updateBlog }>
                <SaveAsIcon />
            </Fab>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ loading2 }>
                <CircularProgress />
            </Backdrop>
        </Container>
    );
};

export default BlogUpdate;