import React, { useState, useEffect } from 'react'

import shape from '../../../assets/resources/shape.png';
import no_image from '../../../assets/images/banners/coleccion-todos1.jpg';

//MUI
import {
    Alert, Box, Container, Grid, Typography, useMediaQuery,
    Stack, FormControl, InputLabel, Select, MenuItem, IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

//Router dom
import { Link, useParams } from 'react-router-dom';

//Redux
import { useSelector } from 'react-redux';

//Constants
import { GOOGLE_DRIVE_CDN } from '../../../constants/constants';

const SearchComponent = () => {
    const matches = useMediaQuery('(max-width: 950px)');
    const { product_field } = useParams();

    const {
        products
    } = useSelector(state => state.shop);

    // productos de tienda
    const [myProducts, setProducts] = useState([]);

    const [filter, setFilter] = useState('');

    // obtener precio menor de variantes para filtro
    const getMinPrice = (p) => {
        const prices = p.prod_variants.map(e => e.price);
        const min = Math.min(...prices);
        return min;
    };

    // obtener promedio de reseñas para filtro
    const getAverageReview = (p) => {
        let prom = 0; // promedio reseñas
        let count = 0 // número reseñas
        if (p.reviews.length > 0) {
            p.reviews.forEach(e => {
                prom += e.rating;
                count++;
            });
            // redondea promedio de reseñas
            prom = Math.round(prom / count);
        }
        return prom;
    };

    // filtrar productos
    const onChangeFilter = ({ target }) => {
        setFilter(target.value);
        const arr = [...myProducts];
        switch (target.value) {
            case '10': // menor precio
                setProducts(arr.sort(function (a, b) {
                    const a_min = getMinPrice(a);
                    const b_min = getMinPrice(b);
                    return (a_min > b_min) ? 1 : ((b_min > a_min) ? -1 : 0);
                }));
                break;
            case '20': // mayor precio
                setProducts(arr.sort(function (a, b) {
                    const a_min = getMinPrice(a);
                    const b_min = getMinPrice(b);
                    return (a_min < b_min) ? 1 : ((b_min < a_min) ? -1 : 0);
                }));
                break;
            case '30': // nombre ascendente
                setProducts(arr.sort(function (a, b) {
                    return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
                }));
                break;
            case '40': // nombre descendente
                setProducts(arr.sort(function (a, b) {
                    return (a.title < b.title) ? 1 : ((b.title < a.title) ? -1 : 0);
                }));
                break;
            case '50':
                setProducts(arr.sort(function (a, b) {
                    const a_prom = getAverageReview(a);
                    const b_prom = getAverageReview(b);
                    return (a_prom < b_prom) ? 1 : ((b_prom < a_prom) ? -1 : 0);
                }));
                break;
            default:
                break;
        }
    };

    const handleClear = () => {
        setProducts(products)
        setFilter('')
    }

    // const { state } = useLocation();
    const result = myProducts.filter((prod) => prod.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(product_field));

    useEffect(() => {
        setProducts(products);
    }, [products]);

    return (
        <Container maxWidth='xl' sx={{ mt: matches ? 5 : 18 }} className="last-view">
            <Typography variant='body1' sx={{ fontSize: '26px', mb: 5 }}>
                {
                    `${result.length} ${
                        result.length > 1 || result.length < 1 ? 'resultados' : 'resultado'
                    } para `
                } <span style={{ fontWeight: 'bold' }}>"{product_field}"</span>
                {/* {result.length}
                {
                result.length > 1 ? 'resultados' : 'resultado'
                } para <span style={{ fontWeight: 'bold' }}>"{state}"</span> */}
            </Typography>

            {/* Filtros */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '15px', mb: 3 }}>
                <FormControl className="order-by-shop" size="small">
                    <InputLabel>Ordenar por:</InputLabel>
                    <Select
                        name={filter}
                        label="Ordenar por:"
                        defaultValue={filter}
                        value={filter}
                        onChange={onChangeFilter}
                    >
                        <MenuItem value={'10'}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Menor precio</MenuItem>
                        <MenuItem value={'20'}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Mayor precio</MenuItem>
                        <MenuItem value={'30'}><i className="fa-solid fa-up-long" style={{ marginRight: '10px' }}></i>Nombre A - Z</MenuItem>
                        <MenuItem value={'40'}><i className="fa-solid fa-down-long" style={{ marginRight: '10px' }}></i>Nombre Z - A</MenuItem>
                        <MenuItem value={'50'}>Rating</MenuItem>
                    </Select>
                </FormControl>
                {
                    filter
                        ?
                        <IconButton onClick={handleClear} size='small'><ClearIcon fontSize='small' /></IconButton>
                        :
                        null
                }
            </Box>

            <Grid container spacing={3}>
                {
                    result.length >= 1 ?
                        
                            
                                result.map((e) => {
                                let review_prom = 0; // promedio reseñas
                                let review_count = 0 // número reseñas

                                // precios de variantes
                                const prices = e.prod_variants.map(e => e.price);
                                // menor precio
                                const min_price = Math.min(...prices);

                                // variante con promoción
                                const promoVariant = e.prod_variants.find(e => e.item_promotion && e.item_promotion.promo !== '');
                                // variante con precio más bajo
                                const minPriceVariant = e.prod_variants.find(e => e.price === min_price);

                                // variante a mostrar
                                const _variant = (!promoVariant && !minPriceVariant) ?
                                    e.prod_variants[0]
                                    :
                                    promoVariant ? promoVariant : minPriceVariant;

                                // reseñas de producto
                                if (e.reviews.length > 0) {
                                    e.reviews.forEach(element => {
                                        review_prom += element.rating;
                                        review_count++;
                                    });
                                    // redondea promedio de reseñas
                                    review_prom = Math.round(review_prom / review_count);
                                }
                                return (
                                    <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={e._id}>
                                        <Grid container rowSpacing={1} columnSpacing={3} className="product-shop">
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={5}
                                                sx={{ my: 'auto', textAlign: 'center' }}>
                                                {
                                                    _variant ?
                                                        <Link to={`/tienda/producto/${e.url}/${e.barcode}`}>
                                                            <div className="product-in-promotion">
                                                                {
                                                                    _variant.images.length > 0 ?
                                                                        <img src={`${GOOGLE_DRIVE_CDN}${_variant.images[0].id}`}
                                                                            width='100%' style={{ objectFit: 'contain' }}
                                                                            className="shop-product"
                                                                            alt={_variant.images[0].alt} />
                                                                        :
                                                                        <img src={no_image}
                                                                            width='100%' className="shop-product"
                                                                            alt="producto-nefropolis" />
                                                                }
                                                                {
                                                                    _variant.item_promotion.promo !== '' &&
                                                                    <div className="shop_top-image">
                                                                        <img src={shape} alt={shape} width='75' style={{ pointerEvents: 'none' }} />
                                                                        <div className="shop_centered_image">
                                                                            {
                                                                                ['2x1', '4x3', 'Descuento%Producto'].includes(_variant.item_promotion.promo)
                                                                                    ?
                                                                                    <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 'bold' }}>
                                                                                        {_variant.item_promotion.text}
                                                                                    </Typography>
                                                                                    :
                                                                                    <Typography sx={{ color: '#fff', fontSize: '12px', fontWeight: 'bold' }}>
                                                                                        Promoción
                                                                                    </Typography>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </Link>
                                                        :
                                                        <Link to={`/tienda/producto/${e.url}/${e.barcode}`}>
                                                            {
                                                                _variant && _variant.images.length > 0 ?
                                                                    <img src={`${GOOGLE_DRIVE_CDN}${_variant.images[0].id}`}
                                                                        width='100%' style={{ objectFit: 'contain' }}
                                                                        className="shop-product"
                                                                        alt={_variant.images[0].alt} />
                                                                    :
                                                                    <img src={no_image}
                                                                        width='100%' className="shop-product"
                                                                        alt="producto-nefropolis" />
                                                            }
                                                        </Link>
                                                }
                                            </Grid>

                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={7} sx={{ my: 'auto' }}>
                                                <Box sx={{ textAlign: { xs: 'flex-start', sm: 'center', md: 'center' } }}>
                                                    <Link to={`/tienda/producto/${e.url}/${e.barcode}`} style={{ textDecoration: 'none', color: '#000' }}
                                                        className='product_name'>
                                                        <Typography
                                                            variant="body1"
                                                            component="div"
                                                            sx={{ fontWeight: '600' }}
                                                        >
                                                            {e.title}
                                                        </Typography>
                                                    </Link>
                                                </Box>

                                                <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }, mt: 1 }}>
                                                    <div className="ratings-shop">
                                                        {
                                                            review_count > 0 ?
                                                                Array.from(Array(review_prom), (e, j) => {
                                                                    return <i className="fas fa-star"
                                                                        style={{
                                                                            color: '#FFA41C',
                                                                            stroke: "#FFA41C",
                                                                            strokeWidth: 0.2
                                                                        }}
                                                                        key={j}></i>
                                                                })
                                                                :
                                                                <>
                                                                    <i className="far fa-star"></i>
                                                                    <i className="far fa-star"></i>
                                                                    <i className="far fa-star"></i>
                                                                    <i className="far fa-star"></i>
                                                                    <i className="far fa-star"></i>
                                                                </>
                                                        }
                                                    </div>
                                                </Box>

                                                <Box sx={{
                                                    display: 'flex', mt: 1,
                                                    justifyContent: { xs: 'flex-start', sm: 'center', md: 'center' }
                                                }}>
                                                    {
                                                        _variant &&
                                                            ['Descuento$Producto', 'Descuento%Producto'].includes(
                                                                _variant.item_promotion.promo
                                                            ) ?
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="baseline"
                                                                spacing={1}>
                                                                <Typography variant="h6"
                                                                    sx={{ fontWeight: '600', color: '#000', textDecoration: 'line-through' }}>
                                                                    ${min_price}
                                                                </Typography>
                                                                <Typography variant="h6"
                                                                    sx={{ fontWeight: '600', color: '#F90606' }}>
                                                                    ${_variant.item_promotion.price}
                                                                </Typography>
                                                            </Stack>
                                                            :
                                                            <Typography variant="h6"
                                                                sx={{ fontWeight: '600', color: '#F90606' }}>
                                                                ${min_price}
                                                            </Typography>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        :
                        <Grid item xs={12}>
                            <Alert severity="info" sx={{ mt: 3 }}>
                                No hay ninguna coincidencia
                            </Alert>
                        </Grid>
                }
            </Grid>
        </Container>
    )
}

export default SearchComponent