import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import '../../Admin/Header/adminHeader.css';
import ProductListItem from './ProductListItem';
import EcommerceListItem from './EcommerceListItem';

import { logout } from '../../../actions/user';

import {
    Typography, ListItemButton, Backdrop, Stack, Collapse,
    List, Menu, Avatar, Divider, MenuItem, IconButton,
    ListItemIcon, ListItemText
} from '@mui/material';

import BookIcon from '@mui/icons-material/Book';
import StoreIcon from '@mui/icons-material/Store';
import BuildIcon from '@mui/icons-material/Build';
import LogoutIcon from '@mui/icons-material/Logout';
import FolderIcon from '@mui/icons-material/Folder';
import ArticleIcon from '@mui/icons-material/Article';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CircularProgress from '@mui/material/CircularProgress';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const usersLinks = [
    {
        title: 'Admins',
        url: 'admins',
        icon: AdminPanelSettingsIcon
    },
    {
        title: 'Banners Tienda',
        url: 'banners-tienda',
        icon: ViewCarouselIcon
    },
    {
        title: 'Extras',
        url: 'tools',
        icon: BuildIcon
    },
    {
        title: 'Reporte Webinar',
        url: 'reporte-webinar',
        icon: ArticleIcon
    }
    // {
    //   title: 'Usuarios',
    //   url: '',
    //   icon: PeopleAltIcon
    // }
];

const directoryLinks = [
    {
        title: 'Listado',
        url: 'directorio-medico',
        icon: LocalHospitalIcon
    },
    {
        title: 'Alta',
        url: 'nuevo-medico',
        icon: LibraryAddIcon
    }
];

const blogLinks = [
    {
        title: 'Entradas',
        url: 'blog',
        icon: ViewComfyIcon
    },
    {
        title: 'Nueva entrada',
        url: 'nuevo-blog',
        icon: LibraryAddIcon
    }
];

const sucLinks = [
    {
        title: 'Sucursales',
        url: 'sucursales',
        icon: StorefrontIcon
    },
    {
        title: 'Nueva sucursal',
        url: 'nueva-sucursal',
        icon: AddBusinessIcon
    }
];

const DrawerList = (props) => {
    const dispatch = useDispatch();
    const { loggedUser } = useSelector(state => state.user);
    const { username } = loggedUser;

    // cerrar sesión
    const logout_fn = () => {
        dispatch(logout());
    };

    const [open, setOpen] = useState(false);

    //Menu avatar
    const [anchorElUser, setAnchorElUser] = useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    
    // generar reporte de Webinar NIN
    const webinarReport = async () => {
        window.location.href = 'https://www.nefropolis.com/reporte_webinar/report.php';
    };

    const [userLink, setUserLink] = useState(false);
    const handleClickUser = () => {
        setUserLink(!userLink);
    };

    const [blogLink, setBlogLink] = useState(false);
    const handleClickBlog = () => {
        setBlogLink(!blogLink);
    };

    const [medicLink, setMedicLink] = useState(false);
    const handleClickMedic = () => {
        setMedicLink(!medicLink);
    };

    const [sucLink, setSucLink] = useState(false);
    const handleClickSuc = () => {
        setSucLink(!sucLink);
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ open }>
                <Stack direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}>
                    <Typography variant='body1'>Generando reporte...</Typography>
                    <CircularProgress color="inherit" fontSize="small" />
                </Stack>
            </Backdrop>
            <div className='img-admin-sidebar-header bg-wrap'>
                <div className="user-logo">
                    <Avatar
                        sx={{ backgroundColor: '#2699FB', fontWeight: 'bold' }}
                        className='img-user-logo'>
                            { username.charAt(0) }
                    </Avatar>
                    <div>
                        <IconButton onClick={ handleOpenUserMenu } sx={{ p: 0 }}>
                            <Typography variant='body1'
                                sx={{ fontWeight: '600', color: '#ffff' }}>
                                    { username } <ArrowDropDownIcon />
                            </Typography>
                        </IconButton>
                        <Typography variant="caption" display="block" gutterBottom
                            sx={{ color: '#ffff' }}>
                            Versión 3.0
                        </Typography>
                        <Menu
                            sx={{ mt: '25px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={ Boolean(anchorElUser) }
                            onClose={ handleCloseUserMenu }
                            >
                            <MenuItem onClick={ logout_fn }><LogoutIcon sx={{ mr: 2 }} />
                                Cerrar sesión
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>

            <List disablePadding>
                <ProductListItem props={ props } />
                
                <EcommerceListItem
                    props={ props }
                    setOpen={ setOpen }
                />

                <ListItemButton onClick={ handleClickUser }
                    sx={[
                        {
                            bgcolor: '#F2F3F4',
                            borderBottom: '1px solid #F2F3F4'
                        },
                            {
                            '&:hover': {
                                backgroundColor: '#EAEDED'
                            }
                        }
                    ]}
                >
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configuración" />
                    { userLink ? <ExpandLess /> : <ExpandMore /> }
                </ListItemButton>
                <Collapse in={ userLink } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            usersLinks.map(({ icon, title, url }) => {
                                const Icon = icon;
                                return url !== 'reporte-webinar' ?
                                    <React.Fragment key={url}>
                                        <ListItemButton
                                            sx={{ pl: 4 }}
                                            onClick={ props.open }
                                            component={ Link }
                                            to={"/nefro-admin/" + url}>
                                            <ListItemIcon>
                                                <Icon />
                                            </ListItemIcon>
                                            <ListItemText primary={ title } />
                                        </ListItemButton>
                                        <Divider />
                                    </React.Fragment>
                                :
                                    <ListItemButton key={url}
                                        sx={{ pl: 4 }}
                                        onClick={ webinarReport }>
                                        <ListItemIcon>
                                            <Icon />
                                        </ListItemIcon>
                                        <ListItemText primary={ title } />
                                    </ListItemButton>
                            })
                        }
                    </List>
                </Collapse>

                <ListItemButton onClick={handleClickMedic}
                    sx={[
                        {
                            bgcolor: '#F2F3F4',
                            borderBottom: '1px solid #F2F3F4'
                        },
                        {
                            '&:hover': {
                                backgroundColor: '#EAEDED'
                            }
                        }
                    ]}
                >
                <ListItemIcon>
                    <FolderIcon />
                </ListItemIcon>
                <ListItemText primary="Médicos" />
                    { medicLink ? <ExpandLess /> : <ExpandMore /> }
                </ListItemButton>
                <Collapse in={ medicLink } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            directoryLinks.map(e => (
                                <React.Fragment key={e.url}>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        onClick={ props.open }
                                        component={ Link }
                                        to={"/nefro-admin/" + e.url}>
                                        <ListItemIcon>
                                            <e.icon />
                                        </ListItemIcon>
                                        <ListItemText primary={ e.title } />
                                    </ListItemButton>
                                    <Divider />
                                </React.Fragment>
                            ))
                        }
                    </List>
                </Collapse>

                <ListItemButton onClick={ handleClickBlog }
                    sx={[
                        {
                            bgcolor: '#F2F3F4',
                            borderBottom: '1px solid #F2F3F4'
                        },
                        {
                            '&:hover': {
                                backgroundColor: '#EAEDED'
                            }
                        }
                    ]}
                >
                    <ListItemIcon>
                        <BookIcon />
                    </ListItemIcon>
                    <ListItemText primary="Blog" />
                    { blogLink ? <ExpandLess /> : <ExpandMore /> }
                </ListItemButton>
                <Collapse in={ blogLink } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            blogLinks.map(e => (
                                <React.Fragment key={e.url}>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        onClick={ props.open }
                                        component={ Link }
                                        to={"/nefro-admin/" + e.url}>
                                        <ListItemIcon>
                                            <e.icon />
                                        </ListItemIcon>
                                        <ListItemText primary={ e.title } />
                                    </ListItemButton>
                                    <Divider />
                                </React.Fragment>
                            ))
                        }
                    </List>
                </Collapse>

                <ListItemButton onClick={handleClickSuc}
                    sx={[
                        {
                            bgcolor: '#F2F3F4',
                            borderBottom: '1px solid #F2F3F4'
                        },
                        {
                            '&:hover': {
                                backgroundColor: '#EAEDED'
                            }
                        }
                    ]}
                >
                    <ListItemIcon>
                        <StoreIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sucursales" />
                    { sucLink ? <ExpandLess /> : <ExpandMore /> }
                </ListItemButton>
                <Collapse in={ sucLink } timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {
                            sucLinks.map(e => (
                                <React.Fragment key={e.url}>
                                    <ListItemButton
                                        sx={{ pl: 4 }}
                                        onClick={ props.open }
                                        component={ Link }
                                        to={"/nefro-admin/" + e.url}>
                                        <ListItemIcon>
                                            <e.icon />
                                        </ListItemIcon>
                                        <ListItemText primary={ e.title } />
                                    </ListItemButton>
                                    <Divider />
                                </React.Fragment>
                            ))
                        }
                    </List>
                </Collapse>
            </List>
        </>
    );
};

export default DrawerList;