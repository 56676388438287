import React from 'react';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';

import { setBlockInEdition } from '../../../../actions/admin/blog';

import {
    Grid, Paper, IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const BlogColEdition = ({ id, col, index, setOpen, row, setRow, oldFiles, setOldFiles }) => {
    const dispatch = useDispatch();
    const { item, xs, md, fileId, uploaded } = col;
    const handleClickOpen = () => {
        dispatch(setBlockInEdition(col));
        setOpen(true);
    };

    const deleteBlock = () => {
        Swal.fire({
            title: '¿Eliminar el bloque?',
            showDenyButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                const content = row.map(e => (
                    {    
                        ...e,
                        col: [
                            ...e.col.filter(u => u.id !== id)
                        ]
                    }
                ));
                setRow(content);
                if (uploaded)
                    setOldFiles([...oldFiles, fileId]);
            }
        });
    };

    return (
        <Draggable key={id} draggableId={id} index={index}>
            {(provided) => (
                <Grid item xs={xs} md={md}
                    ref={ provided.innerRef }
                    { ...provided.draggableProps }
                    { ...provided.dragHandleProps }>
                    <Paper square sx={{ p: 1 }}>
                        <div dangerouslySetInnerHTML={{ __html: item }}></div>
                        <IconButton aria-label="update" size="small"
                            onClick={ handleClickOpen }>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton aria-label="delete" size="small"
                            onClick={ deleteBlock }>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Paper>
                </Grid>
            )}
        </Draggable>
    );
};

export default BlogColEdition;