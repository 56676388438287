import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { HashLink } from 'react-router-hash-link';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import logo from '../../assets/images/Logo_Nefropolis.png';

import {
    AppBar, Toolbar, Box, IconButton, Autocomplete,
    Button, TextField, MenuItem, Badge, Avatar, Menu,
    ListItemIcon, Divider, Paper, useMediaQuery
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from "@mui/material/styles";

import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const MuiAutocomplete = styled(Autocomplete)({
    ".MuiAutocomplete-inputRoot": {
        color: "#000000",
        backgroundColor: "#D1D1D1",
        borderRadius: '40px',
        "& .MuiInputBase-input": {
            height: '18px'
        },
        "& .MuiInputAdornment-root": {
            color: "red"
        },
    }
});

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#ffff',
    backgroundColor: '#0099DC',
    borderColor: '#0099DC',
    '&:hover': {
        backgroundColor: '#0076a9',
        color: '#ffff',
    },
}));

const StyledBadgeDesktop = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -5,
        top: 5,
        border: `2px solid 2699FB`,
        padding: '0 4px',
        background: '#2699FB',
        color: '#fff'
    },
}));

function CustomPaper({ children, button, ...other }) {
    return (
        <Paper {...other}>
            {children}
            {button}
        </Paper>
    );
};

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

const HeaderAppBar = ({ handleRef, userLogout, itemCount, options, navbar, navbarData }) => {
    const { letter, title, link1, link2 } = navbarData;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width: 1024px)');

    // datos de usuario iniciado
    const { loggedUser: { email } } = useSelector(state => state.user);

    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
    };

    //Menu para usuario
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const [value, setValue] = useState("");
    const [openD, setOpenD] = useState(false);

    //Redirigir la busqueda a otro componente
    const handleSearch = (event) => {
        event.preventDefault();
        if (value)
            navigate(`/tienda/buscar/${value}`);
        setValue("");
        setOpenD(false);
    };

    // redirigir a producto
    const goToProductPage = (event, value) => {
        if (value) {
            const { url, barcode } = value;
            navigate(`/tienda/producto/${url}/${barcode}`);
        }
    };

    const buttons = (
        <Box sx={{ display: "flex", justifyContent: 'center', py: 1 }}>
            <Button onMouseDown={
                (event) => { event.preventDefault(); }
            } onClick={ handleSearch } sx={{ textTransform: 'none' }} size='small'>
                Ver todos los productos relacionados
            </Button>
        </Box>
    );

    return (
        <Box className='navbar_desktop'
            sx={{ position: 'fixed', width: '100%', zIndex: '3', top: 0 }}>
            <AppBar position="static" sx={{ background: '#4B4B4B' }}>
                <Toolbar variant='dense'>
                    <Box sx={{ display: 'flex', gap: '15px', marginLeft: 'auto' }}>
                        <IconButton
                            component='a'
                            href='https://www.linkedin.com/company/nefropolis/'
                            target='_blank'
                            size="small"
                            edge="start"
                            color='inherit'
                            sx={[{ '&:hover': { color: '#fff' } }]}
                        >
                            <LinkedInIcon fontSize='small' />
                        </IconButton>
                        <IconButton
                            component='a'
                            href='https://www.youtube.com/@nefropolis1'
                            target='_blank'
                            size="small"
                            edge="start"
                            color='inherit'
                            sx={[{ '&:hover': { color: '#fff' } }]}
                        >
                            <YouTubeIcon fontSize='small' />
                        </IconButton>
                        <IconButton
                            component='a'
                            href='https://www.facebook.com/nefropolis'
                            target='_blank'
                            size="small"
                            edge="start"
                            color='inherit'
                            sx={[{ '&:hover': { color: '#fff' } }]}
                        >
                            <FacebookIcon fontSize='small' />
                        </IconButton>
                        <IconButton
                            component='a'
                            href='https://www.instagram.com/nefropolis/'
                            target='_blank'
                            size="small"
                            edge="start"
                            color='inherit'
                            sx={[{ '&:hover': { color: '#fff' } }]}
                        >
                            <InstagramIcon fontSize='small' />
                        </IconButton>
                    </Box>
                    <Box sx={{ ml: 2 }} component="form" onSubmit={handleSearch}>
                        <MuiAutocomplete
                            open={openD}
                            onOpen={() => setOpenD(true)}
                            onClose={() => {
                                setOpenD(false)
                                setValue("")
                            }}
                            disablePortal
                            options={options}
                            getOptionLabel={(option) => option.title}
                            filterOptions={filterOptions}
                            onChange={goToProductPage}
                            sx={{ width: 260 }}
                            renderOption={(props, option) => (
                                <Box component="li"
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={option.img}
                                        srcSet={option.img}
                                        alt={option.alt} />
                                    {option.title}
                                </Box>
                            )}
                            PaperComponent={value.length >= 1 ? CustomPaper : null}
                            componentsProps={{ paper: { button: buttons } }}
                            renderInput={(params) => <TextField {...params} size='small' placeholder={`${t("Buscar")}...`}
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />
                            }
                        />
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Link
                            to={"/carrito"}
                            style={{ textDecoration: 'none', color: '#000' }}
                        >
                            <StyledBadgeDesktop badgeContent={itemCount}>
                                <ShoppingCartIcon sx={{ color: '#fff' }} />
                            </StyledBadgeDesktop>
                        </Link>
                    </Box>
                    <Box sx={{ ml: 3.5 }}>
                        {
                            email !== '' ?
                                <Avatar onClick={openMenu}
                                    sx={{ width: 28, height: 28, bgcolor: '#fff', color: '#000', cursor: 'pointer' }}
                                >
                                    { letter }
                                </Avatar>
                            :
                                <Avatar component={Link} to='/login'
                                    sx={[
                                        {
                                            width: 28, height: 28, bgcolor: '#fff', color: '#000'
                                        },
                                        {
                                            '&:hover': {
                                                color: '#000',
                                            },
                                        },
                                    ]}>
                                </Avatar>
                        }
                    </Box>
                    {
                        email !== '' &&
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={closeMenu}
                            onClick={closeMenu}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 9,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem
                                component={Link}
                                to={ link1 }
                                sx={[{ '&:hover': { color: '#000' } }]}
                            >
                                <Avatar />
                                { title }
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                sx={[{ '&:hover': { color: '#000' } }]}
                                component={Link}
                                to={ link2 }
                            >
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                {t("Configuracion")}
                            </MenuItem>
                            <MenuItem onClick={ userLogout }>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                {t("Cerrar_sesion")}
                            </MenuItem>
                        </Menu>
                    }
                    {/* <LangSelect /> */}
                </Toolbar>
            </AppBar>

            <AppBar position="static" className={
                    navbar ? "appbar active" : "appbar"
                } sx={{ borderRadius: '0 0 35px 35px', px: 4 }}>
                <Toolbar sx={{ height: '65px' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Link to='/'>
                            <img src={logo} alt={logo} width='120' />
                        </Link>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '20px' }} className='links__'>
                        <Button
                            size={matches ? 'small' : 'normal'}
                            LinkComponent={NavLink} to='/'
                            sx={{ color: 'black', fontWeight: '600' }}>{t('Inicio')}</Button>
                        <Button
                            size={matches ? 'small' : 'normal'}
                            LinkComponent={NavLink} to='/sobre-nefropolis'
                            sx={{ color: 'black', fontWeight: '600' }}>{t('Sobre_nosotros')}</Button>
                        <Button
                            size={matches ? 'small' : 'normal'}
                            LinkComponent={NavLink} to='/sucursales'
                            sx={{ color: 'black', fontWeight: '600' }}>{t("Sucursales")}</Button>
                        <Button
                            size={matches ? 'small' : 'normal'}
                            LinkComponent={NavLink} to='/tienda'
                            sx={{ color: 'black', fontWeight: '600' }}>{t('Tienda')}</Button>
                        <Button
                            size={matches ? 'small' : 'normal'}
                            LinkComponent={HashLink} to="/#servicios"
                            sx={{ color: 'black', fontWeight: '600' }}>{t("Servicios")}</Button>
                        <Button
                            size={matches ? 'small' : 'normal'}
                            LinkComponent={HashLink} to="/blog"
                            sx={{ color: 'black', fontWeight: '600' }}>Blog</Button>
                        {/* <Button
                            size={matches ? 'small' : 'normal'}
                            LinkComponent={NavLink} to='/directorio-medico'
                            sx={{ color: 'black', fontWeight: '600' }}>{t("Medicos")}</Button> */}
                        <ColorButton
                            size={matches ? 'small' : 'normal'}
                            variant='contained' onClick={handleRef}>{t("Contacto")}</ColorButton>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default HeaderAppBar;